import {
  ApiResponse,
  ApiService,
  Game,
  GameHistory,
  Player,
  SaveGameResponse,
  SavePlayerResponse,
} from "@/pages/Molky/types.ts";

export const api: ApiService = {
  saveGame: (
    game: Game,
    generateSummary: boolean = false
  ): Promise<SaveGameResponse> => {
    console.log(
      "Saving game to API:",
      game,
      "Generate Summary:",
      generateSummary
    );
    return Promise.resolve({
      success: true,
      gameId: game.id,
      summary: generateSummary
        ? `Partie du ${new Date().toLocaleDateString()} - Vainqueur: ${
            game.winner?.name
          } avec ${
            game.winner?.score
          } points! Le joueur le plus malchanceux était ${
            game.players.sort(
              (a, b) =>
                a.history.filter((score) => score === 0).length -
                b.history.filter((score) => score === 0).length
            )[0]?.name
          } avec le plus de lancers manqués.`
        : undefined,
    });
  },

  getGames: (): Promise<GameHistory[]> => {
    console.log("Fetching games from API");
    // Mock data pour simuler des parties récentes
    const mockGames: GameHistory[] = [
      {
        id: "game1",
        date: new Date(Date.now() - 1000 * 60 * 60 * 2), // 2 heures avant
        players: [
          { id: "p1", name: "Alex", score: 50, history: [12, 24, 38, 50] },
          { id: "p2", name: "Sam", score: 34, history: [8, 16, 26, 34] },
        ],
        winner: {
          id: "p1",
          name: "Alex",
          score: 50,
          history: [12, 24, 38, 50],
        },
        duration: 15,
      },
      {
        id: "game2",
        date: new Date(Date.now() - 1000 * 60 * 60 * 24), // 1 jour avant
        players: [
          { id: "p3", name: "Jordan", score: 35, history: [10, 20, 25, 35] },
          { id: "p4", name: "Max", score: 50, history: [12, 24, 36, 50] },
          { id: "p5", name: "Lou", score: 42, history: [8, 18, 30, 42] },
        ],
        winner: { id: "p4", name: "Max", score: 50, history: [12, 24, 36, 50] },
        duration: 25,
      },
    ];
    return Promise.resolve(mockGames);
  },

  savePlayer: (player: Player): Promise<SavePlayerResponse> => {
    console.log("Saving player to API:", player);
    return Promise.resolve({ success: true, playerId: player.id });
  },

  getPlayers: (): Promise<Player[]> => {
    console.log("Fetching players from API");
    return Promise.resolve([]);
  },

  deleteGame: (gameId: string): Promise<ApiResponse<null>> => {
    console.log("Deleting game from API:", gameId);
    return Promise.resolve({ success: true });
  },

  getGameById: (gameId: string): Promise<Game | null> => {
    console.log("Fetching game by ID from API:", gameId);
    return Promise.resolve(null);
  },

  updateGame: (game: Game): Promise<SaveGameResponse> => {
    console.log("Updating game in API:", game);
    return Promise.resolve({ success: true, gameId: game.id });
  },
};
