import BanqueCreateForm from "@/pages/Bank/components/Banque/BanqueCreateForm.tsx";
import { BanqueList } from "@/pages/Bank/components/Banque/BanqueList.tsx";
import { Banque } from "@/pages/Bank/types.ts";
import { useState } from "react";
import { Loader } from "lucide-react";

const BanqueMain = ({
  banques,
  isBanquesLoading,
  isBanquesError,
}: {
  banques: Banque[];
  isBanquesLoading: boolean;
  isBanquesError: boolean;
}) => {
  const [banqueFormValue, setBanqueFormValue] = useState<Partial<Banque>>({
    etablissement: "",
    nomCourt: "",
    schema: [],
  });

  // État pour contrôler si le formulaire est ouvert ou fermé
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleFormValueChange = (newValue: Partial<Banque>) => {
    setBanqueFormValue((actualValue) => ({ ...actualValue, ...newValue }));
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  if (isBanquesLoading) return <Loader />;
  if (isBanquesError) return <p>Something went wrong...</p>;

  return (
    <>
      <div className="collapse collapse-arrow bg-base-100 border-base-300 border">
        <input
          type="checkbox"
          checked={isFormOpen}
          onChange={toggleForm}
          className="min-h-[3rem]"
        />
        <div className="collapse-title font-semibold text-2xl">
          Creation de banque
        </div>
        {isFormOpen && (
          <BanqueCreateForm
            formData={banqueFormValue}
            setFormData={setBanqueFormValue}
          />
        )}
      </div>

      <BanqueList
        banqueList={banques}
        formValueChange={handleFormValueChange}
      />
    </>
  );
};

export default BanqueMain;
