import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import NewJourneyCard from "@/pages/API/sncf/components/NewJourneyCard.tsx";

const fetchJourneys = async () => {
  const response = await axios.get(
    "http://localhost:3080/api/sncfApi/journeys",
    {
      params: {
        fromStationId: "87116210",
        toStationId: "87113001",
        datetime: new Date().toISOString(),
        datetime_represents: "departure",
        count: 10,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: localStorage.getItem("authorization"),
      },
    }
  );
  return response.data;
};

const SncfNextCard = () => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["journeys"],
    queryFn: fetchJourneys,
    refetchInterval: 300000, // 5 minutes
    staleTime: 300000, // Données considérées comme fraîches pendant 5 minutes
  });

  console.log(data);

  if (isLoading) return <p>Chargement...</p>;
  if (error) return <p>Erreur : {error.message}</p>;

  return (
    <div className="card bg-base-100 shadow-xl p-4">
      <h2 className="text-lg font-bold">Prochains trajets</h2>
      <ul>
        {data?.map((journey, index) => (
          <NewJourneyCard journey={journey} key={index} />
        ))}
      </ul>
    </div>
  );
};

export default SncfNextCard;
