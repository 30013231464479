import React, { useEffect, useState } from "react";
import { PrevisionRowSchema } from "@/pages/Bank/types.ts";

const newPrevision = {
  intitule: "",
  montant: 0,
  datePrevue: new Date().getDate(),
  done: false,
  isNew: true,
};

export const PrevisionForm: React.FC<{
  onAjouter: (ligne: Partial<PrevisionRowSchema>) => void;
  onClose: (prevision: PrevisionRowSchema | undefined) => void;
  onReinitialiser: () => void;
  infoCompte: string;
  prevision?: PrevisionRowSchema;
}> = ({ onAjouter, onClose, onReinitialiser, prevision, infoCompte }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [nouveauLigne, setNouveauLigne] = useState(
    prevision || {
      intitule: "",
      montant: 0,
      datePrevue: new Date().getDate(),
      done: false,
      isNew: true,
      infoCompte,
    }
  );

  useEffect(() => {
    if (prevision) {
      setNouveauLigne(prevision);
      setIsFormVisible(true);
    } else setNouveauLigne({ ...newPrevision, infoCompte });
  }, [prevision, infoCompte]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    setNouveauLigne((prev) => ({
      ...prev,
      [name]:
        name === "datePrevue" || name === "montant" ? parseInt(value) : value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAjouter(nouveauLigne);
    setNouveauLigne({ ...newPrevision, infoCompte });
    setIsFormVisible(false);
  };

  return (
    <div className="flex items-center gap-2">
      {!isFormVisible ? (
        <div className="flex w-full justify-around">
          <button
            className="btn btn-sm btn-primary w-3/10"
            onClick={() => setIsFormVisible(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
            Ajouter
          </button>
          <button
            className="btn btn-sm btn-outline w-3/10"
            onClick={onReinitialiser}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
            Réinitialiser
          </button>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="flex items-center gap-2 w-full bg-base-200 p-2 rounded-lg"
        >
          <input
            type="text"
            name="intitule"
            value={nouveauLigne.intitule}
            onChange={handleChange}
            placeholder="Intitulé"
            className="input input-sm input-bordered flex-1"
            required
          />
          <input
            type="text"
            name="montant"
            value={nouveauLigne.montant}
            onChange={handleChange}
            placeholder="Montant"
            className="input input-sm input-bordered w-24"
            required
          />
          <input
            type="number"
            name="datePrevue"
            value={nouveauLigne.datePrevue}
            onChange={handleChange}
            min="1"
            max="31"
            className="input input-sm input-bordered w-16"
            required
          />
          <div className="flex gap-1">
            <button type="submit" className="btn btn-sm btn-success">
              ✓
            </button>
            <button
              type="button"
              className="btn btn-sm btn-ghost"
              onClick={() => {
                setIsFormVisible(false);
                onClose(undefined);
              }}
            >
              ✕
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
