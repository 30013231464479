import { useState } from "react";
import { nanoid } from "nanoid";
import { api } from "../services/api";
import { Game, Player, UseGameReturn } from "@/pages/Molky/types.ts";

const TARGET_SCORE = 50; // Score cible pour gagner
const MAX_MISSES = 3; // Nombre max de lancers ratés consécutifs

export function useGame(initialPlayers: Player[]): UseGameReturn {
  const [game, setGame] = useState<Game>({
    id: nanoid(),
    date: new Date(),
    players: initialPlayers.map((p) => ({ ...p, score: 0, history: [] })),
    currentPlayerIndex: 0,
    isFinished: false,
  });

  const [consecutiveMisses, setConsecutiveMisses] = useState<
    Record<string, number>
  >({});

  // Passer au joueur suivant
  const nextPlayer = () => {
    setGame((prev) => ({
      ...prev,
      currentPlayerIndex: (prev.currentPlayerIndex + 1) % prev.players.length,
    }));
  };

  // Ajouter des points au joueur actuel
  const addScore = (points: number) => {
    if (game.isFinished) return;

    const currentPlayer = game.players[game.currentPlayerIndex];
    const playerId = currentPlayer.id;

    // Gestion des lancers ratés
    if (points === 0) {
      const misses = (consecutiveMisses[playerId] || 0) + 1;
      setConsecutiveMisses({ ...consecutiveMisses, [playerId]: misses });

      // Si 3 lancers ratés consécutifs, le joueur est éliminé (score remis à 0)
      if (misses >= MAX_MISSES) {
        updatePlayerScore(playerId, 0);
        setConsecutiveMisses({ ...consecutiveMisses, [playerId]: 0 });
        nextPlayer();
        return;
      }
    } else {
      // Réinitialiser les lancers ratés si le joueur marque
      setConsecutiveMisses({ ...consecutiveMisses, [playerId]: 0 });
    }

    // Calculer le nouveau score
    const newScore = currentPlayer.score + points;

    // Si le score dépasse 50, on revient à 25
    if (newScore > TARGET_SCORE) {
      updatePlayerScore(playerId, 25);
    }
    // Si le score est exactement 50, le joueur gagne
    else if (newScore === TARGET_SCORE) {
      updatePlayerScore(playerId, newScore);
      setGame((prev) => ({
        ...prev,
        winner: { ...currentPlayer, score: newScore },
        isFinished: true,
      }));

      // Sauvegarder la partie terminée
      api.saveGame({
        ...game,
        winner: { ...currentPlayer, score: newScore },
        isFinished: true,
      });
    }
    // Sinon, on met à jour normalement
    else {
      updatePlayerScore(playerId, newScore);
    }

    // Passer au joueur suivant
    nextPlayer();
  };

  // Mettre à jour le score d'un joueur
  const updatePlayerScore = (playerId: string, newScore: number) => {
    setGame((prev) => {
      const updatedPlayers = prev.players.map((player) => {
        if (player.id === playerId) {
          return {
            ...player,
            score: newScore,
            history: [...player.history, newScore],
          };
        }
        return player;
      });

      return {
        ...prev,
        players: updatedPlayers,
      };
    });
  };

  // Démarrer une nouvelle partie
  const startNewGame = (players: Player[]) => {
    const newGame: Game = {
      id: nanoid(),
      date: new Date(),
      players: players.map((p) => ({ ...p, score: 0, history: [] })),
      currentPlayerIndex: 0,
      isFinished: false,
    };

    setGame(newGame);
    setConsecutiveMisses({});
  };

  // Annuler le dernier score
  const undoLastScore = () => {
    if (game.isFinished) return;

    // Trouver le joueur précédent
    const prevPlayerIndex =
      game.currentPlayerIndex === 0
        ? game.players.length - 1
        : game.currentPlayerIndex - 1;

    const prevPlayer = game.players[prevPlayerIndex];

    // S'il n'y a pas d'historique pour ce joueur, on ne fait rien
    if (prevPlayer.history.length === 0) return;

    // Retirer le dernier score de l'historique et mettre à jour le score total
    setGame((prev) => {
      const updatedPlayers = prev.players.map((player) => {
        if (player.id === prevPlayer.id) {
          const newHistory = [...player.history];
          newHistory.pop(); // Retirer le dernier score

          // Recalculer le score (dernier score de l'historique mis à jour)
          const newScore =
            newHistory.length > 0 ? newHistory[newHistory.length - 1] : 0;

          return {
            ...player,
            score: newScore,
            history: newHistory,
          };
        }
        return player;
      });

      return {
        ...prev,
        players: updatedPlayers,
        currentPlayerIndex: prevPlayerIndex,
        isFinished: false,
        winner: undefined,
      };
    });

    // Réinitialiser les lancers manqués pour ce joueur
    setConsecutiveMisses({
      ...consecutiveMisses,
      [prevPlayer.id]: 0,
    });
  };

  // Obtenir le classement d'un joueur
  const getPlayerRank = (playerId: string): number => {
    const sortedPlayers = [...game.players].sort((a, b) => b.score - a.score);
    const playerIndex = sortedPlayers.findIndex(
      (player) => player.id === playerId
    );
    return playerIndex + 1;
  };

  return {
    game,
    currentPlayer: game.players[game.currentPlayerIndex],
    addScore,
    startNewGame,
    isGameFinished: game.isFinished,
    winner: game.winner,
    undoLastScore,
    getPlayerRank,
  };
}
