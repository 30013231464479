import React, { useState } from "react";
import { Category, Modificateur } from "@/pages/Bank/types.ts";
import {
  Archive,
  Ban,
  ChevronDown,
  ChevronRight,
  Edit,
  Trash2,
} from "lucide-react";
import { getIconComponent } from "@/pages/Bank/utils.ts";

interface CategoryListItemProps {
  category: Category;
  subCategories: Category[];
  onEdit: (category: Category) => void;
  onDelete: (categoryId: string) => void;
  onModify: (categoryId: string, modificateur: Modificateur) => void;
}

export const CategoryListItem = ({
  category,
  subCategories,
  onEdit,
  onDelete,
  onModify,
}: CategoryListItemProps) => {
  const [expanded, setExpanded] = useState(false);

  // Déterminer si la catégorie a des sous-catégories
  const hasSubCategories = subCategories && subCategories.length > 0;

  // Fonction pour obtenir l'icône à afficher
  const getIcon = (iconName: string | undefined, isSubCategory = false) => {
    if (iconName) {
      // Si une icône personnalisée est spécifiée
      const IconComponent = getIconComponent(iconName);
      if (IconComponent) {
        return (
          <IconComponent
            size={16}
            className={
              isSubCategory ? "text-base font-bold" : "text-primary font-bold"
            }
          />
        );
      }
    }
    return undefined;
  };

  // Gestion de l'expansion des sous-catégories
  const toggleExpand = () => {
    if (hasSubCategories) {
      setExpanded(!expanded);
    }
  };

  // Actions sur la catégorie
  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEdit(category);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (
      window.confirm(
        `Êtes-vous sûr de vouloir supprimer la catégorie "${category.label}" ?`
      )
    ) {
      onDelete(category._id);
    }
  };

  const handleArchive = (e: React.MouseEvent) => {
    e.stopPropagation();
    onModify(category._id, {
      status: "archived",
      state: !category.archived,
    });
  };

  const handleDisable = (e: React.MouseEvent) => {
    e.stopPropagation();
    onModify(category._id, {
      status: "disabled",
      state: !category.disabled,
    });
  };

  return (
    <div className="mb-1">
      <div
        className={`flex items-center p-2 rounded-md hover:bg-base-200 cursor-pointer ${
          category.disabled ? "opacity-50" : ""
        } ${category.archived ? "text-gray-500" : ""}`}
        onClick={toggleExpand}
      >
        {/* Icône d'expansion */}
        <div className="w-5 flex justify-center">
          {hasSubCategories ? (
            expanded ? (
              <ChevronDown size={16} />
            ) : (
              <ChevronRight size={16} />
            )
          ) : (
            <span className="w-4"></span>
          )}
        </div>

        {/* Icône de catégorie */}
        <div className="ml-1 mr-2">{getIcon(category.icon)}</div>

        {/* Nom de la catégorie */}
        <div className="flex-grow font-medium">{category.label}</div>

        {/* Indicateurs d'état */}
        <div className="flex gap-1 mr-2">
          {category.archived && (
            <Archive size={16} className="text-warning" aria-label="Archivée" />
          )}
          {category.disabled && (
            <Ban size={16} className="text-error" aria-label="Désactivée" />
          )}
        </div>

        {/* Actions */}
        <div className="flex gap-1">
          <button
            className="btn btn-ghost btn-xs"
            onClick={handleEdit}
            aria-label="Modifier"
          >
            <Edit size={14} />
          </button>
          <button
            className="btn btn-ghost btn-xs"
            onClick={handleArchive}
            aria-label={category.archived ? "Désarchiver" : "Archiver"}
          >
            <Archive
              size={14}
              className={category.archived ? "text-warning" : ""}
            />
          </button>
          <button
            className="btn btn-ghost btn-xs"
            onClick={handleDisable}
            aria-label={category.disabled ? "Activer" : "Désactiver"}
          >
            <Ban size={14} className={category.disabled ? "text-error" : ""} />
          </button>
          <button
            className="btn btn-ghost btn-xs text-error"
            onClick={handleDelete}
            aria-label="Supprimer"
          >
            <Trash2 size={14} />
          </button>
        </div>
      </div>

      {/* Sous-catégories */}
      {expanded && hasSubCategories && (
        <div className="pl-6 ml-2 border-l border-dashed border-base-300">
          {subCategories.map((subCategory) => (
            <div
              key={subCategory._id}
              className={`flex items-center p-2 rounded-md hover:bg-base-200 ${
                subCategory.disabled ? "opacity-50" : ""
              } ${subCategory.archived ? "text-gray-500" : ""}`}
            >
              {/* Icône de sous-catégorie */}
              <div className="ml-1 mr-2">{getIcon(subCategory.icon, true)}</div>

              {/* Nom de la sous-catégorie */}
              <div className="flex-grow">{subCategory.label}</div>

              <div className="flex gap-1 mr-2">
                {subCategory.archived && (
                  <Archive
                    size={16}
                    className="text-warning"
                    aria-label="Archivée"
                  />
                )}
                {subCategory.disabled && (
                  <Ban
                    size={16}
                    className="text-error"
                    aria-label="Désactivée"
                  />
                )}
              </div>

              {/* Actions */}
              <div className="flex gap-1">
                <button
                  className="btn btn-ghost btn-xs"
                  onClick={(e) => {
                    e.stopPropagation();
                    onEdit(subCategory);
                  }}
                  aria-label="Modifier"
                >
                  <Edit size={14} />
                </button>
                <button
                  className="btn btn-ghost btn-xs"
                  onClick={(e) => {
                    e.stopPropagation();
                    onModify(subCategory._id, {
                      status: "archived",
                      state: !subCategory.archived,
                    });
                  }}
                  aria-label={subCategory.archived ? "Désarchiver" : "Archiver"}
                >
                  <Archive
                    size={14}
                    className={subCategory.archived ? "text-warning" : ""}
                  />
                </button>
                <button
                  className="btn btn-ghost btn-xs"
                  onClick={(e) => {
                    e.stopPropagation();
                    onModify(subCategory._id, {
                      status: "disabled",
                      state: !subCategory.disabled,
                    });
                  }}
                  aria-label={subCategory.disabled ? "Activer" : "Désactiver"}
                >
                  <Ban
                    size={14}
                    className={subCategory.disabled ? "text-error" : ""}
                  />
                </button>
                <button
                  className="btn btn-ghost btn-xs text-error"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (
                      window.confirm(
                        `Êtes-vous sûr de vouloir supprimer la sous-catégorie "${subCategory.label}" ?`
                      )
                    ) {
                      onDelete(subCategory._id);
                    }
                  }}
                  aria-label="Supprimer"
                >
                  <Trash2 size={14} />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
