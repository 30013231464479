import { Clock, MapPin, Train } from "lucide-react";

const NewJourneyCard = ({ journey }) => {
  // Formater la date et l'heure
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString("fr-FR", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Calculer la durée du trajet en minutes
  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    return hours > 0 ? `${hours}h ${minutes}min` : `${minutes} min`;
  };

  return (
    <div className="shadow-md rounded-lg p-4 border">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <Train className="mr-2 text-blue-600" size={24} />
          <span className="font-semibold text-gray-700">
            {journey.trains[0].trainName} {journey.trains[0].trainNumber}
          </span>
        </div>
        <span className="text-sm text-gray-500">
          {formatDuration(journey.duration)}
        </span>
      </div>

      <div className="flex justify-between items-center mb-2">
        <div>
          <div className="flex items-center">
            <MapPin className="mr-2 text-green-600" size={20} />
            <span className="font-medium text-base">
              {journey.departure.station}
            </span>
          </div>
          <span className="text-sm text-gray-600 ml-6">
            {/*{formatDateTime(journey.departure.scheduledTime)}*/}
            {journey.departure.scheduledTime.split("T")[1].slice(0, 4)}
          </span>
        </div>
        <div className="text-right">
          <div className="flex items-center justify-end">
            <MapPin className="mr-2 text-red-600" size={20} />
            <span className="font-medium text-base">
              {journey.arrival.station}
            </span>
          </div>
          <span className="text-sm text-gray-600 mr-6">
            {/*{formatDateTime(journey.arrival.scheduledTime)}*/}
            {journey.arrival.scheduledTime.split("T")[1].slice(0, 4)}
          </span>
        </div>
      </div>

      {journey.delay > 0 && (
        <div className="mt-2 text-sm text-red-600 flex items-center">
          <Clock className="mr-2" size={16} />
          Retard : {journey.delay} minutes
        </div>
      )}
    </div>
  );
};

export default NewJourneyCard;
