import React from "react";
import {
  FaBalanceScale,
  FaCalculator,
  FaChartLine,
  FaPiggyBank,
} from "react-icons/fa";
import { PrevisionRowSchema } from "@/pages/Bank/types.ts";

interface CompteInfoBarProps {
  compte?: {
    _id: string;
    nomCompte: string;
    nomCourtBanque?: string;
  };
  lignesCompte: Array<any>;
  lignesPrevision: PrevisionRowSchema[];
  isValueHidden: boolean;
}

export const CompteInfoBar: React.FC<CompteInfoBarProps> = ({
  lignesCompte,
  lignesPrevision,
  isValueHidden,
}) => {
  // Calcul du solde actuel (dernière ligne du compte)
  const soldeActuel = lignesCompte.length > 0 ? lignesCompte[0].solde : 0;

  // Calcul du montant total des prévisions non réalisées
  const soldePrevisionnelRestant = lignesPrevision.reduce(
    (acc: number, ligne) => {
      if (!ligne.done && ligne.montant) {
        return acc + ligne.montant;
      }
      return acc;
    },
    0
  );

  // Calcul du montant total de toutes les prévisions positives
  const soldePrevisionnelTotal = lignesPrevision.reduce(
    (acc: number, ligne) => {
      if (ligne.montant && ligne.montant > 0) {
        return acc + ligne.montant;
      }
      return acc;
    },
    0
  );

  // Calcul du solde prévisionnel
  const soldePrevisionnel = soldeActuel - soldePrevisionnelRestant;

  // Fonction pour formatter les nombres en euros avec 2 décimales
  const formatCurrency = (value: number | undefined): string => {
    // Vérifier si la valeur est undefined ou null
    if (value === undefined || value === null) {
      return "0,00 €";
    }

    // Si isValueHidden est true, masquer la valeur
    if (isValueHidden) {
      return "***** €";
    }

    // Formater la valeur en devise française avec Intl.NumberFormat
    return new Intl.NumberFormat("fr-FR", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
    }).format(value);
  };

  // Fonction pour déterminer la classe de couleur en fonction du montant
  const getAmountColorClass = (amount: number): string => {
    if (isValueHidden) return "";
    return amount < 0 ? "text-error" : "text-success";
  };

  return (
    <div className="w-full bg-base-200 rounded-b-lg shadow-sm py-2 px-4 -mt-4">
      <div className="flex flex-wrap justify-between">
        {/* Total des prévisions */}
        <div className="flex items-center gap-2 px-2">
          <FaPiggyBank className="text-primary text-sm" />
          <div>
            <span className="text-xs text-base-content/70 mr-1">
              Total prévisions:
            </span>
            <span
              className={`font-medium ${getAmountColorClass(
                soldePrevisionnelTotal
              )}`}
            >
              {formatCurrency(soldePrevisionnelTotal)}
            </span>
          </div>
        </div>

        {/* Prévisions restantes */}
        <div className="flex items-center gap-2 px-2">
          <FaChartLine className="text-primary text-sm" />
          <div>
            <span className="text-xs text-base-content/70 mr-1">
              Prévisions restantes:
            </span>
            <span
              className={`font-medium ${getAmountColorClass(
                -soldePrevisionnelRestant
              )}`}
            >
              {formatCurrency(soldePrevisionnelRestant)}
            </span>
          </div>
        </div>

        {/* Solde actuel */}
        <div className="flex items-center gap-2 px-2">
          <FaBalanceScale className="text-primary text-sm" />
          <div>
            <span className="text-xs text-base-content/70 mr-1">
              Solde actuel:
            </span>
            <span className={`font-medium ${getAmountColorClass(soldeActuel)}`}>
              {formatCurrency(soldeActuel)}
            </span>
          </div>
        </div>

        {/* Solde prévisionnel */}
        <div className="flex items-center gap-2 px-2">
          <FaCalculator className="text-primary text-sm" />
          <div>
            <span className="text-xs text-base-content/70 mr-1">
              Solde prévisionnel:
            </span>
            <span
              className={`font-medium ${getAmountColorClass(
                soldePrevisionnel
              )}`}
            >
              {formatCurrency(soldePrevisionnel)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
