import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { getUserLogged } from "@/utils/axios/userUtils";
import { useFamilyDetails } from "@/hooks/familyHooks";
import CreateFamilyForm from "./parts/CreateFamilyForm";
import JoinFamilyForm from "./parts/JoinFamilyForm";
import FamilyDetails from "./parts/FamilyDetails";
import HouseManager from "./parts/HouseManager";

const FamilyPage: React.FC = () => {
  const userLogged = getUserLogged();
  const userRole = userLogged?.role || "user";
  const { data: family, isLoading, isError } = useFamilyDetails();

  // Si les données sont en cours de chargement
  if (isLoading) {
    return (
      <Box
        p={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={2}
      >
        <CircularProgress />
        <Typography>Chargement des informations de famille...</Typography>
      </Box>
    );
  }

  // Cas 1: L'utilisateur n'a pas de famille (roles: 'user' ou erreur API)
  if (isError || userRole === "user" || !family) {
    return (
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          Gestion de famille
        </Typography>
        <Typography paragraph>
          Vous n'appartenez à aucune famille. Vous pouvez en créer une nouvelle
          ou rejoindre une famille existante.
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CreateFamilyForm />
          </Grid>
          <Grid item xs={12} md={6}>
            <JoinFamilyForm />
          </Grid>
        </Grid>
      </Box>
    );
  }

  // Cas 2: L'utilisateur est membre d'une famille
  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Gestion de famille
      </Typography>

      {/* Maintenant on est sûr que family n'est pas undefined */}
      <FamilyDetails family={family} />

      {/* Gestion des maisons visible uniquement pour le propriétaire */}
      {userRole === "familyOwner" && <HouseManager family={family} />}
    </Box>
  );
};

export default FamilyPage;
