import { ReactNode } from "react";

interface LayoutProps {
  children: ReactNode;
}

export function MolkyLayout({ children }: LayoutProps) {
  return (
    <div className="min-h-screen bg-base-200 py-8">
      <div className="container mx-auto px-4">
        <header className="mb-8 text-center">
          <h1 className="text-4xl font-bold">🎯 Mölkky Score 🎯</h1>
          <p className="text-lg">Garde les scores de tes parties de Mölkky</p>
        </header>

        <main>{children}</main>

        <footer className="mt-12 text-center text-gray-500 text-sm">
          Mölkky Score App - Fait avec 🧠 par Claude
        </footer>
      </div>
    </div>
  );
}
