import { useEffect, useMemo, useRef, useState } from "react";
import { CheckedRowList, Compte, RowData } from "@/pages/Bank/types.ts";
import {
  FaChartArea,
  FaChartBar,
  FaChartLine,
  FaChartPie,
  FaInfoCircle,
  FaRegClock,
  FaTable,
  FaTags,
} from "react-icons/fa";
import { useDataCompte } from "@/pages/Bank/components/hooks/useDataCompte.tsx";
import * as d3 from "d3";

type SelectedAnalyticsViewProps = {
  compte: Compte;
  selectedCell: CheckedRowList;
};

type ChartType = "pie" | "line" | "bar" | "table" | "trends" | "forecast";
type FilterType = "all" | "6months" | "year" | "custom";
type CategoryInsight = {
  category: string;
  frequency: number; // nombre de transactions
  totalAmount: number;
  averageAmount: number;
  minAmount: number;
  maxAmount: number;
  trend: "up" | "down" | "stable";
  percentChange: number;
  transactions: RowData[];
  color?: string;
};

// Palettes de couleurs pour les graphiques
const COLORS = [
  "#3b82f6",
  "#ef4444",
  "#10b981",
  "#f59e0b",
  "#8b5cf6",
  "#ec4899",
  "#06b6d4",
  "#84cc16",
  "#f97316",
  "#6366f1",
  "#14b8a6",
  "#a855f7",
  "#d946ef",
  "#facc15",
  "#0ea5e9",
];

export const SelectedAnalyticsView = ({
  compte,
  selectedCell,
}: SelectedAnalyticsViewProps) => {
  // Refs pour les graphiques
  const categoryChartRef = useRef<HTMLDivElement>(null);
  const trendChartRef = useRef<HTMLDivElement>(null);
  const comparisonChartRef = useRef<HTMLDivElement>(null);

  // États
  // const [selected, setSelected] = useState<RowData[]>([]);
  const [chartType, setChartType] = useState<ChartType>("pie");
  const [filterType, setFilterType] = useState<FilterType>("year");
  const [customDateRange, setCustomDateRange] = useState<{
    start: Date;
    end: Date;
  }>({
    start: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    end: new Date(),
  });

  // Récupérer les données via le hook
  const { lignesCompte, isLignesCompteLoading } = useDataCompte({ compte });

  const selectedRows =
    Object.keys(selectedCell).length > 0
      ? lignesCompte.filter(
          (row) =>
            Object.keys(selectedCell).includes(row._id) && selectedCell[row._id]
        )
      : [];

  // Extraire toutes les catégories uniques des lignes sélectionnées
  const selectedCategories = useMemo(() => {
    if (!selectedRows || selectedRows.length === 0) return [];

    const categories = new Set<string>();
    selectedRows.forEach((row) => {
      const category =
        row.categorie || row.categoriePersonnel || "Non catégorisé";
      categories.add(category);
    });

    return Array.from(categories);
  }, [selectedRows]);

  // Filtrer les lignes sélectionnées selon la période choisie
  const allCategoryRows = useMemo(() => {
    if (!selectedRows || selectedRows.length === 0) return [];

    // Obtenir la période selon le filtre
    let startDate = new Date();
    const endDate = new Date();

    switch (filterType) {
      case "6months":
        startDate = new Date(endDate);
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case "year":
        startDate = new Date(endDate);
        startDate.setFullYear(startDate.getFullYear() - 1);
        break;
      case "custom":
        startDate = customDateRange.start;
        break;
      case "all":
      default: // epoch
        startDate = new Date(0);
        break;
    }

    // Filtrer uniquement par période (déjà limité aux lignes sélectionnées)
    return selectedRows.filter((row) => {
      const rowDate = new Date(row.date);
      return (
        rowDate >= startDate &&
        rowDate <= (filterType === "custom" ? customDateRange.end : endDate)
      );
    });
  }, [selectedRows, filterType, customDateRange]);

  // Analyser les données par catégorie
  const categoryInsights = useMemo(() => {
    if (!allCategoryRows || allCategoryRows.length === 0) return [];

    const insights: { [key: string]: CategoryInsight } = {};

    // Grouper les transactions par catégorie
    selectedCategories.forEach((category, index) => {
      const categoryTransactions = allCategoryRows.filter((row) => {
        const rowCategory =
          row.categorie || row.categoriePersonnel || "Non catégorisé";
        return rowCategory === category;
      });

      if (categoryTransactions.length === 0) return;

      // Calculer les montants (utiliser abs pour les comparaisons)
      const amounts = categoryTransactions.map((t) => Math.abs(t.montant));
      const totalAmount = amounts.reduce((sum, amount) => sum + amount, 0);

      // Calculer la tendance (comparer première et seconde moitié de la période)
      const sortedTransactions = [...categoryTransactions].sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      );

      const midpoint = Math.floor(sortedTransactions.length / 2);
      const firstHalf = sortedTransactions.slice(0, midpoint);
      const secondHalf = sortedTransactions.slice(midpoint);

      const firstHalfTotal = firstHalf.reduce(
        (sum, t) => sum + Math.abs(t.montant),
        0
      );
      const secondHalfTotal = secondHalf.reduce(
        (sum, t) => sum + Math.abs(t.montant),
        0
      );

      let trend: "up" | "down" | "stable" = "stable";
      let percentChange = 0;

      if (firstHalfTotal > 0) {
        percentChange =
          ((secondHalfTotal - firstHalfTotal) / firstHalfTotal) * 100;
        if (percentChange > 5) trend = "up";
        else if (percentChange < -5) trend = "down";
      }

      insights[category] = {
        category,
        frequency: categoryTransactions.length,
        totalAmount,
        averageAmount: totalAmount / categoryTransactions.length,
        minAmount: Math.min(...amounts),
        maxAmount: Math.max(...amounts),
        trend,
        percentChange,
        transactions: categoryTransactions,
        color: COLORS[index % COLORS.length],
      };
    });

    // Trier par montant total
    return Object.values(insights).sort(
      (a, b) => b.totalAmount - a.totalAmount
    );
  }, [allCategoryRows, selectedCategories]);

  // Analyser l'évolution temporelle des catégories sélectionnées
  const timeSeriesData = useMemo(() => {
    if (!allCategoryRows || allCategoryRows.length === 0) return [];

    // Définir les intervalles temporels (mois)
    const startDate = allCategoryRows.reduce((earliest, row) => {
      const rowDate = new Date(row.date);
      return rowDate < earliest ? rowDate : earliest;
    }, new Date());

    const endDate = allCategoryRows.reduce((latest, row) => {
      const rowDate = new Date(row.date);
      return rowDate > latest ? rowDate : latest;
    }, new Date(0));

    // Créer les buckets mensuels
    const months: {
      [key: string]: { date: Date; [category: string]: number };
    } = {};

    // Initialiser tous les mois dans la période
    const currentDate = new Date(startDate);
    currentDate.setDate(1); // Premier jour du mois

    while (currentDate <= endDate) {
      const monthKey = `${currentDate.getFullYear()}-${String(
        currentDate.getMonth() + 1
      ).padStart(2, "0")}`;
      months[monthKey] = {
        date: new Date(currentDate),
        ...Object.fromEntries(selectedCategories.map((cat) => [cat, 0])),
      };

      // Passer au mois suivant
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    // Remplir les données
    allCategoryRows.forEach((row) => {
      const rowDate = new Date(row.date);
      const monthKey = `${rowDate.getFullYear()}-${String(
        rowDate.getMonth() + 1
      ).padStart(2, "0")}`;
      const category =
        row.categorie || row.categoriePersonnel || "Non catégorisé";

      if (months[monthKey] && selectedCategories.includes(category)) {
        months[monthKey][category] =
          (months[monthKey][category] || 0) + Math.abs(row.montant);
      }
    });

    // Convertir en tableau et trier chronologiquement
    return Object.values(months).sort(
      (a, b) => a.date.getTime() - b.date.getTime()
    );
  }, [allCategoryRows, selectedCategories]);

  // Statistiques générales
  const generalStats = useMemo(() => {
    if (!allCategoryRows || allCategoryRows.length === 0)
      return {
        totalTransactions: 0,
        totalAmount: 0,
        averageAmount: 0,
        recurrentTransactions: [],
      };

    const totalAmount = allCategoryRows.reduce(
      (sum, row) => sum + Math.abs(row.montant),
      0
    );

    // Détecter les transactions récurrentes
    const libelles = allCategoryRows.map(
      (row) => row.libelleSimplifier || (row.libelle as string) || ""
    );
    const libelleCount: { [key: string]: number } = {};

    libelles.forEach((libelle) => {
      libelleCount[libelle] = (libelleCount[libelle] || 0) + 1;
    });

    const recurrentThreshold = allCategoryRows.length > 24 ? 3 : 2; // Seuil pour considérer comme récurrent
    const recurrentTransactions = Object.entries(libelleCount)
      .filter(([_, count]) => count >= recurrentThreshold)
      .map(([libelle, count]) => {
        const transactions = allCategoryRows.filter(
          (row) =>
            (row.libelleSimplifier || (row.libelle as string) || "") === libelle
        );
        const avgAmount =
          transactions.reduce((sum, t) => sum + Math.abs(t.montant), 0) /
          transactions.length;

        return {
          libelle,
          count,
          averageAmount: avgAmount,
          transactions,
        };
      })
      .sort((a, b) => b.count - a.count);

    return {
      totalTransactions: allCategoryRows.length,
      totalAmount,
      averageAmount: totalAmount / allCategoryRows.length,
      recurrentTransactions,
    };
  }, [allCategoryRows]);

  // Créer un graphique circulaire avec D3.js
  useEffect(() => {
    if (
      chartType !== "pie" ||
      !categoryChartRef.current ||
      categoryInsights.length === 0
    )
      return;

    const width = categoryChartRef.current.clientWidth;
    const height = 300;
    const radius = Math.min(width, height) / 2;

    // Nettoyer le contenu précédent
    d3.select(categoryChartRef.current).selectAll("*").remove();

    // Créer le SVG
    const svg = d3
      .select(categoryChartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Préparer les données
    let dataToUse = [...categoryInsights];
    if (dataToUse.length > 5) {
      const otherCategories = dataToUse.slice(5);
      const otherAmount = otherCategories.reduce(
        (sum, cat) => sum + cat.totalAmount,
        0
      );

      dataToUse = dataToUse.slice(0, 5);
      dataToUse.push({
        category: "Autres",
        frequency: otherCategories.reduce((sum, cat) => sum + cat.frequency, 0),
        totalAmount: otherAmount,
        averageAmount: 0, // Non pertinent pour la catégorie "Autres"
        minAmount: 0,
        maxAmount: 0,
        trend: "stable",
        percentChange: 0,
        transactions: [],
        color: COLORS[5],
      });
    }

    // console.log("Data to use:", dataToUse);

    // Créer la fonction arc
    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    // Créer la fonction pie
    const pie = d3
      .pie<CategoryInsight>()
      .value((d) => d.totalAmount)
      .sort(null);

    // Ajouter les arcs
    const arcs = svg.selectAll("arc").data(pie(dataToUse)).enter().append("g");

    // Dessiner les paths
    arcs
      .append("path")
      .attr("d", arc as any)
      .attr("fill", (d, i) => d.data.color || COLORS[i % COLORS.length])
      .attr("stroke", "white")
      .style("stroke-width", "2px");

    // Ajouter les labels
    arcs
      .append("text")
      .attr("transform", (d) => {
        const centroid = arc.centroid(d as any);
        const x = centroid[0] * 1.4;
        const y = centroid[1] * 1.4;
        return `translate(${x}, ${y})`;
      })
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("fill", "white")
      .text((d) => {
        const percentage =
          (d.data.totalAmount /
            categoryInsights.reduce((sum, cat) => sum + cat.totalAmount, 0)) *
          100;
        return percentage >= 5 ? `${percentage.toFixed(0)}%` : "";
      });

    // Ajouter une légende
    const legend = svg
      .selectAll(".legend")
      .data(dataToUse)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr(
        "transform",
        (d, i) => `translate(-${width / 2 - 10}, ${-height / 2 + 20 + i * 20})`
      );

    legend
      .append("rect")
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", (d, i) => d.color || COLORS[i % COLORS.length]);

    legend
      .append("text")
      .attr("x", 20)
      .attr("y", 10)
      .attr("font-size", "12px")
      .text(
        (d) =>
          `${d.category.substring(0, 15)}${d.category.length > 15 ? "..." : ""}`
      );
  }, [chartType, categoryInsights]);

  // Créer un graphique linéaire pour l'évolution temporelle
  useEffect(() => {
    if (
      chartType !== "line" ||
      !trendChartRef.current ||
      timeSeriesData.length === 0
    )
      return;

    const margin = { top: 20, right: 80, bottom: 50, left: 60 };
    const width =
      trendChartRef.current.clientWidth - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Nettoyer le contenu précédent
    d3.select(trendChartRef.current).selectAll("*").remove();

    // Créer le SVG
    const svg = d3
      .select(trendChartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Échelles
    const x = d3
      .scaleTime()
      .domain(d3.extent(timeSeriesData, (d) => d.date) as [Date, Date])
      .range([0, width]);

    // Trouver la valeur maximale pour l'échelle Y
    const maxValue =
      d3.max(timeSeriesData, (d) => {
        return d3.max(selectedCategories, (cat) => d[cat] || 0) || 0;
      }) || 0;

    const y = d3
      .scaleLinear()
      .domain([0, maxValue * 1.1]) // 10% de marge en haut
      .range([height, 0]);

    // Axe X
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(
        d3.axisBottom(x).tickFormat((d) => {
          const date = new Date(d as Date);
          return date.toLocaleDateString("fr-FR", {
            month: "short",
            year: "2-digit",
          });
        })
      )
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    // Axe Y
    svg.append("g").call(
      d3
        .axisLeft(y)
        .ticks(5)
        .tickFormat((d) => `${(+d).toLocaleString()}€`)
    );

    // Générer une ligne pour chaque catégorie
    selectedCategories.forEach((category, index) => {
      const line = d3
        .line<any>()
        .x((d) => x(d.date))
        .y((d) => y(d[category] || 0));

      svg
        .append("path")
        .datum(timeSeriesData)
        .attr("fill", "none")
        .attr(
          "stroke",
          categoryInsights.find((c) => c.category === category)?.color ||
            COLORS[index % COLORS.length]
        )
        .attr("stroke-width", 2)
        .attr("d", line);

      // Ajouter le nom de la catégorie à la fin de la ligne
      const lastDataPoint = timeSeriesData[timeSeriesData.length - 1];

      svg
        .append("text")
        .attr(
          "transform",
          `translate(${width + 5}, ${y(lastDataPoint[category] || 0)})`
        )
        .attr("dy", ".35em")
        .attr("text-anchor", "start")
        .style(
          "fill",
          categoryInsights.find((c) => c.category === category)?.color ||
            COLORS[index % COLORS.length]
        )
        .style("font-size", "10px")
        .text(
          category.length > 10 ? category.substring(0, 10) + "..." : category
        );
    });
  }, [chartType, timeSeriesData, selectedCategories, categoryInsights]);

  // Créer un graphique à barres pour la comparaison
  useEffect(() => {
    if (
      chartType !== "bar" ||
      !comparisonChartRef.current ||
      categoryInsights.length === 0
    )
      return;

    const margin = { top: 20, right: 30, bottom: 100, left: 60 };
    const width =
      comparisonChartRef.current.clientWidth - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Nettoyer le contenu précédent
    d3.select(comparisonChartRef.current).selectAll("*").remove();

    // Créer le SVG
    const svg = d3
      .select(comparisonChartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Échelles
    const x = d3
      .scaleBand()
      .domain(categoryInsights.map((d) => d.category))
      .range([0, width])
      .padding(0.3);

    const y = d3
      .scaleLinear()
      .domain([0, d3.max(categoryInsights, (d) => d.totalAmount) || 0])
      .range([height, 0]);

    // Axe X
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    // Axe Y
    svg
      .append("g")
      .call(d3.axisLeft(y).tickFormat((d) => `${(+d).toLocaleString()}€`));

    // Barres
    svg
      .selectAll(".bar")
      .data(categoryInsights)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.category) as number)
      .attr("width", x.bandwidth())
      .attr("y", (d) => y(d.totalAmount))
      .attr("height", (d) => height - y(d.totalAmount))
      .attr("fill", (d) => d.color || "#3b82f6");

    // Ajouter des labels au-dessus des barres
    svg
      .selectAll(".bar-label")
      .data(categoryInsights)
      .enter()
      .append("text")
      .attr("class", "bar-label")
      .attr("x", (d) => (x(d.category) as number) + x.bandwidth() / 2)
      .attr("y", (d) => y(d.totalAmount) - 5)
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      .text((d) => `${d.frequency}x`);
  }, [chartType, categoryInsights]);

  // Formater une date pour l'affichage
  const formatDateForDisplay = (date: Date): string => {
    return new Intl.DateTimeFormat("fr-FR", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    }).format(date);
  };

  // Obtenir le titre du filtre temporel
  const getFilterTitle = () => {
    switch (filterType) {
      case "6months":
        return "6 derniers mois";
      case "year":
        return "12 derniers mois";
      case "custom":
        return `${formatDateForDisplay(
          customDateRange.start
        )} - ${formatDateForDisplay(customDateRange.end)}`;
      case "all":
      default:
        return "Toutes les données";
    }
  };

  if (isLignesCompteLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (!selectedRows || selectedRows.length === 0) {
    return (
      <div className="w-full bg-base-100 rounded-lg shadow-sm p-8 text-center">
        <FaInfoCircle className="text-3xl mx-auto mb-4 text-base-content/50" />
        <h3 className="text-xl font-semibold mb-2">
          Aucune ligne sélectionnée
        </h3>
        <p className="text-base-content/70">
          Veuillez sélectionner une ou plusieurs lignes dans le tableau pour
          afficher une analyse détaillée.
        </p>
      </div>
    );
  }

  return (
    <div className="w-full bg-base-100 rounded-b-lg shadow-sm">
      {/* Barre d'outils d'analyse */}
      <div className="p-4 border-b border-base-300 flex flex-wrap items-center justify-between gap-4">
        <div className="flex items-center">
          <div className="badge badge-primary mr-3">
            {selectedRows.length} lignes sélectionnées
          </div>
          <div className="badge badge-secondary mr-3">
            {selectedCategories.length} catégories
          </div>
          <div className="badge badge-accent">
            {allCategoryRows.length} transactions analysées
          </div>
        </div>

        <div className="flex flex-wrap items-center gap-2">
          <div className="btn-group">
            <button
              className={`btn btn-sm ${
                chartType === "pie" ? "btn-active" : ""
              }`}
              onClick={() => setChartType("pie")}
              title="Répartition par catégorie"
            >
              <FaChartPie className="text-xs" />
            </button>
            <button
              className={`btn btn-sm ${
                chartType === "line" ? "btn-active" : ""
              }`}
              onClick={() => setChartType("line")}
              title="Évolution temporelle"
            >
              <FaChartLine className="text-xs" />
            </button>
            <button
              className={`btn btn-sm ${
                chartType === "bar" ? "btn-active" : ""
              }`}
              onClick={() => setChartType("bar")}
              title="Comparaison des catégories"
            >
              <FaChartBar className="text-xs" />
            </button>
            <button
              className={`btn btn-sm ${
                chartType === "trends" ? "btn-active" : ""
              }`}
              onClick={() => setChartType("trends")}
              title="Tendances et insights"
            >
              <FaChartArea className="text-xs" />
            </button>
            <button
              className={`btn btn-sm ${
                chartType === "table" ? "btn-active" : ""
              }`}
              onClick={() => setChartType("table")}
              title="Tableau détaillé"
            >
              <FaTable className="text-xs" />
            </button>
          </div>

          {/* Filtre temporel */}
          <div className="dropdown dropdown-end">
            <label
              tabIndex={0}
              className="btn btn-sm btn-outline flex items-center gap-1"
            >
              <FaRegClock className="text-xs" /> {getFilterTitle()}
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <a onClick={() => setFilterType("6months")}>6 derniers mois</a>
              </li>
              <li>
                <a onClick={() => setFilterType("year")}>12 derniers mois</a>
              </li>
              <li>
                <a onClick={() => setFilterType("all")}>Toutes les données</a>
              </li>
              <li className="divider"></li>
              <li>
                <a onClick={() => setFilterType("custom")}>
                  Période personnalisée
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Résumé des statistiques */}
      <div className="p-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
          <div className="bg-base-200 p-4 rounded-lg shadow-sm">
            <div className="flex items-center mb-2">
              <FaTags className="mr-2 text-primary" />
              <h3 className="text-lg font-semibold">Catégories analysées</h3>
            </div>
            <div className="space-y-1">
              {selectedCategories.map((category, idx) => (
                <div key={idx} className="flex items-center">
                  <div
                    className="w-3 h-3 rounded-full mr-2"
                    style={{ backgroundColor: COLORS[idx % COLORS.length] }}
                  ></div>
                  <span className="truncate">{category}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-base-200 p-4 rounded-lg shadow-sm">
            <div className="flex items-center mb-2">
              <FaInfoCircle className="mr-2 text-primary" />
              <h3 className="text-lg font-semibold">Résumé général</h3>
            </div>
            <div className="space-y-1">
              <p>
                <span className="font-medium">Total:</span>{" "}
                {`${generalStats.totalAmount.toLocaleString()} €`}
              </p>
              <p>
                <span className="font-medium">Transactions:</span>{" "}
                {generalStats.totalTransactions}
              </p>
              <p>
                <span className="font-medium">Moyenne:</span>{" "}
                {`${generalStats.averageAmount.toLocaleString()} €`}
              </p>
              <p>
                <span className="font-medium">Tendance:</span>{" "}
                {categoryInsights.length > 0 ? (
                  categoryInsights[0].trend === "up" ? (
                    <span className="text-success">
                      ↑ {categoryInsights[0].percentChange.toFixed(1)}%
                    </span>
                  ) : categoryInsights[0].trend === "down" ? (
                    <span className="text-error">
                      ↓ {Math.abs(categoryInsights[0].percentChange).toFixed(1)}
                      %
                    </span>
                  ) : (
                    <span className="text-base-content/70">→ Stable</span>
                  )
                ) : (
                  "N/A"
                )}
              </p>
            </div>
          </div>

          <div className="bg-base-200 p-4 rounded-lg shadow-sm">
            <div className="flex items-center mb-2">
              <FaRegClock className="mr-2 text-primary" />
              <h3 className="text-lg font-semibold">
                Transactions récurrentes
              </h3>
            </div>
            <div className="space-y-1">
              {generalStats.recurrentTransactions
                .slice(0, 3)
                .map((trans, idx) => (
                  <div key={idx} className="flex justify-between">
                    <span className="truncate mr-2" style={{ maxWidth: "70%" }}>
                      {trans.libelle}
                    </span>
                    <div className="text-right text-sm">
                      <span className="badge badge-sm">{trans.count}x</span>
                      {
                        <span className="ml-1">
                          {trans.averageAmount.toLocaleString()} €
                        </span>
                      }
                    </div>
                  </div>
                ))}
              {generalStats.recurrentTransactions.length > 3 && (
                <div className="text-xs text-right mt-1 text-base-content/70">
                  +{generalStats.recurrentTransactions.length - 3} autres
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Zone principale de visualisation */}
        <div className="bg-base-200 rounded-lg shadow-sm p-6 min-h-80">
          {/* Contenu basé sur le type de graphique sélectionné */}
          {chartType === "pie" && (
            <div>
              <h3 className="text-xl font-semibold mb-4 text-center">
                Répartition par catégorie
              </h3>
              (
              <>
                {/* Graphique D3.js */}
                <div ref={categoryChartRef} className="w-full h-80"></div>

                {/* Tableau récapitulatif */}
                <div className="mt-6 overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Catégorie</th>
                        <th>Montant</th>
                        <th>%</th>
                        <th>Transactions</th>
                        <th>Tendance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryInsights.map((category, index) => (
                        <tr key={index}>
                          <td>
                            <div className="flex items-center">
                              <div
                                className="w-3 h-3 rounded-full mr-2"
                                style={{ backgroundColor: category.color }}
                              ></div>
                              {category.category}
                            </div>
                          </td>
                          <td className="text-right">
                            {category.totalAmount.toLocaleString()} €
                          </td>
                          <td className="text-right">
                            {(
                              (category.totalAmount /
                                categoryInsights.reduce(
                                  (sum, cat) => sum + cat.totalAmount,
                                  0
                                )) *
                              100
                            ).toFixed(1)}
                            %
                          </td>
                          <td className="text-center">{category.frequency}</td>
                          <td>
                            {category.trend === "up" ? (
                              <span className="text-success">
                                ↑ {category.percentChange.toFixed(1)}%
                              </span>
                            ) : category.trend === "down" ? (
                              <span className="text-error">
                                ↓ {Math.abs(category.percentChange).toFixed(1)}%
                              </span>
                            ) : (
                              <span className="text-base-content/70">
                                → Stable
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
              )
            </div>
          )}

          {chartType === "line" && (
            <div>
              <h3 className="text-xl font-semibold mb-4 text-center">
                Évolution temporelle des catégories
              </h3>
              <>
                {/* Graphique D3.js */}
                <div ref={trendChartRef} className="w-full h-80"></div>

                {/* Insights sur les tendances */}
                <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                  {categoryInsights.map((category, index) => (
                    <div
                      key={index}
                      className="bg-base-100 p-3 rounded-lg border border-base-300"
                    >
                      <div className="flex items-center mb-2">
                        <div
                          className="w-3 h-3 rounded-full mr-2"
                          style={{ backgroundColor: category.color }}
                        ></div>
                        <h4 className="font-medium">{category.category}</h4>
                      </div>
                      <p className="text-sm mb-1">
                        <span className="font-medium">Fréquence:</span>{" "}
                        {category.frequency} transactions
                      </p>
                      <p className="text-sm mb-1">
                        <span className="font-medium">Tendance:</span>{" "}
                        {category.trend === "up" ? (
                          <span className="text-success">
                            En hausse de {category.percentChange.toFixed(1)}%
                          </span>
                        ) : category.trend === "down" ? (
                          <span className="text-error">
                            En baisse de{" "}
                            {Math.abs(category.percentChange).toFixed(1)}%
                          </span>
                        ) : (
                          <span className="text-base-content/70">Stable</span>
                        )}
                      </p>
                      <p className="text-sm">
                        <span className="font-medium">Montant moyen:</span>{" "}
                        {category.averageAmount.toLocaleString()} €
                      </p>
                    </div>
                  ))}
                </div>
              </>
              )
            </div>
          )}

          {chartType === "bar" && (
            <div>
              <h3 className="text-xl font-semibold mb-4 text-center">
                Comparaison des catégories
              </h3>
              <>
                {/* Graphique D3.js */}
                <div ref={comparisonChartRef} className="w-full h-80"></div>

                {/* Statistiques comparatives */}
                <div className="mt-6 overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Catégorie</th>
                        <th>Min</th>
                        <th>Moyen</th>
                        <th>Max</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryInsights.map((category, index) => (
                        <tr key={index}>
                          <td>
                            <div className="flex items-center">
                              <div
                                className="w-3 h-3 rounded-full mr-2"
                                style={{ backgroundColor: category.color }}
                              ></div>
                              {category.category}
                            </div>
                          </td>
                          <td className="text-right">
                            {category.minAmount.toLocaleString()} €
                          </td>
                          <td className="text-right">
                            {category.averageAmount.toLocaleString()} €
                          </td>
                          <td className="text-right">
                            {category.maxAmount.toLocaleString()} €
                          </td>
                          <td className="text-right">
                            {category.totalAmount.toLocaleString()} €
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </>
              )
            </div>
          )}

          {chartType === "trends" && (
            <div>
              <h3 className="text-xl font-semibold mb-4 text-center">
                Tendances et insights
              </h3>

              <div className="mb-6">
                <h4 className="text-lg font-medium mb-3">
                  Fréquence des transactions
                </h4>
                <div className="overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Catégorie</th>
                        <th>Fréquence</th>
                        <th>Périodicité</th>
                        <th>Dernière transaction</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryInsights.map((category, index) => {
                        // Calculer la périodicité approximative
                        const sortedTransactions = [
                          ...category.transactions,
                        ].sort(
                          (a, b) =>
                            new Date(a.date).getTime() -
                            new Date(b.date).getTime()
                        );

                        let periodicityText = "Variable";
                        if (sortedTransactions.length > 1) {
                          const firstDate = new Date(
                            sortedTransactions[0].date
                          );
                          const lastDate = new Date(
                            sortedTransactions[
                              sortedTransactions.length - 1
                            ].date
                          );
                          const daysSpan =
                            (lastDate.getTime() - firstDate.getTime()) /
                            (1000 * 60 * 60 * 24);

                          if (sortedTransactions.length > 1) {
                            const avgDays =
                              daysSpan / (sortedTransactions.length - 1);

                            if (avgDays < 15)
                              periodicityText = "Bi-hebdomadaire";
                            else if (avgDays < 40)
                              periodicityText = "Mensuelle";
                            else if (avgDays < 100)
                              periodicityText = "Trimestrielle";
                            else periodicityText = "Annuelle";
                          }
                        }

                        return (
                          <tr key={index}>
                            <td>
                              <div className="flex items-center">
                                <div
                                  className="w-3 h-3 rounded-full mr-2"
                                  style={{ backgroundColor: category.color }}
                                ></div>
                                {category.category}
                              </div>
                            </td>
                            <td>{category.frequency} transactions</td>
                            <td>{periodicityText}</td>
                            <td>
                              {sortedTransactions.length > 0
                                ? formatDateForDisplay(
                                    new Date(
                                      sortedTransactions[
                                        sortedTransactions.length - 1
                                      ].date
                                    )
                                  )
                                : "N/A"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div>
                <h4 className="text-lg font-medium mb-3">
                  Transactions récurrentes détectées
                </h4>
                <div className="overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        <th>Libellé</th>
                        <th>Catégorie</th>
                        <th>Fréquence</th>
                        <th>Montant moyen</th>
                      </tr>
                    </thead>
                    <tbody>
                      {generalStats.recurrentTransactions.map((trans, idx) => {
                        // Trouver la catégorie de la transaction
                        const firstTrans = trans.transactions[0];
                        const category =
                          firstTrans.categorie ||
                          firstTrans.categoriePersonnel ||
                          "Non catégorisé";

                        return (
                          <tr key={idx}>
                            <td>{trans.libelle}</td>
                            <td>{category}</td>
                            <td>{trans.count} fois</td>
                            <td className="text-right">
                              {`${trans.averageAmount.toLocaleString()} €`}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {chartType === "table" && (
            <div>
              <h3 className="text-xl font-semibold mb-4 text-center">
                Transactions détaillées
              </h3>

              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Libellé</th>
                      <th>Catégorie</th>
                      <th>Montant</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCategoryRows
                      .sort(
                        (a, b) =>
                          new Date(b.date).getTime() -
                          new Date(a.date).getTime()
                      )
                      .map((row, idx) => (
                        <tr
                          key={idx}
                          className={
                            selectedRows.some((r) => r._id === row._id)
                              ? "bg-base-200"
                              : ""
                          }
                        >
                          <td>{formatDateForDisplay(new Date(row.date))}</td>
                          <td>
                            {row.libelleSimplifier ||
                              (row.libelle as string) ||
                              ""}
                          </td>
                          <td>
                            {row.categorie ||
                              row.categoriePersonnel ||
                              "Non catégorisé"}
                          </td>
                          <td
                            className={`text-right ${
                              row.montant < 0 ? "text-error" : "text-success"
                            }`}
                          >
                            {`${row.montant.toLocaleString()} €`}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
