import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/bank/")({
  component: RouteComponent,
  // beforeLoad: (ctx) => {
  //   console.log("ctx dans beforeLoad de /bank/:", ctx);
  // },
  // loader: async () => await getListBanque(),
});

function RouteComponent() {
  // const { tab } = Route.useParams();
  return <div>Not defined for now ... please go to 'routes/bank/index</div>;
}
