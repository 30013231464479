import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { LoginFormType, loginSchema } from "./validationSchemas";
import { useAuthContext } from "@/providers/AuthProvider";

interface LoginFormProps {
  onToggleForm: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onToggleForm }) => {
  const { login, isLoading, error, isAuthenticated } = useAuthContext();
  const navigate = useNavigate();

  // Configuration du formulaire avec react-hook-form
  const loginForm = useForm<LoginFormType>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      login: "",
      password: "",
    },
  });

  // Rediriger si déjà connecté
  useEffect(() => {
    if (isAuthenticated) {
      navigate({ to: "/" });
    }
  }, [isAuthenticated, navigate]);

  // Gestion de la soumission du formulaire
  const handleLoginSubmit = loginForm.handleSubmit(async (data) => {
    try {
      await login(data);
      // La redirection est gérée par le useEffect ci-dessus
    } catch (err) {
      console.error("Erreur lors de la connexion:", err);
    }
  });

  return (
    <form onSubmit={handleLoginSubmit} className="space-y-4">
      <div className="form-control">
        <label className="label">
          <span className="label-text">Login (email ou username)</span>
        </label>
        <input
          type="text"
          className={`input input-bordered w-full ${
            loginForm.formState.errors.login ? "input-error" : ""
          }`}
          {...loginForm.register("login")}
        />
        {loginForm.formState.errors.login && (
          <label className="label">
            <span className="label-text-alt text-error">
              {loginForm.formState.errors.login.message}
            </span>
          </label>
        )}
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Mot de passe</span>
        </label>
        <input
          type="password"
          className={`input input-bordered w-full ${
            loginForm.formState.errors.password ? "input-error" : ""
          }`}
          {...loginForm.register("password")}
        />
        {loginForm.formState.errors.password && (
          <label className="label">
            <span className="label-text-alt text-error">
              {loginForm.formState.errors.password.message}
            </span>
          </label>
        )}
      </div>

      {error && (
        <div className="alert alert-error">
          <span>{error}</span>
        </div>
      )}

      <div className="form-control mt-6">
        <button
          type="submit"
          className="btn btn-primary w-full"
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="loading loading-spinner loading-sm"></span>
          ) : (
            "Se connecter"
          )}
        </button>
      </div>

      <div className="divider">OU</div>

      {/* TODO : Add google here */}
      {/*<GoogleLogin />*/}

      <div className="divider"></div>

      <p className="text-center">
        Tu n'as pas encore de compte ?{" "}
        <button
          type="button"
          className="text-primary font-semibold"
          onClick={onToggleForm}
        >
          C'est par ici...
        </button>
      </p>
    </form>
  );
};

export default LoginForm;
