import axios from "axios";
import {
  ApiResponse,
  Journey,
  JourneySearchParams,
  Station,
} from "@/pages/API/sncf/types.ts";
import { getBackendMainUrl } from "@/utils/axios/global.ts";

const API_BASE_URL = getBackendMainUrl + "/api/sncfApi";
const HEADERS = {
  headers: {
    "content-type": "application/json",
    authorization: localStorage.getItem("authorization"),
  },
};

export const sncfApi = {
  // Recherche de stations avec autocomplétion
  async searchStations(query: string): Promise<ApiResponse<Station[]>> {
    try {
      const response = await axios.get<ApiResponse<Station[]>>(
        `${API_BASE_URL}/stations/search`,
        {
          params: { query },
          ...HEADERS,
        }
      );
      console.log("response.data", response.data);
      return response.data;
    } catch (error) {
      console.error("Error searching stations:", error);
      return {
        success: false,
        data: [],
        error: "Impossible de rechercher les stations",
      };
    }
  },

  // Récupération des détails d'une station
  async getStation(stationId: string): Promise<ApiResponse<Station>> {
    try {
      const response = await axios.get<ApiResponse<Station>>(
        `${API_BASE_URL}/stations/${stationId}`,
        HEADERS
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching station details:", error);
      return {
        success: false,
        data: {} as Station,
        error: "Impossible de récupérer les détails de la station",
      };
    }
  },

  // Recherche de trajets
  async searchJourneys(
    params: JourneySearchParams
  ): Promise<ApiResponse<Journey[]>> {
    try {
      const response = await axios.get<ApiResponse<Journey[]>>(
        `${API_BASE_URL}/journeys`,
        {
          params,
          ...HEADERS,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error searching journeys:", error);
      return {
        success: false,
        data: [],
        error: "Impossible de rechercher les trajets",
      };
    }
  },

  // Suivi en temps réel d'un train
  async getJourneyRealTimeInfo(
    journeyId: string
  ): Promise<ApiResponse<Journey>> {
    try {
      const response = await axios.get<ApiResponse<Journey>>(
        `${API_BASE_URL}/journeys/${journeyId}/realtime`,
        HEADERS
      );
      return response.data;
    } catch (error) {
      console.error("Error getting real-time info:", error);
      return {
        success: false,
        data: {} as Journey,
        error: "Impossible de récupérer les informations en temps réel",
      };
    }
  },
};
