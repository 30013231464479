import { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Compte } from "@/pages/Bank/types.ts";
import { updateCompteForUser } from "@/utils/axios/bankUtils.ts";
import {
  getUserById,
  getUserListExceptUserLogged,
} from "@/utils/axios/userUtils.ts";
import { UserType } from "@/utils/userTypes.ts";
import { showToast } from "@/components/tools/toast.ts";
import { CreditCard, Plus, Search, Users, X } from "lucide-react";

interface OwnerManagementDialogProps {
  compte: Compte;
  isOpen: boolean;
  onClose: () => void;
}

export const OwnerManagementDialog = ({
  compte,
  isOpen,
  onClose,
}: OwnerManagementDialogProps) => {
  // Fonction pour extraire les IDs des propriétaires
  const extractIds = (owners: any[]): string[] => {
    return owners
      .map((owner) =>
        typeof owner === "object" && owner?._id ? owner._id : owner
      )
      .filter((id) => typeof id === "string" && id.trim() !== "");
  };

  const initialOwners = Array.isArray(compte.owners)
    ? extractIds(compte.owners)
    : [];
  const [owners, setOwners] = useState<string[]>(initialOwners);
  const [searchTerm, setSearchTerm] = useState("");
  const queryClient = useQueryClient();

  // Réinitialiser l'état lorsque le dialogue s'ouvre ou que le compte change
  useEffect(() => {
    if (isOpen) {
      const extractedOwners = Array.isArray(compte.owners)
        ? extractIds(compte.owners)
        : [];
      setOwners(extractedOwners);
      setSearchTerm("");
    }
  }, [isOpen, compte._id]); // Dépendre de compte._id au lieu de compte.owners entier

  // Récupérer la liste des utilisateurs disponibles
  const { data: availableUsers = [], isLoading: loadingUsers } = useQuery({
    queryKey: ["users"],
    queryFn: getUserListExceptUserLogged,
    enabled: isOpen,
  });

  // Récupérer les détails des propriétaires actuels
  const { data: currentOwners = [], isLoading: loadingOwners } = useQuery({
    queryKey: ["owners", JSON.stringify(owners)],
    queryFn: async () => {
      if (!owners || owners.length === 0) return [];

      // Filtrer pour n'avoir que des chaînes de caractères valides
      const validOwnerIds = owners.filter(
        (id) => typeof id === "string" && id.trim() !== ""
      );

      if (validOwnerIds.length === 0) return [];

      try {
        const ownerPromises = validOwnerIds.map((id) => getUserById(id));
        const ownerDetails = await Promise.all(
          ownerPromises.map((p) => p.catch(() => null))
        );
        // Filtrer les éléments nuls et éliminer les doublons potentiels
        const validOwners = ownerDetails.filter(Boolean) as UserType[];
        const uniqueOwners = Array.from(
          new Map(validOwners.map((owner) => [owner._id, owner])).values()
        );
        return uniqueOwners;
      } catch (error) {
        return [];
      }
    },
    enabled: isOpen && owners.length > 0,
    retry: false,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  // Mutation pour mettre à jour le compte
  const updateMutation = useMutation({
    mutationFn: (updatedCompte: Partial<Compte>) =>
      updateCompteForUser(updatedCompte as Compte),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["compte"] });
      queryClient.invalidateQueries({ queryKey: ["compte-info", compte._id] });
      showToast("success", "Propriétaires mis à jour avec succès!");
      onClose();
    },
    onError: (error: any) => {
      showToast(
        "error",
        `Erreur lors de la mise à jour: ${error.message || "Erreur inconnue"}`
      );
    },
  });

  const handleAddOwner = (userId: string) => {
    if (!owners.includes(userId)) {
      setOwners([...owners, userId]);
    }
  };

  const handleRemoveOwner = (userId: string) => {
    setOwners(owners.filter((id) => id !== userId));
  };

  const handleSave = () => {
    updateMutation.mutate({
      _id: compte._id,
      owners: owners,
    });
  };

  // Filtrer les utilisateurs disponibles selon le terme de recherche
  const filteredUsers = availableUsers.filter((user: UserType) => {
    return (
      !owners.includes(user._id) &&
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Créer une fonction de fermeture stable avec useCallback
  const handleClose = useCallback(() => {
    // Annuler toutes les requêtes en cours pour ce compte
    queryClient.cancelQueries({ queryKey: ["owners"] });
    onClose();
  }, [onClose, queryClient]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 p-4">
      <div
        className="bg-base-100 rounded-lg shadow-xl w-full max-w-md overflow-hidden"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="bg-base-300 p-4 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <CreditCard className="text-primary" size={20} />
            <h3 className="text-lg font-bold">Gérer les propriétaires</h3>
          </div>
          <button
            onClick={handleClose}
            className="btn btn-sm btn-circle btn-ghost"
            aria-label="Fermer"
          >
            <X size={18} />
          </button>
        </div>

        <div className="p-6">
          {/* Nom du compte */}
          <div className="mb-4">
            <div className="flex items-center gap-2">
              <CreditCard size={16} className="text-gray-500" />
              <h4 className="font-medium">{compte.nomCompte}</h4>
            </div>
            <div className="divider my-2"></div>
          </div>

          {/* Liste des propriétaires actuels */}
          <div className="mb-4">
            <div className="flex items-center gap-2 mb-2">
              <Users size={16} className="text-primary" />
              <h4 className="font-medium">Propriétaires actuels</h4>
            </div>

            {loadingOwners ? (
              <div className="flex justify-center my-4">
                <span className="loading loading-spinner text-primary"></span>
              </div>
            ) : currentOwners.length === 0 ? (
              <div className="alert alert-warning">
                <span className="text-sm">
                  Aucun propriétaire. Ajoutez au moins un utilisateur.
                </span>
              </div>
            ) : (
              <div className="flex flex-wrap gap-2 mb-2">
                {currentOwners.map((owner: UserType) => (
                  <div
                    key={`current-${owner._id}`}
                    className="badge badge-lg badge-primary gap-1"
                  >
                    {owner.username}
                    <button
                      onClick={() => handleRemoveOwner(owner._id)}
                      className="btn btn-xs btn-ghost btn-circle"
                    >
                      <X size={12} />
                    </button>
                  </div>
                ))}
                {/* Afficher les IDs qui n'ont pas pu être résolus en utilisateurs */}
                {owners
                  .filter(
                    (id) => !currentOwners.some((owner) => owner._id === id)
                  )
                  .map((id) => (
                    <div
                      key={`unresolved-${id}`}
                      className="badge badge-lg badge-warning gap-1"
                    >
                      ID: {id.substring(0, 6)}...
                      <button
                        onClick={() => handleRemoveOwner(id)}
                        className="btn btn-xs btn-ghost btn-circle"
                      >
                        <X size={12} />
                      </button>
                    </div>
                  ))}
              </div>
            )}
          </div>

          {/* Ajouter de nouveaux propriétaires */}
          <div className="mb-4">
            <div className="flex items-center gap-2 mb-2">
              <Plus size={16} className="text-success" />
              <h4 className="font-medium">Ajouter des propriétaires</h4>
            </div>

            {/* Barre de recherche */}
            <div className="form-control mb-2">
              <div className="input-group">
                <span className="btn btn-square btn-sm bg-base-300">
                  <Search size={16} />
                </span>
                <input
                  type="text"
                  placeholder="Rechercher un utilisateur..."
                  className="input input-bordered input-sm w-full"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            {loadingUsers ? (
              <div className="flex justify-center my-4">
                <span className="loading loading-spinner text-primary"></span>
              </div>
            ) : (
              <div className="max-h-40 overflow-y-auto pr-2 rounded-lg bg-base-200">
                {filteredUsers.length === 0 ? (
                  <div className="p-4 text-center text-gray-500">
                    {searchTerm
                      ? "Aucun utilisateur trouvé pour cette recherche"
                      : "Aucun utilisateur disponible"}
                  </div>
                ) : (
                  filteredUsers.map((user: UserType) => (
                    <div
                      key={user._id}
                      className="flex items-center justify-between p-2 hover:bg-base-300 cursor-pointer border-b border-base-300 last:border-b-0"
                      onClick={() => handleAddOwner(user._id)}
                    >
                      <span className="font-medium">{user.username}</span>
                      <button className="btn btn-xs btn-ghost btn-circle text-success">
                        <Plus size={14} />
                      </button>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>

          {/* Boutons d'action */}
          <div className="flex justify-end gap-2 mt-6">
            <button className="btn btn-outline" onClick={handleClose}>
              Annuler
            </button>
            <button
              className="btn btn-primary"
              onClick={handleSave}
              disabled={updateMutation.isPending || owners.length === 0}
            >
              {updateMutation.isPending ? (
                <>
                  <span className="loading loading-spinner loading-xs"></span>
                  Enregistrement...
                </>
              ) : (
                "Enregistrer"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
