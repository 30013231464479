import { useState } from "react";
import { nanoid } from "nanoid";
import { Player, UsePlayersReturn } from "@/pages/Molky/types.ts";

export function usePlayers(): UsePlayersReturn {
  const [players, setPlayers] = useState<Player[]>([]);

  const addPlayer = (name: string) => {
    if (name.trim() === "") return;

    const newPlayer: Player = {
      id: nanoid(),
      name,
      score: 0,
      history: [],
    };

    setPlayers((prev) => [...prev, newPlayer]);
  };

  const removePlayer = (id: string) => {
    setPlayers((prev) => prev.filter((player) => player.id !== id));
  };

  const resetPlayers = () => {
    setPlayers((prev) =>
      prev.map((player) => ({
        ...player,
        score: 0,
        history: [],
      }))
    );
  };

  const updatePlayerName = (id: string, name: string) => {
    if (name.trim() === "") return;

    setPlayers((prev) =>
      prev.map((player) => (player.id === id ? { ...player, name } : player))
    );
  };

  return {
    players,
    addPlayer,
    removePlayer,
    resetPlayers,
    setPlayers,
    updatePlayerName,
  };
}
