import { createFileRoute } from "@tanstack/react-router";
import AuthForm from "@/features/Login/AuthForm.tsx";

export const Route = createFileRoute("/_utilsRoutes/login2")({
  component: RouteComponent,
});

function RouteComponent() {
  return <AuthForm />;
}
