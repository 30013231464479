import React from "react";
import { useRealTimeInfo } from "../hooks/useSncfApi";
import { Journey } from "@/pages/API/sncf/types.ts";

interface JourneyCardProps {
  journey: Journey;
  onSelect?: () => void;
  selected?: boolean;
  showRealTimeInfo?: boolean;
}

const JourneyCard: React.FC<JourneyCardProps> = ({
  journey,
  onSelect,
  selected = false,
  showRealTimeInfo = false,
}) => {
  // Format des dates et heures
  const formatTime = (dateString: string) => {
    // Gérer le format "22/03/2025 14:13" ou format ISO
    if (dateString.includes("/")) {
      const [, timePart] = dateString.split(" ");
      return timePart;
    }

    // Gérer le format "20250322T141300"
    if (dateString.includes("T")) {
      const timeStr = dateString.split("T")[1];
      return `${timeStr.substring(0, 2)}:${timeStr.substring(2, 4)}`;
    }

    // Si c'est déjà au format Date
    return new Date(dateString).toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // Format de la durée (seconds -> minutes)
  const formatDuration = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h${mins.toString().padStart(2, "0")}`;
  };

  // Récupération des informations en temps réel si nécessaire
  const { data: realtimeJourney, isLoading: isLoadingRealtime } =
    useRealTimeInfo(
      showRealTimeInfo ? journey.id : null,
      showRealTimeInfo ? 30000 : 0 // Rafraîchir toutes les 30 secondes si activé
    );

  // Utiliser le journey original si les données en temps réel ne sont pas disponibles
  const currentJourney = realtimeJourney || journey;
  const delay = currentJourney.delay || 0;

  // Obtenir le premier train (principal)
  const mainTrain =
    currentJourney.trains && currentJourney.trains.length > 0
      ? currentJourney.trains[0]
      : null;

  // Détermination du statut
  const getStatus = () => {
    if (delay > 0) return "delayed";
    if (currentJourney.departure.realTime === null) return "cancelled";
    return "on_time";
  };

  const status = getStatus();

  // Classe CSS pour le statut
  const getStatusClass = () => {
    switch (status) {
      case "delayed":
        return "text-warning";
      case "cancelled":
        return "text-error";
      default:
        return "text-success";
    }
  };

  // Texte du statut
  const getStatusText = () => {
    switch (status) {
      case "delayed":
        return `Retard de ${delay} min`;
      case "cancelled":
        return "Annulé";
      default:
        return "À l'heure";
    }
  };

  // Extraire le nom de la ville à partir de la chaîne "Station (Ville)"
  const extractCityName = (stationString: string) => {
    if (!stationString) return "";

    // Si le format est "Station (Ville)", extraire la ville
    const match = stationString.match(/\(([^)]+)\)/);
    return match ? match[1] : stationString;
  };

  return (
    <div
      className={`card bg-base-100 shadow-md hover:shadow-lg transition-shadow cursor-pointer mb-4 ${
        selected ? "border-2 border-primary" : ""
      }`}
      onClick={onSelect}
    >
      <div className="card-body p-4">
        <div className="flex justify-between items-center">
          <div className="badge badge-lg">
            {mainTrain?.trainName || "TRAIN"}
          </div>
          <div className="badge badge-outline">
            {mainTrain?.trainNumber || ""}
          </div>
        </div>

        <div className="grid grid-cols-3 gap-2 mt-3">
          <div className="text-center">
            <p className="text-2xl font-bold">
              {formatTime(
                currentJourney.departure.realTime ||
                  currentJourney.departure.scheduledTime
              )}
            </p>
            <p className="font-medium mt-1">
              {currentJourney.departure.station.split(" (")[0]}
            </p>
            <p className="text-xs opacity-70">
              {extractCityName(currentJourney.departure.station)}
            </p>
          </div>

          <div className="flex flex-col items-center justify-center">
            <p className="text-sm font-medium">
              {formatDuration(currentJourney.duration)}
            </p>
            <div className="flex items-center w-full my-1">
              <div className="h-0.5 bg-gray-300 flex-grow"></div>
              <div className="mx-1">→</div>
              <div className="h-0.5 bg-gray-300 flex-grow"></div>
            </div>
            {showRealTimeInfo && (
              <div className={`text-xs ${getStatusClass()}`}>
                {isLoadingRealtime ? (
                  <span className="loading loading-dots loading-xs"></span>
                ) : (
                  getStatusText()
                )}
              </div>
            )}
          </div>

          <div className="text-center">
            <p className="text-2xl font-bold">
              {formatTime(
                currentJourney.arrival.realTime ||
                  currentJourney.arrival.scheduledTime
              )}
            </p>
            <p className="font-medium mt-1">
              {currentJourney.arrival.station.split(" (")[0]}
            </p>
            <p className="text-xs opacity-70">
              {extractCityName(currentJourney.arrival.station)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyCard;
