import React, { useEffect, useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import {
  Cancel as CancelIcon,
  Edit as EditIcon,
  Group as GroupIcon,
  Home as HomeIcon,
  Person as PersonIcon,
  Save as SaveIcon,
  Upload as UploadIcon,
} from "@mui/icons-material";
import { getAvatarUrl, getUserLogged } from "@/utils/axios/userUtils";
import { useFamilyDetails } from "@/hooks/familyHooks";
import { Link } from "@tanstack/react-router";
import { useUpdateProfile } from "@/hooks/profileHooks";

const ProfilePage: React.FC = () => {
  // Récupérer l'utilisateur une seule fois au chargement du composant
  // et non à chaque rendu pour éviter les boucles infinies
  const user = React.useMemo(() => getUserLogged(), []);

  const [editMode, setEditMode] = useState(false);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [avatarPreview, setAvatarPreview] = useState<string | null>(null);
  const [formValues, setFormValues] = useState({
    username: user?.username || "",
    email: user?.email || "",
  });
  const [successMessage, setSuccessMessage] = useState("");

  const { data: family, isLoading: isFamilyLoading } = useFamilyDetails();
  const updateProfileMutation = useUpdateProfile();

  // Réinitialise le formulaire uniquement lors du montage du composant
  // ou lorsque l'utilisateur change (par exemple après une déconnexion puis reconnexion)
  useEffect(() => {
    if (user) {
      setFormValues({
        username: user.username || "",
        email: user.email || "",
      });
    }
  }, [user]); // Dépendance uniquement à "user", pas à "currentUser"

  // Vérifie si l'utilisateur est connecté
  if (!user) {
    return (
      <Box p={3} textAlign="center">
        <Alert severity="warning" sx={{ mb: 2 }}>
          Vous devez être connecté pour accéder à votre profil.
        </Alert>
        <Button variant="contained" component={Link} to="/login">
          Se connecter
        </Button>
      </Box>
    );
  }

  // Gestion du changement d'avatar
  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setAvatarFile(file);

      // Crée une URL pour prévisualiser l'image
      const reader = new FileReader();
      reader.onload = (event) => {
        setAvatarPreview(event.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Gestion du changement des champs du formulaire
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Sauvegarde des modifications du profil
  const handleSaveProfile = () => {
    const formData = new FormData();
    formData.append("username", formValues.username);
    formData.append("email", formValues.email);

    if (avatarFile) {
      formData.append("avatar", avatarFile);
    }

    updateProfileMutation.mutate(
      { id: user._id, data: formData },
      {
        onSuccess: () => {
          setEditMode(false);
          setAvatarFile(null);
          setAvatarPreview(null);
          setSuccessMessage("Profil mis à jour avec succès !");
        },
      }
    );
  };

  // Annuler les modifications
  const handleCancelEdit = () => {
    setEditMode(false);
    setAvatarFile(null);
    setAvatarPreview(null);
    setFormValues({
      username: user.username || "",
      email: user.email || "",
    });
  };

  // Obtient le rôle en français
  const getRoleName = (role: string) => {
    switch (role) {
      case "familyOwner":
        return "Propriétaire de famille";
      case "familyMember":
        return "Membre de famille";
      case "user":
        return "Utilisateur";
      case "admin":
        return "Administrateur";
      default:
        return "Utilisateur";
    }
  };

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Mon Profil
      </Typography>

      <Grid container spacing={3}>
        {/* Informations de profil */}
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={3}
              >
                <Box position="relative" mb={2}>
                  <Avatar
                    src={
                      avatarPreview ||
                      (user.avatar ? getAvatarUrl(user.avatar) : undefined)
                    }
                    alt={user.username}
                    sx={{ width: 120, height: 120, mb: 1 }}
                  />

                  {editMode && (
                    <Box position="absolute" bottom={0} right={0}>
                      <Button
                        component="label"
                        variant="contained"
                        size="small"
                        startIcon={<UploadIcon />}
                      >
                        Changer
                        <input
                          type="file"
                          hidden
                          accept="image/*"
                          onChange={handleAvatarChange}
                        />
                      </Button>
                    </Box>
                  )}
                </Box>

                <Chip
                  label={getRoleName(user.role || "user")}
                  color={
                    user.role === "admin"
                      ? "error"
                      : user.role === "familyOwner"
                      ? "primary"
                      : user.role === "familyMember"
                      ? "info"
                      : "default"
                  }
                  variant="outlined"
                  icon={<PersonIcon />}
                  sx={{ mb: 2 }}
                />

                {!editMode ? (
                  <Typography variant="h5">{user.username}</Typography>
                ) : (
                  <TextField
                    name="username"
                    label="Nom d'utilisateur"
                    value={formValues.username}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                )}

                {!editMode ? (
                  <Typography color="textSecondary">{user.email}</Typography>
                ) : (
                  <TextField
                    name="email"
                    label="Email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="email"
                  />
                )}
              </Box>

              <Divider sx={{ mb: 2 }} />

              <Box display="flex" justifyContent="center" gap={2}>
                {!editMode ? (
                  <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    onClick={() => setEditMode(true)}
                  >
                    Modifier
                  </Button>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      startIcon={<CancelIcon />}
                      onClick={handleCancelEdit}
                    >
                      Annuler
                    </Button>

                    <Button
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={handleSaveProfile}
                      disabled={updateProfileMutation.isPending}
                    >
                      {updateProfileMutation.isPending
                        ? "Enregistrement..."
                        : "Enregistrer"}
                    </Button>
                  </>
                )}
              </Box>

              {updateProfileMutation.isError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {updateProfileMutation.error?.message ||
                    "Une erreur est survenue lors de la mise à jour du profil."}
                </Alert>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* Informations de famille */}
        <Grid item xs={12} md={6}>
          <Card elevation={3}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
                <GroupIcon sx={{ mr: 1 }} color="primary" />
                <Typography variant="h6">Ma Famille</Typography>
              </Box>

              {isFamilyLoading ? (
                <Box display="flex" justifyContent="center" p={3}>
                  <CircularProgress />
                </Box>
              ) : family ? (
                <>
                  <Box mb={2}>
                    <Typography variant="subtitle1">
                      <strong>Nom de la famille:</strong> {family.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Rôle:</strong>{" "}
                      {user._id === family.owner._id
                        ? "Propriétaire"
                        : "Membre"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Membres:</strong> {family.members.length}
                    </Typography>
                  </Box>

                  <Divider sx={{ mb: 2 }} />

                  <Box>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      <HomeIcon sx={{ verticalAlign: "middle", mr: 1 }} />
                      Maisons ({family.houses.length})
                    </Typography>

                    {family.houses.length > 0 ? (
                      <Box component="ul" sx={{ pl: 2 }}>
                        {family.houses.map((house) => (
                          <Typography component="li" key={house._id}>
                            {house.name}{" "}
                            {house.address ? `- ${house.address}` : ""}
                          </Typography>
                        ))}
                      </Box>
                    ) : (
                      <Typography color="textSecondary">
                        Aucune maison
                      </Typography>
                    )}
                  </Box>

                  <Box mt={3} display="flex" justifyContent="center">
                    <Button variant="outlined" component={Link} to="/family">
                      Gérer ma famille
                    </Button>
                  </Box>
                </>
              ) : (
                <Paper sx={{ p: 3, textAlign: "center" }}>
                  <Typography paragraph>
                    Vous n'appartenez à aucune famille pour le moment.
                  </Typography>
                  <Button variant="contained" component={Link} to="/family">
                    Créer ou rejoindre une famille
                  </Button>
                </Paper>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={5000}
        onClose={() => setSuccessMessage("")}
        message={successMessage}
      />
    </Box>
  );
};

export default ProfilePage;
