import { useState } from "react";
import { ScoreBoard } from "@/pages/Molky/components/ScoreBoard.tsx";
import { Stats } from "@/pages/Molky/components/Stats.tsx";
import { MolkyLayout } from "@/pages/Molky/components/MolkyLayout.tsx";
import { PlayerForm } from "@/pages/Molky/components/PlayerForm.tsx";
import { GameControls } from "@/pages/Molky/components/GameControls.tsx";
import { useGame } from "@/pages/Molky/hooks/useGame.ts";
import { usePlayers } from "@/pages/Molky/hooks/usePlayer.ts";
import { nanoid } from "nanoid";
import { GameHistoryComponent } from "@/pages/Molky/components/GameHistoryComponent.tsx";
import { api } from "@/pages/Molky/services/api.ts";

export const MolkyMainPage = () => {
  const [gameStarted, setGameStarted] = useState(false);
  const [gameSummary, setGameSummary] = useState<string | undefined>(undefined);
  const { players, addPlayer, resetPlayers, setPlayers } = usePlayers();
  const {
    game,
    currentPlayer,
    addScore,
    startNewGame,
    isGameFinished,
    winner,
    undoLastScore,
  } = useGame(players);

  const handleStartGame = () => {
    if (players.length >= 2) {
      startNewGame(players);
      setGameStarted(true);
      setGameSummary(undefined);
    }
  };

  const handleNewGame = () => {
    resetPlayers();
    setGameStarted(false);
    setGameSummary(undefined);
  };

  const handleSaveGame = async (generateSummary: boolean) => {
    try {
      const response = await api.saveGame(game, generateSummary);
      if (response.success && response.summary) {
        setGameSummary(response.summary);
      }
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de la partie", error);
    }
  };

  const handleSelectPlayersFromHistory = (playerNames: string[]) => {
    // Créer de nouveaux joueurs basés sur les noms
    const newPlayers = playerNames.map((name) => ({
      id: nanoid(),
      name,
      score: 0,
      history: [],
    }));

    // Mettre à jour la liste des joueurs
    setPlayers(newPlayers);

    // Démarrer une nouvelle partie avec ces joueurs
    handleStartGame();
  };

  return (
    <MolkyLayout>
      {!gameStarted ? (
        <div className="space-y-6">
          <PlayerForm
            onAddPlayer={addPlayer}
            onStartGame={handleStartGame}
            players={players}
          />

          <GameHistoryComponent
            onSelectPlayers={handleSelectPlayersFromHistory}
          />
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <GameControls
              currentPlayer={currentPlayer}
              onAddScore={addScore}
              onNewGame={handleNewGame}
              onUndoLastScore={undoLastScore}
              isGameFinished={isGameFinished}
              winner={winner}
              onSaveGame={handleSaveGame}
              gameSummary={gameSummary}
            />

            {!isGameFinished && (
              <div className="mt-6">
                <Stats players={game.players} />
              </div>
            )}
          </div>

          <ScoreBoard
            players={game.players}
            currentPlayerId={currentPlayer?.id}
          />
        </div>
      )}
    </MolkyLayout>
  );
};
