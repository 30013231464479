import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { decodeToken } from "react-jwt";
import axios from "axios";
import { getBackendMainUrl } from "@/utils/axios/global";
import { Jwt, UserType } from "@/utils/userTypes.ts";
import { sendRegisterForm } from "@/utils/axios/userUtils.ts";

const BASE_URL_API = getBackendMainUrl + "/users";

// Types pour l'état
interface AuthState {
  user: UserType | null;
  token: string | null;
  isLoading: boolean;
  error: string | null;
  family: Family | null;
}

// Type pour la famillle
export interface FamilyMember {
  _id: string;
  username: string;
  email: string;
  avatar?: string;
}

export interface House {
  _id: string;
  name: string;
  address: string;
  family: string;
}

export interface Family {
  _id: string;
  name: string;
  owner: FamilyMember;
  members: FamilyMember[];
  houses: House[];
  inviteCode: string;
  createdAt?: string;
  updatedAt?: string;
}

// État initial
const initialState: AuthState = {
  user: null,
  token: localStorage.getItem("authorization")?.split(" ")[1] || null,
  isLoading: false,
  error: null,
  family: null,
};

// Fonction d'aide pour décoder le token et extraire l'utilisateur
const extractUserFromToken = (token: string): UserType | null => {
  try {
    const decoded: Jwt | null = decodeToken(token);
    return decoded?.user || null;
  } catch (error) {
    console.error("Erreur lors du décodage du token:", error);
    return null;
  }
};

// Intercepteur pour les requêtes
export const setupAxiosInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("authorization");
      if (token) {
        config.headers.authorization = token;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        // Token expiré ou invalide
        localStorage.removeItem("authorization");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
};

// AsyncThunks pour les actions asynchrones
export const login = createAsyncThunk(
  "auth/login",
  async (
    { login, password }: { login: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(`${BASE_URL_API}/login`, {
        login,
        password,
      });
      const token = response.data;

      // Stocker le token
      localStorage.setItem("authorization", `Bearer ${token}`);

      // Retourner le token pour le stocker dans le state
      return token;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await sendRegisterForm(formData);
      // Ne stocke le token que si la réponse est valide
      if (response && typeof response === "string") {
        localStorage.setItem("authorization", `Bearer ${response}`);
        return response;
      }
      return rejectWithValue("Réponse invalide du serveur");
    } catch (error: any) {
      console.error("Erreur d'inscription détaillée:", error);
      if (error.response) {
        console.error("Réponse d'erreur:", error.response.data);
        console.error("Status:", error.response.status);
      }
      // Ne pas stocker l'erreur dans le token
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async (credential: string, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL_API}/auth/google`, {
        credential,
      });
      const token = response.data;

      localStorage.setItem("authorization", `Bearer ${token}`);
      return token;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (
    { userId, data }: { userId: string; data: FormData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.patch(`${BASE_URL_API}/${userId}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Si un nouveau token est renvoyé
      if (response.data?.token) {
        localStorage.setItem("authorization", `Bearer ${response.data.token}`);
        return response.data.token;
      }

      return response.data.user || response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchFamilyDetails = createAsyncThunk(
  "auth/fetchFamilyDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL_API}/family/details`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("authorization");
      state.user = null;
      state.token = null;
      state.family = null;
      state.error = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    initializeAuth: (state) => {
      const token = localStorage.getItem("authorization")?.split(" ")[1];
      if (token) {
        state.token = token;
        state.user = extractUserFromToken(token);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.token = action.payload;
        state.user = extractUserFromToken(action.payload);
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Register
      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.token = action.payload;
        state.user = extractUserFromToken(action.payload);
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Google Login
      .addCase(googleLogin.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        googleLogin.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.isLoading = false;
          state.token = action.payload;
          state.user = extractUserFromToken(action.payload);
        }
      )
      .addCase(googleLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Update Profile
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        if (typeof action.payload === "string") {
          // Si c'est un token
          state.token = action.payload;
          state.user = extractUserFromToken(action.payload);
        } else if (action.payload && "_id" in action.payload) {
          // Si c'est un user
          state.user = action.payload as UserType;
        }
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Fetch Family Details
      .addCase(fetchFamilyDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFamilyDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.family = action.payload;
      })
      .addCase(fetchFamilyDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { logout, clearError, initializeAuth } = authSlice.actions;

export default authSlice.reducer;
