import React, { useEffect, useState } from "react";
import { CheckedRowList, Compte, RowData } from "@/pages/Bank/types.ts";
import { useDataCompte } from "@/pages/Bank/components/hooks/useDataCompte.tsx";
import { ColumnsToggler } from "@/pages/Bank/components/Table/parts/ColumnToggler.tsx";
import { ExportButton } from "@/pages/Bank/components/Table/parts/ExportButton.tsx";
import { DataTable } from "@/pages/Bank/components/Table/parts/DataTable.tsx";
import { PageSizeSelector } from "@/pages/Bank/components/Table/parts/PageSizeSelector.tsx";
import { Pagination } from "@/pages/Bank/components/Table/parts/Pagination.tsx";
import PrevisionPane from "@/pages/Bank/components/Prevision/PrevisionPane.tsx";
import { FilterPanel } from "@/pages/Bank/components/Table/parts/FilterPanel";
import { Filter, SortConfig } from "@/pages/Bank/components/Table/TableMain";
import {
  FaBuilding,
  FaColumns,
  FaEye,
  FaEyeSlash,
  FaFileExport,
  FaLock,
  FaLockOpen,
  FaPiggyBank,
  FaPrint,
  FaTag,
  FaUniversity,
  FaUserFriends,
  FaWallet,
} from "react-icons/fa";
import { CompteInfoBar } from "@/pages/Bank/components/Table/parts/CompteInfoBar.tsx";
import {
  loadPreferenceLocalStorage,
  savePreferenceLocalStorage,
} from "@/pages/Bank/utils.ts";

// Mapping des clés de colonnes vers des noms plus lisibles pour l'en-tête
const COLUMN_LABELS: { [key: string]: string } = {
  _id: "ID",
  date: "Date",
  libelleSimplifier: "Libellé simplifié",
  categorie: "Catégorie",
  sousCategorie: "Sous-catégorie",
  solde: "Solde",
  montant: "Montant",
  debit: "Débit",
  credit: "Crédit",
  pointageOperation: "Pointage",
  dateValeur: "Date valeur",
  dateOperation: "Date opération",
  reference: "Référence",
  informationComplementaire: "Info. compl.",
  typeOperation: "Type",
  libellerOperation: "Libellé",
  precision: "Précision",
  categoriePersonnel: "Catégorie perso.",
  sousCategoriePersonnel: "Sous-cat. perso.",
};

// Préfixe pour les clés localStorage pour éviter les conflits
const STORAGE_PREFIX = "bank_table_";

interface InterfaceTableVue {
  compte: Compte;
  changeView?: (view: string) => void;
  isValueHidden: boolean;
  setIsValueHidden: (value: boolean) => void;
  generatePagination: () => (number | string)[];
  totalPages: number;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  selectedCell: CheckedRowList;
  toggleColumnVisibility: (columnKey: string) => void;
  visibleColumns: { [key: string]: boolean };
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  currentPageData: RowData[];
  handleSelectRow: (id: string, isChecked: boolean) => void;
  areAllCurrentPageRowsSelected: () => boolean;
  handleSelectAllOnPage: (event: React.ChangeEvent<HTMLInputElement>) => void;

  // Nouvelles props pour le tri et le filtrage
  sortConfig: SortConfig;
  handleSort: (column: keyof RowData) => void;
  filters: Filter[];
  handleAddFilter: (filter: Filter) => void;
  handleUpdateFilter: (index: number, filter: Filter) => void;
  handleRemoveFilter: (index: number) => void;
  handleClearFilters: () => void;
  isLoading?: boolean;
  totalRowCount?: number;
}

export const TableView = ({
  compte,
  // changeView,
  isValueHidden,
  setIsValueHidden,
  generatePagination,
  totalPages,
  currentPage,
  setCurrentPage,
  selectedCell,
  toggleColumnVisibility,
  visibleColumns,
  pageSize,
  setPageSize,
  currentPageData,
  handleSelectRow,
  areAllCurrentPageRowsSelected,
  handleSelectAllOnPage,
  // Nouvelles props pour le tri et le filtrage
  sortConfig,
  handleSort,
  filters,
  handleAddFilter,
  handleUpdateFilter,
  handleRemoveFilter,
  handleClearFilters,
  isLoading = false,
  totalRowCount = 0,
}: InterfaceTableVue) => {
  // Calculer les indices pour l'affichage "X à Y sur Z entrées"
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalRowCount);

  // États avec initialisation depuis localStorage
  const [exportMode, setExportMode] = useState<boolean>(() =>
    loadPreferenceLocalStorage("exportMode", false, STORAGE_PREFIX, compte._id)
  );
  const [isPrevisionPaneVisible, setIsPrevisionPaneVisible] = useState(() =>
    loadPreferenceLocalStorage(
      "previsionPane",
      false,
      STORAGE_PREFIX,
      compte._id
    )
  );
  const [isCompactMode, setIsCompactMode] = useState(() =>
    loadPreferenceLocalStorage("compactMode", false, STORAGE_PREFIX, compte._id)
  );

  const {
    // Données
    lignesCompte,
    isLignesCompteLoading,
    isLignesCompteError,
    lignesPrevision,

    // Utilitaires
    formatDateValue,
  } = useDataCompte({
    compte,
  });

  // Sauvegarde des préférences lors des changements
  useEffect(() => {
    savePreferenceLocalStorage(
      "exportMode",
      exportMode,
      STORAGE_PREFIX,
      compte._id
    );
  }, [exportMode, compte._id]);

  useEffect(() => {
    savePreferenceLocalStorage(
      "previsionPane",
      isPrevisionPaneVisible,
      STORAGE_PREFIX,
      compte._id
    );
  }, [isPrevisionPaneVisible, compte._id]);

  useEffect(() => {
    savePreferenceLocalStorage(
      "compactMode",
      isCompactMode,
      STORAGE_PREFIX,
      compte._id
    );
  }, [isCompactMode, compte._id]);

  useEffect(() => {
    savePreferenceLocalStorage(
      "valueHidden",
      isValueHidden,
      STORAGE_PREFIX,
      compte._id
    );
  }, [isValueHidden, compte._id]);

  useEffect(() => {
    savePreferenceLocalStorage(
      "pageSize",
      pageSize,
      STORAGE_PREFIX,
      compte._id
    );
  }, [pageSize, compte._id]);

  // Gestionnaire pour basculer le mode d'affichage compact
  const toggleCompactMode = () => {
    setIsCompactMode(!isCompactMode);
  };

  // Gestionnaire pour basculer le mode d'exportation
  const toggleExportMode = () => {
    setExportMode(!exportMode);
  };

  // Gestionnaire pour basculer la visibilité des montants
  const toggleValueVisibility = () => {
    setIsValueHidden(!isValueHidden);
  };

  // Gestionnaire pour basculer la visibilité du PrevisionPane
  const togglePrevisionPaneVisibility = () => {
    setIsPrevisionPaneVisible(!isPrevisionPaneVisible);
  };

  // Gestionnaire pour l'impression
  const handlePrint = () => {
    window.print();
  };

  // Wrapper pour le toggleColumnVisibility qui sauvegarde dans localStorage
  const handleToggleColumnVisibility = (columnKey: string) => {
    toggleColumnVisibility(columnKey);

    // Sauvegarde après un court délai pour laisser le temps à l'état de se mettre à jour
    setTimeout(() => {
      savePreferenceLocalStorage(
        "visibleColumns",
        visibleColumns,
        STORAGE_PREFIX,
        compte._id
      );
    }, 50);
  };

  // État de chargement combiné
  const isDataLoading = isLoading || isLignesCompteLoading;

  if (isLignesCompteError) {
    return (
      <div className="alert alert-error" role="alert">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="stroke-current shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span>Une erreur est survenue lors du chargement des données.</span>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col">
        {/* En-tête avec le nom du compte et les informations */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 p-4 bg-base-200 rounded-t-lg shadow-sm">
          <div className="flex items-center gap-3">
            {/* Icône de compte basée sur le type */}
            <div className="bg-primary/10 p-3 rounded-full">
              {compte.typeCompte === "Compte personnel" ? (
                <FaWallet className="text-primary text-xl" />
              ) : compte.typeCompte === "Compte Joint" ? (
                <FaUserFriends className="text-primary text-xl" />
              ) : compte.typeCompte === "Livret" ? (
                <FaPiggyBank className="text-primary text-xl" />
              ) : (
                <FaUniversity className="text-primary text-xl" />
              )}
            </div>

            <div>
              <h2 className="text-2xl font-bold">{compte.nomCompte}</h2>
              <p className="text-base-content/70 flex items-center gap-2">
                <span className="flex items-center gap-1">
                  <FaTag className="text-xs" /> {compte.typeCompte}
                </span>
                <span className="text-base-content/50">|</span>
                <span className="flex items-center gap-1">
                  <FaBuilding className="text-xs" /> {compte.nomCourtBanque}
                </span>
              </p>
            </div>
          </div>

          {/* Actions pour la table */}
          <div className="flex flex-wrap items-center gap-2">
            <button
              className={`btn btn-sm ${
                isPrevisionPaneVisible ? "btn-primary" : "btn-outline"
              } flex items-center gap-1 transition-all`}
              onClick={togglePrevisionPaneVisibility}
              title={
                isPrevisionPaneVisible
                  ? "Masquer les prévisions"
                  : "Afficher les prévisions"
              }
            >
              {isPrevisionPaneVisible ? (
                <>
                  <FaEyeSlash className="text-xs" /> Prévisions
                </>
              ) : (
                <>
                  <FaEye className="text-xs" /> Prévisions
                </>
              )}
            </button>

            <button
              className={`btn btn-sm ${
                isValueHidden ? "btn-primary" : "btn-outline"
              } flex items-center gap-1 transition-all`}
              onClick={toggleValueVisibility}
              title={
                isValueHidden ? "Afficher les montants" : "Masquer les montants"
              }
            >
              {isValueHidden ? (
                <>
                  <FaLock className="text-xs" /> Montants
                </>
              ) : (
                <>
                  <FaLockOpen className="text-xs" /> Montants
                </>
              )}
            </button>

            <button
              className={`btn btn-sm ${
                isCompactMode ? "btn-primary" : "btn-outline"
              } flex items-center gap-1 transition-all`}
              onClick={toggleCompactMode}
              title={isCompactMode ? "Affichage standard" : "Affichage compact"}
            >
              {isCompactMode ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 10h16M4 14h16M4 18h16"
                    />
                  </svg>
                  Compact
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 8h16M4 16h16"
                    />
                  </svg>
                  Standard
                </>
              )}
            </button>

            <button
              className="btn btn-sm btn-outline flex items-center gap-1 transition-all hover:bg-primary/10"
              onClick={handlePrint}
              title="Imprimer la table"
            >
              <FaPrint className="text-xs" /> Imprimer
            </button>

            {/* Bouton de filtrage */}
            <FilterPanel
              filters={filters}
              onAddFilter={handleAddFilter}
              onUpdateFilter={handleUpdateFilter}
              onRemoveFilter={handleRemoveFilter}
              onClearFilters={handleClearFilters}
              visibleColumns={visibleColumns}
              columnLabels={COLUMN_LABELS}
            />

            <div className="relative">
              <ColumnsToggler
                visibleColumns={visibleColumns}
                toggleColumnVisibility={handleToggleColumnVisibility}
                buttonClassName={`btn btn-sm btn-outline flex items-center gap-1 transition-all`}
                buttonContent={
                  <>
                    <FaColumns className="text-xs" /> Colonnes
                  </>
                }
              />
            </div>

            <ExportButton
              data={lignesCompte}
              selectedCell={selectedCell}
              exportMode={exportMode}
              toggleExportMode={toggleExportMode}
              buttonClassName={`btn btn-sm ${
                exportMode ? "btn-primary" : "btn-outline"
              } flex items-center gap-1 transition-all`}
              buttonContent={
                <>
                  <FaFileExport className="text-xs" /> Coches
                </>
              }
            />
          </div>
        </div>

        <CompteInfoBar
          compte={compte}
          lignesCompte={lignesCompte}
          lignesPrevision={lignesPrevision}
          isValueHidden={isValueHidden}
        />

        {/* Indicateur de filtrage */}
        {filters.length > 0 && (
          <div className="bg-blue-100 text-blue-800 p-2 text-sm flex justify-between items-center">
            <span className="font-medium">
              {totalRowCount} résultat{totalRowCount > 1 ? "s" : ""} trouvé
              {totalRowCount > 1 ? "s" : ""} avec {filters.length} filtre
              {filters.length > 1 ? "s" : ""}
            </span>
            <button
              className="btn btn-xs btn-ghost"
              onClick={handleClearFilters}
              title="Effacer tous les filtres"
            >
              Effacer les filtres
            </button>
          </div>
        )}

        {/* Tableau de données avec indicateur de chargement */}
        {isDataLoading ? (
          <div className="flex justify-center items-center p-8">
            <span className="loading loading-spinner loading-lg"></span>
          </div>
        ) : (
          <DataTable
            data={currentPageData}
            visibleColumns={visibleColumns}
            selectedCell={selectedCell}
            handleSelectRow={handleSelectRow}
            areAllCurrentPageRowsSelected={areAllCurrentPageRowsSelected}
            handleSelectAllOnPage={handleSelectAllOnPage}
            isValueHidden={isValueHidden}
            exportMode={exportMode}
            formatDateValue={formatDateValue}
            isCompactMode={isCompactMode}
            onToggleCompactMode={toggleCompactMode}
            // Nouvelles props pour le tri
            sortConfig={sortConfig}
            handleSort={handleSort}
          />
        )}

        {/* Pagination et sélection de taille de page */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-4 mt-4">
          <PageSizeSelector
            pageSize={pageSize}
            setPageSize={setPageSize}
            options={[10, 25, 50, 100, 200]}
          />

          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-500">
              {totalRowCount > 0 ? (
                <>
                  Affichage de {startIndex + 1} à{" "}
                  {Math.min(endIndex, totalRowCount)} sur {totalRowCount}{" "}
                  entrées
                </>
              ) : (
                "Aucune donnée"
              )}
            </span>

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              generatePagination={generatePagination}
            />
          </div>
        </div>
      </div>
      <PrevisionPane isVisible={isPrevisionPaneVisible} compte={compte} />
    </>
  );
};
