import React, { useState } from "react";
import { Player } from "../types";

interface PlayerFormProps {
  onAddPlayer: (name: string) => void;
  onStartGame: () => void;
  players: Player[];
}

export function PlayerForm({
  onAddPlayer,
  onStartGame,
  players,
}: PlayerFormProps) {
  const [playerName, setPlayerName] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (playerName.trim()) {
      onAddPlayer(playerName);
      setPlayerName("");
    }
  };

  return (
    <div className="card bg-base-100 shadow-xl mb-6">
      <div className="card-body">
        <h2 className="card-title">Ajouter des joueurs</h2>

        <form onSubmit={handleSubmit} className="flex gap-2 mb-4">
          <input
            type="text"
            placeholder="Nom du joueur"
            className="input input-bordered w-full"
            value={playerName}
            onChange={(e) => setPlayerName(e.target.value)}
          />
          <button type="submit" className="btn btn-primary">
            Ajouter
          </button>
        </form>

        {players.length > 0 && (
          <div className="mb-4">
            <h3 className="font-bold mb-2">Joueurs ({players.length})</h3>
            <ul className="list-disc pl-5">
              {players.map((player) => (
                <li key={player.id}>{player.name}</li>
              ))}
            </ul>
          </div>
        )}

        <button
          onClick={onStartGame}
          disabled={players.length < 2}
          className="btn btn-success w-full"
        >
          Commencer la partie
        </button>
      </div>
    </div>
  );
}
