import { createFileRoute } from "@tanstack/react-router";
import { TableMain } from "@/pages/Bank/components/Table/TableMain.tsx";
import { getCompteById } from "@/utils/axios/bankUtils.ts";
import { useQuery } from "@tanstack/react-query";

export const Route = createFileRoute("/bank/landing/table/$compteId")({
  component: RouteComponent,
});

function RouteComponent() {
  const { compteId } = Route.useParams();
  const {
    data: compte,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["compte-info", compteId],
    queryFn: () => getCompteById(compteId),
    staleTime: 1000 * 60 * 5,
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (isError || !compte) {
    return (
      <div className="alert alert-error">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="stroke-current shrink-0 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <span>Erreur de chargement du compte</span>
      </div>
    );
  }

  return <TableMain compte={compte} />;
}
