import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Home as HomeIcon,
} from "@mui/icons-material";
import { Family } from "@/utils/axios/userUtils";
import { useAddHouse, useRemoveHouse } from "@/hooks/familyHooks";

interface HouseManagerProps {
  family: Family;
}

const HouseManager: React.FC<HouseManagerProps> = ({ family }) => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedHouseId, setSelectedHouseId] = useState("");
  const [houseName, setHouseName] = useState("");
  const [houseAddress, setHouseAddress] = useState("");

  const addHouseMutation = useAddHouse();
  const removeHouseMutation = useRemoveHouse();

  const handleAddHouse = () => {
    if (!houseName.trim()) return;

    addHouseMutation.mutate(
      {
        name: houseName,
        address: houseAddress.trim() ? houseAddress : undefined,
      },
      {
        onSuccess: () => {
          setOpenAddDialog(false);
          setHouseName("");
          setHouseAddress("");
        },
      }
    );
  };

  const openDeleteConfirmation = (houseId: string) => {
    setSelectedHouseId(houseId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteHouse = () => {
    if (!selectedHouseId) return;

    removeHouseMutation.mutate(selectedHouseId, {
      onSuccess: () => {
        setOpenDeleteDialog(false);
        setSelectedHouseId("");
      },
    });
  };

  return (
    <>
      <Card elevation={3}>
        <CardHeader
          title="Maisons"
          subheader={`Gérez les maisons de votre famille (${family.houses.length})`}
          action={
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => setOpenAddDialog(true)}
            >
              Ajouter
            </Button>
          }
        />

        <CardContent>
          {addHouseMutation.isSuccess && (
            <Alert severity="success" sx={{ mb: 3 }}>
              Maison ajoutée avec succès !
            </Alert>
          )}

          {removeHouseMutation.isSuccess && (
            <Alert severity="success" sx={{ mb: 3 }}>
              Maison supprimée avec succès !
            </Alert>
          )}

          <Grid container spacing={3}>
            {family.houses.map((house) => (
              <Grid item xs={12} sm={6} md={4} key={house._id}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={1}
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <HomeIcon color="primary" />
                      <Typography variant="h6">{house.name}</Typography>
                    </Box>

                    {family.houses.length > 1 && (
                      <Tooltip title="Supprimer la maison">
                        <IconButton
                          color="error"
                          size="small"
                          onClick={() => openDeleteConfirmation(house._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>

                  {house.address && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: "auto" }}
                    >
                      {house.address}
                    </Typography>
                  )}
                </Paper>
              </Grid>
            ))}

            {family.houses.length === 0 && (
              <Grid item xs={12}>
                <Alert severity="info">
                  Vous n'avez pas encore de maison. Ajoutez-en une pour
                  commencer.
                </Alert>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      {/* Dialog d'ajout de maison */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Ajouter une nouvelle maison</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nom de la maison"
            fullWidth
            variant="outlined"
            value={houseName}
            onChange={(e) => setHouseName(e.target.value)}
            required
            sx={{ mb: 2, mt: 1 }}
          />
          <TextField
            margin="dense"
            label="Adresse (optionnelle)"
            fullWidth
            variant="outlined"
            value={houseAddress}
            onChange={(e) => setHouseAddress(e.target.value)}
            multiline
            rows={2}
          />

          {addHouseMutation.isError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Erreur:{" "}
              {addHouseMutation.error.message ||
                "Impossible d'ajouter la maison"}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>Annuler</Button>
          <Button
            onClick={handleAddHouse}
            variant="contained"
            disabled={!houseName.trim() || addHouseMutation.isPending}
            startIcon={
              addHouseMutation.isPending ? <CircularProgress size={20} /> : null
            }
          >
            {addHouseMutation.isPending ? "Ajout..." : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog de confirmation de suppression */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Supprimer cette maison ?</DialogTitle>
        <DialogContent>
          <Typography>
            Êtes-vous sûr de vouloir supprimer cette maison ? Cette action est
            irréversible.
          </Typography>

          {removeHouseMutation.isError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Erreur:{" "}
              {removeHouseMutation.error.message ||
                "Impossible de supprimer la maison"}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Annuler</Button>
          <Button
            onClick={handleDeleteHouse}
            color="error"
            disabled={removeHouseMutation.isPending}
            startIcon={
              removeHouseMutation.isPending ? (
                <CircularProgress size={20} />
              ) : null
            }
          >
            {removeHouseMutation.isPending ? "Suppression..." : "Supprimer"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HouseManager;
