import { createFileRoute } from "@tanstack/react-router";
import { Feed } from "@mui/icons-material";

export const Route = createFileRoute("/feed")({
  component: RouteComponent,
});

function RouteComponent() {
  return <Feed />;
}
