import { createRootRoute, Outlet } from "@tanstack/react-router";
import NotFoundPage from "../pages/NotFound/NotFoundPage.tsx";
import { DaisyNavbar } from "@/components/layout/DaisyNavbar.tsx";
import { menuItems } from "@/components/layout/menuItems.tsx";
import { AuthProvider } from "@/providers/AuthProvider.tsx";

export const Route = createRootRoute({
  component: () => (
    <AuthProvider>
      <div className="min-h-screen flex flex-col">
        <DaisyNavbar menuItems={menuItems} />
        <main className="flex-1 container mx-auto p-4">
          <Outlet />
        </main>
      </div>
    </AuthProvider>
  ),
  notFoundComponent: () => <NotFoundPage />,
});
