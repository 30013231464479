import axios from "axios";
import { decodeToken } from "react-jwt";
import { Jwt, UserType } from "../userTypes.ts";
import { getBackendMainUrl } from "./global";

const BASE_URL_API = getBackendMainUrl + "/users";
export let userLogged: UserType | undefined;

// Types pour la gestion des familles
export interface FamilyMember {
  _id: string;
  username: string;
  email: string;
  avatar?: string;
}

export interface House {
  _id: string;
  name: string;
  address: string;
  family: string;
}

export interface Family {
  _id: string;
  name: string;
  owner: FamilyMember;
  members: FamilyMember[];
  houses: House[];
  inviteCode: string;
  createdAt?: string;
  updatedAt?: string;
}

// Utilitaires de base
export const getUserFromId = (userId: string, userList: UserType[]) =>
  userList.find((u) => u._id === userId);

export const getUserById = async (userId: string): Promise<UserType | null> => {
  if (typeof userId !== "string" || !userId.trim()) {
    console.error("getUserById a reçu un ID invalide:", userId);
    return null;
  }

  try {
    const response = await axios.get(BASE_URL_API + "/" + userId, {
      headers: { authorization: localStorage.getItem("authorization") },
    });
    return response.data;
  } catch (error) {
    console.error(
      `Erreur lors de la récupération de l'utilisateur ${userId}:`,
      error
    );
    return null;
  }
};

export const getUserLogged = () => {
  const authItem = localStorage.getItem("authorization");
  if (!authItem) return null;
  const decodedToken: Jwt | undefined =
    decodeToken(authItem.split(" ")[1]) || undefined;
  userLogged = decodedToken?.user;
  return userLogged;
};

export const clearUserLogged = () => {
  localStorage.removeItem("authorization");
  userLogged = undefined;
};

// Auth
export const submitLogin = async (userForm: {
  login: string;
  password: string;
}) => {
  localStorage.clear();
  try {
    const result = await axios.post(BASE_URL_API + "/login", userForm);
    if (result.data.message) {
      console.error(result.data);
      return;
    }
    localStorage.setItem("authorization", "Bearer " + result.data);
    getUserLogged();
    return result;
  } catch (err: Error | unknown) {
    console.log("err: ", (err as Error).message);
    return (err as Error).message;
  }
};

export const sendRegisterForm = async (registerForm: FormData) => {
  try {
    const response = await axios.post(
      BASE_URL_API + "/register",
      registerForm,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Correction ici
        },
      }
    );
    console.log("response: ", response);
    console.log("response.data: ", response.data);
    return response.data;
  } catch (err: Error | unknown) {
    console.log("err: ", (err as Error).message);
    throw err; // Propager l'erreur au lieu de retourner une chaîne vide
  }
};

// Gestion des utilisateurs
export const getAvatarUrl = (avatarUri: string | undefined) => {
  return BASE_URL_API.replace("/users", "/images/avatar/") + avatarUri;
};

export const getUserList = async () => {
  try {
    const response = await axios.get(BASE_URL_API + "/userList", {
      headers: { authorization: localStorage.getItem("authorization") },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération de la liste d'utilisateurs:",
      error
    );
    throw error;
  }
};

export const getSpecifiedUserList = async (userList: string[]) => {
  try {
    const response = await axios.post(
      BASE_URL_API + "/getUsersList",
      userList,
      {
        headers: { authorization: localStorage.getItem("authorization") },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des utilisateurs spécifiques:",
      error
    );
    throw error;
  }
};

export const getUserListExceptUserLogged = async () => {
  const userList = await getUserList();
  return userList.filter((user: UserType) => user._id !== getUserLogged()?._id);
};

// Nouvelles fonctions pour la gestion des familles
export const createFamily = async (familyData: {
  name: string;
  owner: string;
}) => {
  try {
    const response = await axios.post(
      BASE_URL_API + "/createFamily",
      familyData,
      {
        headers: { authorization: localStorage.getItem("authorization") },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de la famille:", error);
    throw error;
  }
};

export const joinFamily = async (inviteCode: string) => {
  try {
    const response = await axios.post(
      BASE_URL_API + "/joinFamily",
      { inviteCode },
      {
        headers: { authorization: localStorage.getItem("authorization") },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la rejointe d'une famille:", error);
    throw error;
  }
};

export const leaveFamily = async () => {
  try {
    const response = await axios.post(
      BASE_URL_API + "/leaveFamily",
      {},
      {
        headers: { authorization: localStorage.getItem("authorization") },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors du départ de la famille:", error);
    throw error;
  }
};

export const getFamilyDetails = async () => {
  try {
    const response = await axios.get(BASE_URL_API + "/family/details", {
      headers: { authorization: localStorage.getItem("authorization") },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des détails de la famille:",
      error
    );
    throw error;
  }
};

export const regenerateInviteCode = async () => {
  try {
    const response = await axios.post(
      BASE_URL_API + "/family/regenerateInviteCode",
      {},
      {
        headers: { authorization: localStorage.getItem("authorization") },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la régénération du code d'invitation:",
      error
    );
    throw error;
  }
};

export const addHouse = async (houseData: {
  name: string;
  address?: string;
}) => {
  try {
    const response = await axios.post(BASE_URL_API + "/house", houseData, {
      headers: { authorization: localStorage.getItem("authorization") },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout d'une maison:", error);
    throw error;
  }
};

export const removeHouse = async (houseId: string) => {
  try {
    const response = await axios.delete(BASE_URL_API + "/house/" + houseId, {
      headers: { authorization: localStorage.getItem("authorization") },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression d'une maison:", error);
    throw error;
  }
};

/**
 * Met à jour le profil d'un utilisateur
 * @param userId ID de l'utilisateur à mettre à jour
 * @param data FormData contenant les données à mettre à jour (username, email, avatar, etc.)
 * @returns Les données de l'utilisateur mis à jour
 */
export const updateUserProfile = async (
  userId: string,
  data: FormData
): Promise<UserType> => {
  try {
    const response = await axios.patch(`${BASE_URL_API}/${userId}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: localStorage.getItem("authorization"),
      },
    });

    // Si un nouveau token est renvoyé par le serveur, mettez-le à jour
    if (response.data && response.data.token) {
      localStorage.setItem("authorization", "Bearer " + response.data.token);
      // Mettre à jour l'utilisateur connecté avec les nouvelles informations
      getUserLogged();
    }

    return response.data.user || response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour du profil:", error);
    throw error;
  }
};
