import { Edit, X } from "lucide-react";
import { deleteBanqueForUser } from "@/utils/axios/bankUtils.ts";
import { useQueryClient } from "@tanstack/react-query";
import { showToast } from "@/components/tools/toast.ts";
import { Banque } from "@/pages/Bank/types.ts";

export const BanqueList = ({
  banqueList,
  formValueChange,
}: {
  banqueList: Banque[];
  formValueChange: (banque: Banque) => void;
}) => {
  const queryClient = useQueryClient();

  const handleEdit = (e: React.MouseEvent, banque: Banque) => {
    e.stopPropagation();
    formValueChange(banque);
  };

  const handleDelete = async (e: React.MouseEvent, banque: Banque) => {
    e.stopPropagation();
    try {
      await deleteBanqueForUser(banque._id);
      queryClient.invalidateQueries({ queryKey: ["banque"] });
      showToast(
        "info",
        `Banque ${banque.etablissement} supprimée avec succès!`
      );
    } catch (error) {
      showToast(
        "error",
        `Erreur lors de la suppression de la banque: ${error}`
      );
    }
  };

  const cardRender = (banque: Banque) => {
    return (
      <div className="card w-full bg-base-200 shadow-sm hover:shadow-md transition-shadow duration-200">
        <div className="card-body p-4">
          <div className="flex justify-between items-start">
            <div className="flex-grow">
              <h2 className="card-title text-lg font-semibold mb-2">
                {banque.etablissement}
              </h2>
              <p className="text-sm text-gray-600">{banque.nomCourt}</p>
              {banque._id && (
                <p className="text-xs text-gray-500 mt-2">ID: {banque._id}</p>
              )}
            </div>
            <div className="flex gap-2 ml-2">
              <button
                className="btn btn-sm btn-circle btn-ghost hover:bg-base-300"
                onClick={(e) => handleEdit(e, banque)}
                aria-label="Éditer"
              >
                <Edit size={16} className="text-gray-600" />
              </button>
              <button
                className="btn btn-sm btn-circle btn-ghost hover:bg-red-100"
                onClick={(e) => handleDelete(e, banque)}
                aria-label="Supprimer"
              >
                <X size={16} className="text-red-500" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full gap-4">
      {banqueList && banqueList.length > 0 ? (
        banqueList.map((banque: Banque) => (
          <div key={banque._id}>{cardRender(banque)}</div>
        ))
      ) : (
        <div className="col-span-full text-center py-8 text-gray-500">
          Aucune banque trouvée
        </div>
      )}
    </div>
  );
};
