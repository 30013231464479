import { z } from "zod"; // Schéma pour la validation du formulaire de connexion

// Schéma pour la validation du formulaire de connexion
export const loginSchema = z.object({
  login: z.string().min(1, "L'identifiant est requis"),
  password: z.string().min(1, "Le mot de passe est requis"),
});

// Type inféré du schéma de connexion
export type LoginFormType = z.infer<typeof loginSchema>;

// Schéma pour la validation du formulaire d'inscription
export const registerSchema = z
  .object({
    username: z
      .string()
      .min(3, "Le nom d'utilisateur doit contenir au moins 3 caractères")
      .max(30, "Le nom d'utilisateur ne doit pas dépasser 30 caractères")
      .regex(
        /^[a-zA-Z0-9_-]+$/,
        "Le nom d'utilisateur ne peut contenir que des lettres, chiffres, tirets et underscores"
      ),

    email: z
      .string()
      .email("Adresse email invalide")
      .min(1, "L'email est requis"),

    firstname: z
      .string()
      .max(50, "Le prénom ne doit pas dépasser 50 caractères")
      .optional(),

    lastname: z
      .string()
      .max(50, "Le nom ne doit pas dépasser 50 caractères")
      .optional(),

    password: z
      .string()
      .min(3, "Le mot de passe doit contenir au moins 3 caractères")
      .regex(/[0-9]/, "Le mot de passe doit contenir au moins un chiffre"),

    confirmPassword: z
      .string()
      .min(1, "La confirmation du mot de passe est requise"),

    familyCode: z.string().optional(),

    avatar: z
      .instanceof(File)
      .optional()
      .nullable()
      .refine(
        (file) =>
          !file ||
          (file instanceof File &&
            ["image/jpeg", "image/png", "image/gif", "image/webp"].includes(
              file.type
            )),
        {
          message: "L'avatar doit être une image (JPEG, PNG, GIF, WebP)",
        }
      )
      .refine((file) => !file || file.size <= 5 * 1024 * 1024, {
        message: "L'avatar ne doit pas dépasser 5 Mo",
      }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Les mots de passe ne correspondent pas",
    path: ["confirmPassword"],
  });
