import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createBanqueForUser } from "@/utils/axios/bankUtils.ts";
import { Banque } from "@/pages/Bank/types.ts";
import { showToast } from "@/components/tools/toast.ts"; // Service pour l'API

// Service pour l'API
const createBanque = async (data: Partial<Banque>) => {
  const response = await createBanqueForUser(data as Banque);
  return response.data;
};

const BanqueCreateForm = ({
  formData,
  setFormData,
}: {
  formData: Partial<Banque>;
  setFormData: Dispatch<SetStateAction<Partial<Banque>>>;
}) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!document.getElementById("toast-container")) {
      const container = document.createElement("div");
      container.id = "toast-container";
      container.className = "toast toast-top toast-end z-50";
      document.body.appendChild(container);
    }

    // Nettoyage au démontage
    return () => {
      const container = document.getElementById("toast-container");
      if (container && container.childNodes.length === 0) {
        document.body.removeChild(container);
      }
    };
  }, []);

  // Mutation pour créer une banque
  const createBanqueMutation = useMutation({
    mutationFn: createBanque,
    onSuccess: () => {
      // Réinitialiser le formulaire
      setFormData({
        etablissement: "",
        nomCourt: "",
      });

      // Invalider et rafraîchir les queries
      queryClient.invalidateQueries({ queryKey: ["banque"] });

      // Afficher un message de succès
      showToast("success", "Banque créée avec succès!");
    },
    onError: (error: Error) => {
      showToast("error", error.message);
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value }: { name: string; value: string } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    createBanqueMutation.mutate(formData);
  };

  const handleReset = (e: React.FormEvent) => {
    e.preventDefault();
    setFormData({ etablissement: "", nomCourt: "" });
  };

  return (
    // <div className="p-6 max-w-2xl mx-auto bg-base-100 rounded-box shadow-lg collapse-content">
    //   <h2 className="text-2xl font-bold mb-6">Créer une nouvelle banque</h2>
    <div className="p-1">
      <form onSubmit={handleSubmit} onReset={handleReset}>
        {/* Champs principaux */}
        <div className="form-control mb-4">
          <label className="label">
            <span className="label-text">Nom de l'établissement *</span>
          </label>
          <input
            type="text"
            name="etablissement"
            value={formData.etablissement}
            onChange={handleInputChange}
            className="input input-bordered"
            required
          />
        </div>

        <div className="form-control mb-4">
          <label className="label">
            <span className="label-text">Nom court *</span>
          </label>
          <input
            type="text"
            name="nomCourt"
            value={formData.nomCourt}
            onChange={handleInputChange}
            className="input input-bordered"
            required
          />
        </div>

        <div className="flex justify-end gap-2">
          <button
            type="reset"
            className="btn btn-error"
            disabled={createBanqueMutation.isPending}
          >
            {createBanqueMutation.isPending
              ? "Création en cours..."
              : "Annulation"}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={createBanqueMutation.isPending}
          >
            {createBanqueMutation.isPending
              ? "Création en cours..."
              : "Créer la banque"}
          </button>
        </div>
      </form>
    </div>
    // </div>
  );
};

export default BanqueCreateForm;
