import {
  addTodo,
  getLoggedUserTodos,
  removeTodo,
  updateTodo,
} from "@/utils/axios/todosUtils.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  ArrowUpDown,
  Calendar,
  CheckCircle,
  Circle,
  Edit,
  PlusCircle,
  Share2,
  Tag,
  Trash2,
  Users,
} from "lucide-react";
import { Todo } from "@/features/Todo/types.ts";

async function getTodos() {
  console.log("getTodos asked");
  const resultRequest = await getLoggedUserTodos();
  return resultRequest!.data;
}

export function TodosPage() {
  const queryClient = useQueryClient();
  const [activeFilter, setActiveFilter] = useState("all");
  const [sortBy, setSortBy] = useState("updatedAt");
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [newTodo, setNewTodo] = useState<Partial<Todo>>({
    title: "",
    category: "personal",
    finished: false,
  });

  // Récupération des todos
  const { data, isLoading, isError } = useQuery({
    queryKey: ["myTodos"],
    queryFn: getTodos,
  });

  // Mutation pour ajouter un todo
  const addMutation = useMutation({
    mutationFn: addTodo,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["myTodos"] });
      setNewTodo({
        title: "",
        category: "personal",
        finished: false,
      });
    },
  });

  // Mutation pour mettre à jour un todo
  const updateMutation = useMutation({
    mutationFn: ({ todo, _id }: { todo: Partial<Todo>; _id: string }) =>
      updateTodo(todo, _id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["myTodos"] });
    },
  });

  // Mutation pour supprimer un todo
  const deleteMutation = useMutation({
    mutationFn: (_id: string) => removeTodo(_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["myTodos"] });
    },
  });

  // Fonction pour gérer l'ajout d'un todo
  const handleAddTodo = () => {
    if (!newTodo.title?.trim()) return;

    // Création d'un nouveau todo avec contenu minimal
    const todoToAdd: Todo = {
      title: newTodo.title,
      category: newTodo.category || "personal",
      finished: false,
      contentHtml: "",
      content: [
        {
          type: "paragraph",
          children: [
            {
              text: "",
            },
          ],
        },
      ],
    };

    addMutation.mutate(todoToAdd);
  };

  // Fonction pour basculer l'état "finished" d'un todo
  const handleToggleComplete = (todo: Todo) => {
    if (!todo._id) return;

    updateMutation.mutate({
      todo: { finished: !todo.finished },
      _id: todo._id,
    });
  };

  // Fonction pour supprimer un todo
  const handleDeleteTodo = (_id: string) => {
    deleteMutation.mutate(_id);
  };

  // Obtenir toutes les catégories uniques pour le filtre
  const allCategories = data
    ? [
        ...new Set([
          ...data.userList.map((todo: Todo) => todo.category),
          ...data.sharedList.map((todo: Todo) => todo.category),
        ]),
      ]
    : [];

  // Filtrer et trier les todos
  const filterAndSortTodos = () => {
    if (!data) return [];

    // Combiner les listes
    let todos = [...data.userList, ...data.sharedList];

    // Filtrer par état
    if (activeFilter === "active") {
      todos = todos.filter((todo) => !todo.finished);
    } else if (activeFilter === "completed") {
      todos = todos.filter((todo) => todo.finished);
    }

    // Filtrer par catégorie
    if (categoryFilter !== "all") {
      todos = todos.filter((todo) => todo.category === categoryFilter);
    }

    // Trier les todos
    todos.sort((a, b) => {
      if (sortBy === "updatedAt") {
        return new Date(b.updatedAt || "") > new Date(a.updatedAt || "")
          ? 1
          : -1;
      } else if (sortBy === "title") {
        return a.title.localeCompare(b.title);
      } else if (sortBy === "category") {
        return a.category.localeCompare(b.category);
      }
      return 0;
    });

    return todos;
  };

  // Générer couleur pour une catégorie
  const getCategoryColor = (category: string) => {
    // Une fonction simple pour générer une couleur cohérente basée sur le texte
    let hash = 0;
    for (let i = 0; i < category.length; i++) {
      hash = category.charCodeAt(i) + ((hash << 5) - hash);
    }

    const colors = [
      "bg-blue-100 text-blue-800",
      "bg-green-100 text-green-800",
      "bg-purple-100 text-purple-800",
      "bg-yellow-100 text-yellow-800",
      "bg-red-100 text-red-800",
      "bg-pink-100 text-pink-800",
      "bg-indigo-100 text-indigo-800",
    ];

    return colors[Math.abs(hash) % colors.length];
  };

  // Filtre et tri des todos
  const filteredTodos = filterAndSortTodos();

  if (isLoading) {
    return (
      <div className="flex justify-center p-12">Chargement des todos...</div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center p-12 text-red-500">
        Erreur lors du chargement des todos
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-6 text-gray-800">
        Ma Liste de Tâches
      </h1>

      {/* Formulaire d'ajout */}
      <div className="mb-6 p-4 bg-gray-50 rounded-lg">
        <div className="flex flex-col space-y-3">
          <input
            type="text"
            value={newTodo.title}
            onChange={(e) => setNewTodo({ ...newTodo, title: e.target.value })}
            placeholder="Ajouter une nouvelle tâche..."
            onKeyPress={(key) => key.code === "Enter" && handleAddTodo()}
            className="p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="flex flex-wrap gap-3">
            <select
              value={newTodo.category}
              onChange={(e) =>
                setNewTodo({ ...newTodo, category: e.target.value })
              }
              className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="personal">Personnel</option>
              <option value="work">Travail</option>
              <option value="shopping">Courses</option>
              <option value="health">Santé</option>
              <option value="other">Autre</option>
            </select>
            <button
              onClick={handleAddTodo}
              disabled={addMutation.isPending}
              className="ml-auto flex items-center space-x-1 bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors disabled:bg-blue-300"
            >
              <PlusCircle className="h-5 w-5" />
              <span>{addMutation.isPending ? "Ajout..." : "Ajouter"}</span>
            </button>
          </div>
        </div>
      </div>

      {/* Filtres et tri */}
      <div className="flex flex-wrap justify-between items-center mb-4">
        <div className="flex space-x-2 mb-2 sm:mb-0">
          <button
            onClick={() => setActiveFilter("all")}
            className={`px-3 py-1 rounded-md ${
              activeFilter === "all"
                ? "bg-blue-100 text-blue-800"
                : "bg-gray-100 text-gray-800"
            }`}
          >
            Toutes
          </button>
          <button
            onClick={() => setActiveFilter("active")}
            className={`px-3 py-1 rounded-md ${
              activeFilter === "active"
                ? "bg-blue-100 text-blue-800"
                : "bg-gray-100 text-gray-800"
            }`}
          >
            Actives
          </button>
          <button
            onClick={() => setActiveFilter("completed")}
            className={`px-3 py-1 rounded-md ${
              activeFilter === "completed"
                ? "bg-blue-100 text-blue-800"
                : "bg-gray-100 text-gray-800"
            }`}
          >
            Terminées
          </button>
        </div>

        <div className="flex space-x-2">
          {allCategories.length > 1 && (
            <select
              value={categoryFilter}
              onChange={(e) => setCategoryFilter(e.target.value)}
              className="px-3 py-1 bg-gray-100 rounded-md text-gray-800"
            >
              <option value="all">Toutes catégories</option>
              {allCategories.map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </select>
          )}

          <button
            onClick={() =>
              setSortBy(
                sortBy === "updatedAt"
                  ? "title"
                  : sortBy === "title"
                  ? "category"
                  : "updatedAt"
              )
            }
            className="flex items-center space-x-1 px-3 py-1 bg-gray-100 rounded-md text-gray-800"
          >
            <ArrowUpDown className="h-4 w-4" />
            <span>
              Trier:{" "}
              {sortBy === "updatedAt"
                ? "Date"
                : sortBy === "title"
                ? "Titre"
                : "Catégorie"}
            </span>
          </button>
        </div>
      </div>

      {/* Liste des tâches */}
      <div className="space-y-2">
        {filteredTodos.length === 0 ? (
          <p className="text-center py-6 text-gray-500">
            Aucune tâche à afficher
          </p>
        ) : (
          filteredTodos.map((todo: Todo) => (
            <div
              key={todo._id}
              className={`p-4 border rounded-lg flex items-start transition-all ${
                todo.finished
                  ? "bg-gray-50 border-gray-200"
                  : "bg-white border-gray-300"
              }`}
            >
              <button
                onClick={() => handleToggleComplete(todo)}
                className="mt-1 mr-3 flex-shrink-0"
              >
                {todo.finished ? (
                  <CheckCircle className="h-5 w-5 text-green-500" />
                ) : (
                  <Circle className="h-5 w-5 text-gray-400" />
                )}
              </button>

              <div className="flex-grow">
                <div className="flex flex-wrap items-center gap-2 mb-1">
                  <span
                    className={`text-md ${
                      todo.finished
                        ? "line-through text-gray-500"
                        : "text-gray-800"
                    }`}
                  >
                    {todo.title}
                  </span>

                  <span
                    className={`text-xs px-2 py-1 rounded-full ${getCategoryColor(
                      todo.category
                    )}`}
                  >
                    {todo.category}
                  </span>

                  {todo.tag?.tagName && (
                    <span
                      className="text-xs px-2 py-1 rounded-full flex items-center"
                      style={{
                        backgroundColor: `${todo.tag.tagColor}20`,
                        color: todo.tag.tagColor,
                      }}
                    >
                      <Tag className="h-3 w-3 mr-1" />
                      {todo.tag.tagName}
                    </span>
                  )}

                  {todo.sharedUsers && todo.sharedUsers.length > 0 && (
                    <span className="text-xs px-2 py-1 rounded-full bg-indigo-100 text-indigo-800 flex items-center">
                      <Share2 className="h-3 w-3 mr-1" />
                      Partagé ({todo.sharedUsers.length})
                    </span>
                  )}
                </div>

                <div className="text-sm text-gray-500 flex items-center">
                  <Calendar className="h-4 w-4 mr-1" />
                  {new Date(todo.updatedAt || "").toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </div>
              </div>

              <div className="flex items-center">
                <div className="tooltip" data-tip={"Bah faut rendre dynamique"}>
                  <button
                    onClick={() =>
                      console.log("Ajouter la fonction pour gérer le partage")
                    }
                    className="p-1 text-gray-400 hover:text-blue-500 transition-colors"
                  >
                    <Users className="h-5 w-5" />
                  </button>
                </div>
                <button
                  onClick={() => console.log("Éditer", todo._id)}
                  className="p-1 text-gray-400 hover:text-blue-500 transition-colors"
                >
                  <Edit className="h-5 w-5" />
                </button>

                <button
                  onClick={() => todo._id && handleDeleteTodo(todo._id)}
                  disabled={deleteMutation.isPending}
                  className="p-1 text-gray-400 hover:text-red-500 transition-colors"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Résumé des tâches */}
      <div className="mt-6 text-sm text-gray-500 flex justify-between">
        <span>
          {data
            ? `${
                data.userList.filter((t: Todo) => !t.finished).length
              } tâches personnelles et 
             ${
               data.sharedList.filter((t: Todo) => !t.finished).length
             } tâches partagées restantes`
            : "Chargement..."}
        </span>

        {data &&
          [...data.userList, ...data.sharedList].some(
            (t: Todo) => t.finished
          ) && (
            <button
              onClick={() => {
                const completedTodos = [...data.userList, ...data.sharedList]
                  .filter((t: Todo) => t.finished && t._id)
                  .map((t: Todo) => t._id as string);

                // On ne peut pas facilement supprimer plusieurs todos à la fois
                // alors on les traite un par un
                Promise.all(completedTodos.map((id) => removeTodo(id))).then(
                  () => queryClient.invalidateQueries({ queryKey: ["myTodos"] })
                );
              }}
              className="text-blue-600 hover:underline"
            >
              Supprimer les tâches terminées
            </button>
          )}
      </div>
    </div>
  );
}
