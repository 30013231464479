import { Banque, Compte } from "@/pages/Bank/types.ts";
import { Loader } from "lucide-react";
import { CompteForm } from "@/pages/Bank/components/Compte/CompteForm.tsx";
import { CompteList } from "@/pages/Bank/components/Compte/CompteList.tsx";
import { useState } from "react";
import { getUserLogged } from "@/utils/axios/userUtils.ts";

export const CompteMain = ({
  banques,
  comptes,
  isComptesLoading,
  isComptesError,
}: {
  banques: Banque[];
  comptes: Compte[];
  isComptesLoading: boolean;
  isComptesError: boolean;
}) => {
  const [compteFormValue, setCompteFormValue] = useState<Partial<Compte>>({
    nomCompte: "",
    typeCompte: "Compte personnel",
    correctionSoldeDepart: 0,
    etablissement: "",
    owners: (getUserLogged() && [getUserLogged()!._id]) || [],
  });

  // État pour contrôler si le formulaire est ouvert ou fermé
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleFormValueChange = (newValue: Partial<Compte>) => {
    setCompteFormValue((actualValue) => ({ ...actualValue, ...newValue }));
    setIsFormOpen(true);
  };

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  const getBanqueById = (id: string) => {
    if (!banques) return;
    return banques.find((banque) => banque._id === id);
  };

  if (isComptesLoading) return <Loader />;
  if (isComptesError) return <p>Something went wrong...</p>;

  return (
    <>
      <div className="collapse collapse-arrow bg-base-100 border-base-300 border">
        <input
          type="checkbox"
          checked={isFormOpen}
          onChange={toggleForm}
          className="min-h-[3rem]"
        />
        <div className="collapse-title font-semibold text-2xl">
          Creation de compte
        </div>
        {isFormOpen && (
          <CompteForm
            banques={banques}
            formData={compteFormValue}
            setFormData={setCompteFormValue}
            onCancel={() => setIsFormOpen(false)}
          />
        )}
      </div>

      <CompteList
        comptes={comptes}
        formValueChange={handleFormValueChange}
        getBanqueById={getBanqueById}
      />
    </>
  );
};
