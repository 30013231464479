import { useEffect, useState } from "react";

type Theme = "light" | "dark";

export function useTheme() {
  // Récupère le thème depuis localStorage ou utilise la valeur par défaut (light)
  const [theme, setTheme] = useState<Theme>(() => {
    const savedTheme =
      typeof window !== "undefined"
        ? (localStorage.getItem("theme") as Theme)
        : null;

    // Vérifier si le système préfère le thème sombre par défaut
    const prefersDark =
      typeof window !== "undefined"
        ? window.matchMedia("(prefers-color-scheme: dark)").matches
        : false;

    return savedTheme || (prefersDark ? "dark" : "light");
  });

  // Mettre à jour la classe du document quand le thème change
  useEffect(() => {
    const root = window.document.documentElement;

    // Enlever l'ancienne classe de thème
    root.classList.remove("light", "dark");

    // Ajouter la nouvelle classe de thème
    root.classList.add(theme);

    // Sauvegarder le thème dans localStorage
    localStorage.setItem("theme", theme);

    // Mettre à jour l'attribut data-theme pour daisyUI
    root.setAttribute("data-theme", theme);
  }, [theme]);

  // Fonction pour changer de thème
  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  return { theme, toggleTheme };
}
