import { Banque, Compte } from "@/pages/Bank/types.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Building, CreditCard, Edit, Users, X } from "lucide-react";
import { showToast } from "@/components/tools/toast.ts";
import { deleteCompteForUser } from "@/utils/axios/bankUtils.ts";
import { useNavigate } from "@tanstack/react-router";
import { getUserById } from "@/utils/axios/userUtils.ts";
import React, { useState } from "react";
import { OwnerManagementDialog } from "@/pages/Bank/components/Compte/parts/OwnerManagementDialog.tsx";
import { UserType } from "@/utils/userTypes.ts";

export const CompteList = ({
  comptes,
  formValueChange,
  getBanqueById,
}: {
  comptes: Compte[];
  formValueChange: (compte: Compte) => void;
  getBanqueById: (banqueId: string) => Banque | undefined;
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // État pour gérer le dialogue de gestion des propriétaires
  const [ownerDialogOpen, setOwnerDialogOpen] = useState(false);
  const [selectedCompte, setSelectedCompte] = useState<Compte | null>(null);

  const handleCardClick = (compte: Compte) => {
    queryClient.setQueryData(["compte-info", compte._id], compte);
    navigate({
      to: "/bank/landing/table/$compteId",
      params: { compteId: compte._id },
      replace: true,
    });
  };

  const handleEdit = (e: React.MouseEvent, compte: Compte) => {
    e.stopPropagation(); // Empêche la propagation vers la carte
    formValueChange(compte);
  };

  const handleDelete = async (e: React.MouseEvent, compte: Compte) => {
    e.stopPropagation(); // Empêche la propagation vers la carte

    // Confirmation avant suppression
    if (
      !window.confirm(
        `Êtes-vous sûr de vouloir supprimer le compte "${compte.nomCompte}" ? Cette action est irréversible.`
      )
    ) {
      return; // Annulation si l'utilisateur refuse
    }

    try {
      await deleteCompteForUser(compte._id);
      await queryClient.invalidateQueries({ queryKey: ["compte"] });
      showToast("info", `Compte ${compte.nomCompte} supprimé avec succès!`);
    } catch (error) {
      showToast("error", `Erreur lors de la suppression du compte: ${error}`);
    }
  };

  const handleManageOwners = (e: React.MouseEvent, compte: Compte) => {
    e.stopPropagation(); // Empêche la propagation vers la carte
    setSelectedCompte(compte);
    setOwnerDialogOpen(true);
  };

  // Fonction utilitaire pour extraire les IDs valides
  const extractValidIds = (ids: any[]): string[] => {
    return ids
      .map((id) => (typeof id === "object" && id?._id ? id._id : id))
      .filter((id) => typeof id === "string" && id.trim() !== "");
  };

  // Composant pour afficher les propriétaires d'un compte
  const AccountOwners = ({ ownerIds }: { ownerIds: string[] | any[] }) => {
    // Vérifier et nettoyer les IDs avant utilisation
    const validOwnerIds = Array.isArray(ownerIds)
      ? extractValidIds(ownerIds)
      : [];

    // Récupérer tous les propriétaires
    const { data: owners = [], isLoading } = useQuery({
      queryKey: ["compte-owners", JSON.stringify(validOwnerIds)],
      queryFn: async () => {
        if (validOwnerIds.length === 0) return [];

        try {
          const ownerPromises = validOwnerIds.map((id) => getUserById(id));
          const ownerDetails = await Promise.all(
            ownerPromises.map((p) => p.catch(() => null))
          );
          return ownerDetails.filter(Boolean) as UserType[];
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des propriétaires:",
            error
          );
          return [];
        }
      },
      enabled: validOwnerIds.length > 0,
      retry: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    });

    if (!validOwnerIds.length) {
      return <span className="text-xs text-gray-500">Aucun propriétaire</span>;
    }

    return (
      <div className="flex flex-col gap-1 mt-1">
        <div className="flex items-center gap-1">
          <Users size={14} className="text-gray-500" />
          <span className="text-xs text-gray-600 font-medium">
            Propriétaires:
          </span>
        </div>
        {isLoading ? (
          <span className="text-xs text-gray-500">Chargement...</span>
        ) : (
          <div className="flex flex-wrap gap-1">
            {owners.map((owner, index) => (
              <span
                key={`owner-${owner._id}-${index}`}
                className="text-xs badge badge-sm badge-outline"
              >
                {owner.username}
              </span>
            ))}
            {owners.length < validOwnerIds.length && (
              <span className="text-xs badge badge-sm badge-outline badge-warning">
                +{validOwnerIds.length - owners.length} inconnu(s)
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  // Type de badge pour les différents types de compte
  const getTypeCompteStyle = (type: string) => {
    switch (type) {
      case "Compte Joint":
        return "badge-info";
      case "Livret":
        return "badge-accent";
      default:
        return "badge-primary";
    }
  };

  const cardRender = (compte: Compte) => {
    return (
      <div
        key={compte._id}
        className="card w-full bg-base-200 hover:bg-base-100 shadow-sm hover:shadow-md transition-all duration-200 cursor-pointer border border-base-300 h-full flex flex-col"
        onClick={() => handleCardClick(compte)}
      >
        <div className="card-body p-4 flex flex-col h-full">
          <div className="flex justify-between items-start flex-grow">
            <div className="flex-grow">
              <div className="flex items-center gap-2 mb-2">
                <CreditCard size={18} className="text-primary" />
                <h2 className="card-title text-lg font-semibold">
                  {compte.nomCompte}
                </h2>
              </div>

              <div className="flex flex-wrap gap-2 mb-2">
                <div
                  className={`badge ${getTypeCompteStyle(compte.typeCompte)}`}
                >
                  {compte.typeCompte}
                </div>

                <div className="flex items-center gap-1 badge badge-outline">
                  <Building size={12} />
                  <span>
                    {getBanqueById(compte.etablissement)?.nomCourt ||
                      "Banque inconnue"}
                  </span>
                </div>
              </div>

              {/* Afficher les propriétaires du compte */}
              <AccountOwners ownerIds={compte.owners || []} />
            </div>
            <div className="flex gap-2 ml-2">
              <button
                className="btn btn-sm btn-circle btn-ghost hover:bg-base-300"
                onClick={(e) => handleEdit(e, compte)}
                aria-label="Éditer"
              >
                <Edit size={16} className="text-gray-600" />
              </button>
              <button
                className="btn btn-sm btn-circle btn-ghost hover:bg-indigo-100"
                onClick={(e) => handleManageOwners(e, compte)}
                aria-label="Gérer les propriétaires"
              >
                <Users size={16} className="text-indigo-500" />
              </button>
              <button
                className="btn btn-sm btn-circle btn-ghost hover:bg-red-100"
                onClick={(e) => handleDelete(e, compte)}
                aria-label="Supprimer"
              >
                <X size={16} className="text-red-500" />
              </button>
            </div>
          </div>

          {compte.correctionSoldeDepart && (
            <div className="mt-2 text-xs text-gray-500">
              Correction initiale: {compte.correctionSoldeDepart}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full gap-4">
        {comptes && comptes.length > 0 ? (
          comptes.map((compte: Compte) => (
            <div
              key={compte._id}
              className="transition-all duration-200 hover:scale-[1.02] h-full"
            >
              {cardRender(compte)}
            </div>
          ))
        ) : (
          <div className="col-span-full text-center py-8 text-gray-500">
            Aucun compte trouvé
          </div>
        )}
      </div>

      {/* Modal de gestion des propriétaires */}
      {selectedCompte && ownerDialogOpen && (
        <OwnerManagementDialog
          key={`dialog-${selectedCompte._id}`}
          compte={selectedCompte}
          isOpen={true}
          onClose={() => {
            setOwnerDialogOpen(false);
            setSelectedCompte(null);
          }}
        />
      )}
    </>
  );
};
