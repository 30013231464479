import { createRouter, RouterProvider } from "@tanstack/react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { routeTree } from "./routeTree.gen.ts";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./store/store.ts";
import "github-markdown-css/github-markdown-light.css";
import "katex/dist/katex.min.css";
import "highlight.js/styles/github.css";
import { lazy, Suspense, useEffect, useState } from "react";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";

const router = createRouter({ routeTree });
const queryClient = new QueryClient();

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export const App = () => {
  const [showDevtools, setShowDevtools] = useState(false);

  // On check si on est en dev seulement quand le composant monte
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      setShowDevtools(true);
    }
  }, []);

  // Lazy load des devtools
  const TanStackRouterDevtoolsLazy = lazy(() =>
    import("@tanstack/router-devtools").then((res) => ({
      default: res.TanStackRouterDevtools,
    }))
  );

  const ReactQueryDevtoolsLazy = lazy(() =>
    import("@tanstack/react-query-devtools").then((res) => ({
      default: res.ReactQueryDevtools,
    }))
  );
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />

        {showDevtools && (
          <Suspense fallback={null}>
            <TanStackRouterDevtoolsLazy router={router} />
          </Suspense>
        )}

        {showDevtools && (
          <Suspense fallback={null}>
            <ReactQueryDevtoolsLazy initialIsOpen={false} />
          </Suspense>
        )}
      </QueryClientProvider>
    </Provider>
  );
};
