import React from "react";
import { PrevisionRowSchema } from "@/pages/Bank/types.ts";
import { CiEdit } from "react-icons/ci";

export const PrevisionTable: React.FC<{
  lignes: PrevisionRowSchema[];
  onToggleDone: (line: PrevisionRowSchema) => void;
  onSupprimer: (id: string) => void;
  handleEdit: (prevision: PrevisionRowSchema) => void;
}> = ({ lignes, onToggleDone, onSupprimer, handleEdit }) => {
  return (
    <div className="overflow-x-auto">
      <table className="table table-zebra table-compact w-full">
        <thead>
          <tr>
            <th className="w-10"></th>
            <th>Intitulé</th>
            <th>Montant</th>
            <th>Date</th>
            <th className="w-20">Actions</th>
          </tr>
        </thead>
        <tbody>
          {lignes.map((ligne) => (
            <tr key={ligne._id} className={ligne.done ? "opacity-60" : ""}>
              <td>
                <input
                  type="checkbox"
                  className="checkbox checkbox-sm"
                  checked={ligne.done}
                  onChange={() => {
                    ligne.done = !ligne.done;
                    onToggleDone(ligne);
                  }}
                />
              </td>
              <td>{ligne.intitule}</td>
              <td>{ligne.montant} €</td>
              <td>{ligne.datePrevue}</td>
              <td>
                <div className="flex gap-2">
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() => {
                      handleEdit(ligne);
                    }}
                  >
                    <CiEdit />
                  </button>
                  <button
                    className="btn btn-ghost btn-xs"
                    onClick={() => onSupprimer(ligne._id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
