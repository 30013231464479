import { useState } from "react";
import { useRouter } from "@tanstack/react-router";
import { Compte } from "@/pages/Bank/types.ts";
import { useQueryClient } from "@tanstack/react-query";
import { Check, Save, Upload } from "lucide-react";
import { sendCsvFile } from "@/utils/axios/bankUtils.ts";
import { showToast } from "@/components/tools/toast.ts";

interface ImportFileBankFormProps {
  listeCompte: Compte[];
}

export const ImportCsvForm = ({ listeCompte }: ImportFileBankFormProps) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [compteSelected, setCompteSelected] = useState<string>(
    listeCompte.length === 1 ? listeCompte[0]._id : ""
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const router = useRouter();

  const handleCompteChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCompteSelected(event.target.value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const checkFormData = (): boolean => {
    if (!file) return false;
    if (file.type !== "text/csv") return false;
    if (!compteSelected) return false;
    return true;
  };

  const sendFile = async () => {
    setIsLoading(true);

    if (!checkFormData() || !file) {
      setIsLoading(false);
      return;
    }

    const formData: FormData = new FormData();
    formData.append("csvFile", file);
    formData.append("compte", compteSelected);

    try {
      const { data } = await sendCsvFile(formData);

      // Invalider la query pour rafraîchir les données
      await queryClient.invalidateQueries({
        queryKey: ["compte", compteSelected],
      });

      // Récupérer le nom du compte pour un message plus informatif
      const compteName =
        listeCompte.find((c) => c._id === compteSelected)?.nomCompte ||
        "sélectionné";

      // Afficher le toast de succès avec le récapitulatif
      showToast(
        "success",
        `Importation réussie pour ${compteName}:<br />
          ${data.inserted} lignes ajoutées<br />
          ${data.matched + data.modified} ignorées.`,
        10
      );

      // Rediriger vers la page principale des comptes bancaires avec l'onglet Table actif
      router.navigate({
        params: { tab: "compte" },
        to: "/bank/landing/$tab",
        replace: true,
      });
    } catch (e: any) {
      console.error("Erreur lors de l'importation:", e.message);

      // Afficher un toast d'erreur
      showToast(
        "error",
        `Échec de l'importation: ${e.message || "Une erreur est survenue"}`
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (listeCompte.length < 1) {
    return (
      <div className="alert alert-warning">
        Aucun compte disponible. Veuillez d'abord créer un compte.
      </div>
    );
  }

  return (
    <div className="flex flex-col p-4 m-4 border-2 border-gray-300 bg-base-200 rounded-2xl shadow-2xl">
      <div className="mb-8">
        <h2 className="text-2xl font-bold">Charger un fichier CSV</h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="flex items-center w-full">
          <span className="mr-2">Compte:</span>
          <select
            className="select select-bordered w-full"
            value={compteSelected}
            onChange={handleCompteChange}
          >
            <option value="" disabled>
              Choisir le compte
            </option>
            {listeCompte.map((compte) => (
              <option key={compte._id} value={compte._id}>
                {compte.nomCompte}
              </option>
            ))}
          </select>
        </div>

        <div className="flex items-center">
          <label className="btn btn-neutral">
            <Upload className="mr-2" size={18} />
            Fichier...
            <input
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="hidden"
            />
          </label>
          {file && (
            <span className="ml-2 text-success flex items-center">
              <Check size={20} />
              <span className="ml-1 truncate max-w-xs">{file.name}</span>
            </span>
          )}
        </div>

        <button
          className="btn btn-neutral"
          onClick={sendFile}
          disabled={!checkFormData() || isLoading}
        >
          {isLoading ? (
            <>
              <span className="loading loading-spinner loading-sm"></span>
              Envoi en cours
            </>
          ) : (
            <>
              <Save size={18} />
              Sauvegarder
            </>
          )}
        </button>
      </div>
    </div>
  );
};
