import { Banque, Compte } from "@/pages/Bank/types.ts";
import {
  createCompteForBanqueForUser,
  updateCompteForUser,
} from "@/utils/axios/bankUtils.ts";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showToast } from "@/components/tools/toast.ts";
import { getUserLogged } from "@/utils/axios/userUtils.ts";
import { UserOwnersSelector } from "@/pages/Bank/components/Compte/parts/UserOwnerSelector.tsx";
import { Building, Check, CreditCard, Wallet, X } from "lucide-react";

const createCompte = async (data: Partial<Compte>) => {
  const response = await createCompteForBanqueForUser(data as Compte);
  return response;
};

const updateCompte = async (data: Partial<Compte>) => {
  const response = await updateCompteForUser(data as Compte);
  return response;
};

export const CompteForm = ({
  formData,
  setFormData,
  banques,
  onSuccess = () => {},
  onCancel,
}: {
  formData: Partial<Compte>;
  setFormData: Dispatch<SetStateAction<Partial<Compte>>>;
  banques: Banque[];
  onSuccess?: () => void;
  onCancel?: () => void;
}) => {
  const queryClient = useQueryClient();
  const isEditMode = !!formData._id;

  // Initialiser l'établissement si nécessaire
  useEffect(() => {
    if (banques && banques.length > 0 && !formData.etablissement) {
      setFormData((prev) => ({
        ...prev,
        etablissement: banques[0]._id,
      }));
    }
  }, [banques, formData.etablissement, setFormData]);

  // S'assurer que owners est toujours un tableau initialisé
  useEffect(() => {
    if (!formData.owners) {
      const currentUserId = getUserLogged()?._id;
      setFormData((prev) => ({
        ...prev,
        owners: currentUserId ? [currentUserId] : [],
      }));
    }
  }, [formData.owners, setFormData]);

  // Configuration des toasts
  useEffect(() => {
    if (!document.getElementById("toast-container")) {
      const container = document.createElement("div");
      container.id = "toast-container";
      container.className = "toast toast-top toast-end z-50";
      document.body.appendChild(container);
    }

    // Nettoyage au démontage
    return () => {
      const container = document.getElementById("toast-container");
      if (container && container.childNodes.length === 0) {
        document.body.removeChild(container);
      }
    };
  }, []);

  // Fonction pour réinitialiser le formulaire
  const resetForm = () => {
    const currentUserId = getUserLogged()?._id;
    const initialForm = {
      nomCompte: "",
      typeCompte: "Compte personnel",
      correctionSoldeDepart: 0,
      etablissement: banques.length > 0 ? banques[0]._id : "",
      owners: currentUserId ? [currentUserId] : [],
    };
    if (onCancel && formData.nomCompte === "") onCancel();
    setFormData(initialForm as Compte);
  };

  // Mutation pour la création de compte
  const createCompteMutation = useMutation({
    mutationFn: createCompte,
    onSuccess: () => {
      resetForm();
      queryClient.invalidateQueries({ queryKey: ["compte"] }).then(() => {
        showToast("success", "Compte créé avec succès!");
        onSuccess();
      });
    },
    onError: (error: Error) => {
      showToast("error", error.message);
    },
  });

  // Mutation pour la mise à jour de compte
  const updateCompteMutation = useMutation({
    mutationFn: updateCompte,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["compte"] }).then(() => {
        showToast("success", "Compte mis à jour avec succès!");
        resetForm();
        onSuccess();
      });
    },
    onError: (error: Error) => {
      showToast("error", error.message);
    },
  });

  // Gestion des changements de champs
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value }: { name: string; value: string } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Fonction pour gérer le changement des propriétaires
  const handleOwnersChange = (newOwners: string[]) => {
    // Extraire les IDs propres
    const extractIds = (owners: any[]): string[] => {
      return owners
        .map((owner) =>
          typeof owner === "object" && owner?._id ? owner._id : owner
        )
        .filter((id) => typeof id === "string" && id.trim() !== "");
    };

    const cleanOwners = extractIds(newOwners);
    setFormData((prev) => ({ ...prev, owners: cleanOwners }));
  };

  // Soumission du formulaire
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isEditMode) {
      updateCompteMutation.mutate(formData);
    } else {
      createCompteMutation.mutate(formData);
    }
  };

  // Réinitialisation du formulaire
  const handleReset = (e: React.FormEvent) => {
    e.preventDefault();
    resetForm();
  };

  const isPending =
    createCompteMutation.isPending || updateCompteMutation.isPending;

  return (
    <div className="p-3 bg-base-100 rounded-lg shadow-sm border border-base-300">
      <h3 className="text-lg font-bold mb-3 flex items-center gap-2">
        <CreditCard className="text-primary" />
        {isEditMode ? "Modifier le compte" : "Créer un nouveau compte"}
      </h3>

      <form onSubmit={handleSubmit} onReset={handleReset} className="space-y-2">
        {/* Sélection de la banque */}
        <div className="form-control">
          <label className="label py-1">
            <span className="label-text flex items-center gap-1">
              <Building size={16} />
              Banque *
            </span>
          </label>
          <select
            name="etablissement"
            value={formData.etablissement}
            onChange={handleInputChange}
            className="select select-bordered select-sm w-full h-9"
            required
          >
            {banques.map((banque: Banque) => (
              <option key={banque._id} value={banque._id}>
                {banque.etablissement}
              </option>
            ))}
          </select>
        </div>

        {/* Nom du compte */}
        <div className="form-control">
          <label className="label py-1">
            <span className="label-text flex items-center gap-1">
              <CreditCard size={16} />
              Nom de compte *
            </span>
          </label>
          <input
            type="text"
            name="nomCompte"
            value={formData.nomCompte}
            onChange={handleInputChange}
            className="input input-bordered input-sm h-9 w-full"
            placeholder="Ex: Compte courant principal"
            required
          />
        </div>

        {/* Type de compte */}
        <div className="form-control">
          <label className="label py-1">
            <span className="label-text flex items-center gap-1">
              <Wallet size={16} />
              Type de compte *
            </span>
          </label>
          <select
            name="typeCompte"
            value={formData.typeCompte}
            onChange={handleInputChange}
            className="select select-bordered select-sm w-full h-9"
            required
          >
            <option>Compte personnel</option>
            <option>Compte Joint</option>
            <option>Livret</option>
          </select>
        </div>

        {/* Sélecteur de propriétaires */}
        <UserOwnersSelector
          currentOwners={formData.owners || []}
          onChange={handleOwnersChange}
        />

        {/* Correction du solde de départ */}
        <div className="form-control">
          <label className="label py-1">
            <span className="label-text flex items-center gap-1">
              <Wallet size={16} />
              Correction solde départ *
            </span>
            <span className="label-text-alt text-xs text-gray-500">
              Ajustement initial
            </span>
          </label>
          <input
            type="text"
            name="correctionSoldeDepart"
            value={formData.correctionSoldeDepart}
            onChange={handleInputChange}
            className="input input-bordered input-sm h-9 w-full"
            placeholder="0.00"
            required
          />
        </div>

        {/* Boutons d'action */}
        <div className="flex justify-end gap-2 pt-2">
          <button
            type="reset"
            className="btn btn-sm btn-outline btn-error"
            disabled={isPending}
          >
            {isPending ? (
              <>
                <span className="loading loading-spinner loading-xs"></span>
                Patientez...
              </>
            ) : (
              <>
                <X size={16} />
                Annuler
              </>
            )}
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={isPending}
          >
            {isPending ? (
              <>
                <span className="loading loading-spinner loading-xs"></span>
                Traitement...
              </>
            ) : (
              <>
                <Check size={16} />
                {isEditMode ? "Modifier" : "Créer"}
              </>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};
