interface PageSizeSelectorProps {
  pageSize: number;
  setPageSize: (size: number) => void;
  options: number[];
}

export const PageSizeSelector = ({
  pageSize,
  setPageSize,
  options,
}: PageSizeSelectorProps) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="text-sm">Lignes par page:</span>
      <select
        className="select select-bordered select-sm"
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};
