import React from "react";
import { CheckedRowList, RowData } from "@/pages/Bank/types.ts";
import { CategoryCell } from "@/pages/Bank/components/Table/parts/CategoryCell.tsx";
import { SortableColumnHeader } from "@/pages/Bank/components/Table/parts/SortableColumnHeader";
import { SortConfig } from "@/pages/Bank/components/Table/TableMain";

interface DataTableProps {
  data: RowData[];
  visibleColumns: { [key: string]: boolean };
  selectedCell: CheckedRowList;
  handleSelectRow: (id: string, isChecked: boolean) => void;
  areAllCurrentPageRowsSelected: () => boolean;
  handleSelectAllOnPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isValueHidden: boolean;
  exportMode: boolean;
  formatDateValue: (date: string | Date | undefined) => string;
  isCompactMode?: boolean;
  onToggleCompactMode?: () => void;
  onDataChange?: () => void; // Pour rafraîchir les données

  // Nouvelles props pour le tri
  sortConfig?: SortConfig;
  handleSort?: (column: keyof RowData) => void;
}

// Mapping des clés de colonnes vers des noms plus lisibles pour l'en-tête
const COLUMN_LABELS: { [key: string]: string } = {
  _id: "ID",
  date: "Date",
  libelleSimplifier: "Libellé simplifié",
  categorie: "Catégorie",
  sousCategorie: "Sous-catégorie",
  solde: "Solde",
  montant: "Montant",
  debit: "Débit",
  credit: "Crédit",
  pointageOperation: "Pointage",
  dateValeur: "Date valeur",
  dateOperation: "Date opération",
  reference: "Référence",
  informationComplementaire: "Info. compl.",
  typeOperation: "Type",
  libellerOperation: "Libellé",
  precision: "Précision",
  chronoIndex: "Ordre chronologique", // Nouvel index chronologique
};

export const DataTable = ({
  data,
  visibleColumns,
  selectedCell,
  handleSelectRow,
  areAllCurrentPageRowsSelected,
  handleSelectAllOnPage,
  isValueHidden,
  exportMode,
  formatDateValue,
  isCompactMode = false,
  // Nouvelles props pour le tri
  sortConfig = { key: null, direction: "desc" },
  handleSort = () => {},
}: DataTableProps) => {
  // Fonction pour formatter les valeurs selon le type de colonne
  const formatCellValue = (column: string, value: any): string => {
    if (value === null || value === undefined) return "";

    // Formater les dates
    if (
      column === "date" ||
      column === "dateValeur" ||
      column === "dateOperation"
    ) {
      return formatDateValue(value);
    }

    // Masquer les valeurs monétaires si demandé
    if (
      isValueHidden &&
      (column === "montant" ||
        column === "debit" ||
        column === "credit" ||
        column === "solde")
    ) {
      return "***** €";
    }

    // Formatter les valeurs monétaires
    if (
      column === "montant" ||
      column === "debit" ||
      column === "credit" ||
      column === "solde"
    ) {
      return value.toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
      });
    }

    // Valeur par défaut
    return String(value);
  };

  // Obtenez les colonnes visibles
  const visibleColumnKeys = Object.entries(visibleColumns)
    .filter(([, isVisible]) => isVisible)
    .map(([key]) => key);

  // Fonction pour appliquer des styles aux cellules
  const getCellStyles = (column: string, value: any): string => {
    let styles = "text-right ";

    if (column === "solde") {
      if (value < 0) {
        styles += "bg-red-200 text-slate-800 ";
      } else {
        styles += "bg-green-200 text-slate-800 ";
      }
      styles += "text-xs font-thin ";
    }

    if (column === "montant" || column === "debit" || column === "credit") {
      if (value < 0) {
        styles += "text-red-700 ";
      } else {
        styles += "text-green-800 ";
      }
      styles += "text-xs font-semibold ";
    }

    return styles;
  };

  // Classes conditionnelles basées sur le mode compact
  const getTableClasses = () => {
    let classes = "table table-zebra w-full ";
    if (isCompactMode) {
      classes += "table-compact text-sm";
    }
    return classes;
  };

  const getHeaderClasses = (column: string) => {
    let classes = "";
    if (isCompactMode) {
      classes += "p-1 h-8 text-xs font-medium ";
    }
    if (
      column === "montant" ||
      column === "debit" ||
      column === "credit" ||
      column === "solde"
    ) {
      classes += "text-right ";
    }
    return classes;
  };

  const getRowClasses = () => {
    let classes = "";
    if (isCompactMode) {
      classes += "h-7 hover:bg-base-200";
    } else {
      classes += "hover:bg-base-200";
    }
    return classes;
  };

  const getCellClasses = (column: string, value: any) => {
    let classes = "";
    if (isCompactMode) {
      classes += "p-1 py-0.5 ";
    }

    if (["montant", "debit", "credit", "solde"].includes(column)) {
      classes += getCellStyles(column, value);
    }

    return classes;
  };

  const getCheckboxClasses = () => {
    return isCompactMode ? "checkbox checkbox-xs" : "checkbox checkbox-sm";
  };

  const getCheckboxColClasses = () => {
    return isCompactMode ? "w-8 p-1" : "w-10";
  };

  // Fonction pour rendre une cellule en fonction de son type
  const renderCellContent = (row: RowData, column: string) => {
    // Pour les cellules de catégories, utiliser le composant CategoryCell
    if (column === "categorie") {
      return (
        <CategoryCell
          row={row}
          column="categorie"
          isCompactMode={isCompactMode}
          compteId={row.infoCompte}
        />
      );
    } else if (column === "sousCategorie") {
      return (
        <CategoryCell
          row={row}
          column="sousCategorie"
          isCompactMode={isCompactMode}
          compteId={row.infoCompte}
        />
      );
    }

    // Pour les autres types de cellules, afficher simplement la valeur formatée
    return formatCellValue(column, row[column]);
  };

  // Déterminer si une colonne est triable
  const isSortableColumn = (column: string): boolean => {
    // Ne pas autoriser le tri pour les colonnes qui sont des objets complexes
    return !["categoriePersonnel", "sousCategoriePersonnel"].includes(column);
  };

  // Rendu de l'en-tête de colonne
  const renderColumnHeader = (column: string) => {
    const label = COLUMN_LABELS[column] || column;

    if (isSortableColumn(column)) {
      return (
        <SortableColumnHeader
          column={column as keyof RowData}
          label={label}
          sortConfig={sortConfig}
          onSort={handleSort}
          isCompactMode={isCompactMode}
        />
      );
    }

    return label;
  };

  return (
    <div className="overflow-x-auto">
      <table className={getTableClasses()}>
        <thead>
          <tr>
            {exportMode && (
              <th className={getCheckboxColClasses()}>
                <input
                  type="checkbox"
                  className={getCheckboxClasses()}
                  checked={areAllCurrentPageRowsSelected()}
                  onChange={handleSelectAllOnPage}
                />
              </th>
            )}
            {visibleColumnKeys.map((column) => (
              <th key={column} className={getHeaderClasses(column)}>
                {renderColumnHeader(column)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((row) => (
              <tr key={row._id} className={getRowClasses()}>
                {exportMode && (
                  <td className={isCompactMode ? "p-1 w-8" : ""}>
                    <input
                      type="checkbox"
                      className={getCheckboxClasses()}
                      checked={!!row._id && !!selectedCell[row._id]}
                      onChange={(e) =>
                        row._id && handleSelectRow(row._id, e.target.checked)
                      }
                    />
                  </td>
                )}
                {visibleColumnKeys.map((column) => {
                  const cellValue = row[column];
                  return (
                    <td
                      key={`${row._id}-${column}`}
                      className={getCellClasses(column, cellValue)}
                    >
                      {renderCellContent(row, column)}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={visibleColumnKeys.length + (exportMode ? 1 : 0)}
                className={`text-center ${
                  isCompactMode ? "py-2 text-sm" : "py-4"
                }`}
              >
                Aucune donnée disponible
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
