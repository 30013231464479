import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useRouter } from "@tanstack/react-router";
import { MenuType } from "@/pages/types.ts";
import { getAvatarUrl } from "@/utils/axios/userUtils.ts";
import { UserSettings } from "@/utils/userTypes.ts";
import { nanoid } from "nanoid";
import { useTheme } from "@/hooks/use-theme.ts";
import {
  CircleUserRound,
  LogOut,
  Menu,
  Moon,
  Sun,
  Tent,
  X,
} from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { useAuthContext } from "@/providers/AuthProvider";

interface DaisyNavbarProps {
  menuItems: MenuType[];
}

export const DaisyNavbar = ({ menuItems }: DaisyNavbarProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const router = useRouter();
  const navigate = useNavigate();
  const [currentUrl, setCurrentUrl] = useState(router.state.location.pathname);
  const queryClient = useQueryClient();
  const { user, isAuthenticated, logout } = useAuthContext();
  const { theme, toggleTheme } = useTheme();

  const dropdownRefs = useRef<Map<string, HTMLDetailsElement | null>>(
    new Map()
  );

  useEffect(() => {
    // Si non authentifié et pas sur la page de login, rediriger
    if (!isAuthenticated && currentUrl !== "/login") {
      navigate({ to: "/login" });
    }
  }, [isAuthenticated, currentUrl, navigate]);

  useEffect(() => {
    const unsubscribe = router.history.subscribe(() => {
      setCurrentUrl(router.state.location.pathname);
      setIsDrawerOpen(false);
    });

    return () => unsubscribe();
  }, [router]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;

      if (
        target.closest(".dropdown-content") ||
        target.closest("summary") ||
        target.closest('[role="button"]')
      ) {
        return;
      }

      dropdownRefs.current.forEach((dropdown) => {
        if (dropdown && !dropdown.contains(target)) {
          dropdown.open = false;
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getMainUrlPart = (uri: string) => {
    if (uri === "/") return "index";
    const urlArray = uri.split("/");
    return urlArray[1];
  };

  const urlArrayPart = currentUrl === "/" ? "index" : currentUrl.split("/");

  const handleLogout = () => {
    console.log("Logout triggered - L'utilisateur se déconnecte");
    logout();
    queryClient.clear();
    navigate({ to: "/login" });
  };

  const userSettings: UserSettings[] = [
    {
      id: nanoid(),
      label: "Profil",
      target: "/profile",
      isDisplayed: true,
      icon: <CircleUserRound />,
    },
    {
      id: nanoid(),
      label: "Gestion Famille",
      target: "/family",
      isDisplayed: true,
      icon: <Tent />,
    },
    {
      id: nanoid(),
      label: "Logout",
      exec: function () {
        handleLogout();
      },
      isDisplayed: true,
      icon: <LogOut />,
    },
  ];

  const UserAvatar = () => {
    if (user?._id) {
      if (user.avatar) {
        return (
          <div className="avatar">
            <div className="w-10 h-10 rounded-full">
              <img src={getAvatarUrl(user.avatar)} alt={user.username} />
            </div>
          </div>
        );
      }
      return (
        <div className="avatar placeholder">
          <div className="w-10 h-10 rounded-full bg-primary text-primary-content">
            <span>{user.username?.substring(0, 2).toUpperCase()}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  const getVisibleMenuItems = () => {
    if (!isAuthenticated) {
      const connectionMenu: MenuType = {
        id: nanoid(),
        name: "Connexion",
        path: "/login",
        icon: undefined,
      };

      return [connectionMenu];
    }

    return menuItems.filter((item) => !item.hidden);
  };

  const renderMenuItems = (_: MenuType[]) => {
    return getVisibleMenuItems().map((item) => {
      if (item.subMenu) {
        return (
          <li key={item.id} className="mx-1">
            <details
              className="dropdown"
              ref={(el) => dropdownRefs.current.set(item.id, el)}
            >
              <summary
                className={
                  urlArrayPart.includes(getMainUrlPart(item.path))
                    ? "active"
                    : ""
                }
              >
                {item.icon && <span className="mr-2">{item.icon}</span>}
                {item.name}
              </summary>
              <ul className="p-2 bg-base-200 rounded-md dropdown-content z-[100] shadow">
                {item.subMenu
                  .filter((subItem) => !subItem.hidden)
                  .map((subItem) => (
                    <li key={subItem.id} className="my-1">
                      {subItem.disable ? (
                        <span className="opacity-50 cursor-not-allowed">
                          {subItem.icon && (
                            <span className="mr-2">{subItem.icon}</span>
                          )}
                          {subItem.name}
                        </span>
                      ) : (
                        <Link
                          to={item.path + subItem.path}
                          onClick={() => setIsDrawerOpen(false)}
                          className="flex items-center"
                          preload="intent"
                        >
                          {subItem.icon && (
                            <span className="mr-2">{subItem.icon}</span>
                          )}
                          {subItem.name}
                        </Link>
                      )}
                    </li>
                  ))}
              </ul>
            </details>
          </li>
        );
      }

      return (
        <li key={item.id} className="mx-1">
          {item.disable ? (
            <span className="opacity-50 cursor-not-allowed">
              {item.icon && <span className="mr-2">{item.icon}</span>}
              {item.name}
            </span>
          ) : (
            <Link
              to={item.path}
              onClick={() => setIsDrawerOpen(false)}
              preload="intent"
              className={`flex items-center ${
                urlArrayPart.includes(getMainUrlPart(item.path)) ? "active" : ""
              }`}
            >
              {item.icon && <span className="mr-2">{item.icon}</span>}
              {item.name}
            </Link>
          )}
        </li>
      );
    });
  };

  return (
    <>
      <div className="navbar bg-base-100 shadow-md sticky top-0 z-50">
        <div className="navbar-start">
          {isAuthenticated && (
            <button
              className="btn btn-ghost lg:hidden"
              onClick={() => setIsDrawerOpen(true)}
            >
              <Menu className="h-6 w-6" />
            </button>
          )}

          <Link
            to={isAuthenticated ? "/" : "/login"}
            preload="intent"
            className="btn btn-ghost text-xl"
          >
            YaCadMander
          </Link>
        </div>

        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1">
            {renderMenuItems(menuItems)}
          </ul>
        </div>

        <div className="navbar-end">
          <button
            className="btn btn-ghost btn-circle"
            onClick={toggleTheme}
            aria-label={
              theme === "dark" ? "Switch to light mode" : "Switch to dark mode"
            }
          >
            {theme === "dark" ? (
              <Sun className="h-5 w-5" />
            ) : (
              <Moon className="h-5 w-5" />
            )}
          </button>

          {isAuthenticated && (
            <div className="dropdown dropdown-end">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost btn-circle"
              >
                <UserAvatar />
              </div>
              <ul className="menu menu-sm dropdown-content mt-3 z-[200] p-2 shadow bg-base-100 rounded-box w-52">
                {userSettings.map((setting) => {
                  if (setting.target) {
                    return (
                      <li key={setting.id} className="my-1">
                        <Link to={setting.target} preload="intent">
                          {setting.label}
                        </Link>
                      </li>
                    );
                  } else if (setting.exec) {
                    return (
                      <li key={setting.id} className="my-1">
                        <button
                          type="button"
                          className="w-full text-left"
                          onClick={setting.exec}
                        >
                          {setting.label}
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </div>
          )}
        </div>
      </div>

      {isAuthenticated && (
        <div
          className={`fixed inset-0 z-50 transition-opacity duration-300 lg:hidden ${
            isDrawerOpen
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }`}
        >
          <div
            className="absolute inset-0 bg-black/50"
            onClick={() => setIsDrawerOpen(false)}
          ></div>

          <div
            className={`absolute top-0 left-0 h-full w-80 bg-base-100 shadow-xl transform transition-transform duration-300 ${
              isDrawerOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            <div className="flex justify-between items-center p-4 border-b">
              <span className="font-bold text-xl">YaCadMander</span>
              <button
                className="btn btn-ghost btn-sm btn-circle"
                onClick={() => setIsDrawerOpen(false)}
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="overflow-y-auto h-[calc(100%-4rem)]">
              <ul className="menu p-4">
                {renderMenuItems(menuItems)}
                {userSettings.map((setting) => (
                  <li key={setting.id} className="mx-1">
                    {setting.exec ? (
                      <span onClick={setting.exec}>
                        {setting.icon && (
                          <span className="mr-2">{setting.icon}</span>
                        )}
                        {setting.label}
                      </span>
                    ) : (
                      <Link
                        to={setting.target}
                        onClick={() => setIsDrawerOpen(false)}
                        preload="intent"
                        className={`flex items-center`}
                      >
                        {setting.icon && (
                          <span className="mr-2">{setting.icon}</span>
                        )}
                        {setting.label}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
