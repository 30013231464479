import axios from "axios";
import { getBackendMainUrl } from "./global.ts";
import { Todo } from "@/features/Todo/types.ts";

const BASE_URL_API = getBackendMainUrl + "/todos";
const HEADERS = {
  headers: {
    "content-type": "application/json",
    authorization: localStorage.getItem("authorization"),
  },
};
const HEADERS_FORM = {
  headers: {
    "content-type": "multipart/form-data",
    authorization: localStorage.getItem("authorization"),
  },
};

export const getLoggedUserTodos = async () => {
  try {
    const result = await axios.get(BASE_URL_API + "/", HEADERS);
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getListUserInfo = async () => {
  const listUserInfo = await axios.post(
    BASE_URL_API + "/shareTodo",
    { askUser: true },
    HEADERS
  );
  // console.log("listUserInfo:", listUserInfo.data);
  return listUserInfo;
};

export const manageSharedUserForTodo = async (
  todoId: string,
  userIds: string[],
  action: "add" | "remove"
) => {
  const parameters = {
    addUser: action === "add" && userIds,
    removeUser: action === "remove" && userIds,
    todoId,
  };
  const CHANGE = await axios.post(
    BASE_URL_API + "/shareTodo",
    parameters,
    HEADERS
  );
  console.log("CHANGE:", CHANGE.data);
  return CHANGE;
};

export const addTodo = async (todo: Partial<Todo>) => {
  try {
    const result = await axios.post(BASE_URL_API + "/", todo, HEADERS);
    return result;
  } catch (error) {
    console.error(error);
  }
};
export const updateTodo = async (todo: Partial<Todo>, todoId: string) => {
  try {
    const result = await axios.patch(
      BASE_URL_API + "/" + todoId,
      todo,
      HEADERS_FORM
    );
    return result;
  } catch (error) {
    console.error(error);
  }
};
export const removeTodo = async (todoId: string) => {
  try {
    return await axios.delete(BASE_URL_API + "/" + todoId, HEADERS);
  } catch (error) {
    console.error(error);
  }
};
