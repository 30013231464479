// Utilitaire pour les toasts DaisyUI
export const showToast = (
  type: "info" | "success" | "warning" | "error",
  message: string,
  time: number = 3
) => {
  const colors = {
    background: "",
    textColor: "",
    stroke: "",
  };
  switch (type) {
    case "info": {
      colors.background = "bg-blue-500";
      colors.textColor = "text-white";
      colors.stroke = "stroke-blue-200";
      break;
    }
    case "success": {
      colors.background = "bg-green-500";
      colors.textColor = "text-white";
      colors.stroke = "stroke-green-800";
      break;
    }
    case "warning": {
      colors.background = "bg-orange-500";
      colors.textColor = "text-white";
      colors.stroke = "stroke-orange-100";
      break;
    }
    case "error": {
      colors.background = "bg-red-500";
      colors.textColor = "text-white";
      colors.stroke = "stroke-red-200";
      break;
    }
  }
  // Créer l'élément toast
  const toast = document.createElement("div");
  toast.className = `alert alert-${type} ${colors.background}`;

  // Définir l'icône en fonction du type
  let icon = "";
  switch (type) {
    case "success":
      icon = `<svg xmlns="http://www.w3.org/2000/svg" class="${colors.stroke} shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>`;
      break;
    case "error":
      icon = `<svg xmlns="http://www.w3.org/2000/svg" class="${colors.stroke} shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>`;
      break;
    case "warning":
      icon = `<svg xmlns="http://www.w3.org/2000/svg" class="${colors.stroke} shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>`;
      break;
    case "info":
    default:
      icon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="${colors.stroke} shrink-0 w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>`;
      break;
  }

  // Définir le contenu du toast
  toast.innerHTML = `
    <div class="${colors.textColor}">
      ${icon}
      <span>${message}</span>
    </div>
  `;

  // Récupérer ou créer le conteneur de toast
  let toastContainer = document.getElementById("toast-container");
  if (!toastContainer) {
    toastContainer = document.createElement("div");
    toastContainer.id = "toast-container";
    toastContainer.className = "toast toast-top toast-end z-50";
    document.body.appendChild(toastContainer);
  }

  // Ajouter le toast au conteneur
  toastContainer.appendChild(toast);

  // Supprimer le toast après 3 secondes
  setTimeout(() => {
    if (toast && toastContainer.contains(toast)) {
      toastContainer.removeChild(toast);
    }
  }, time * 1000);
};
