import { ReactNode, useState } from "react";

interface ColumnsTogglerProps {
  visibleColumns: { [key: string]: boolean };
  toggleColumnVisibility: (columnKey: string) => void;
  buttonClassName?: string; // Prop optionnelle pour les classes du bouton
  buttonContent?: ReactNode; // Prop optionnelle pour le contenu du bouton
}

// Mapping des clés de colonnes vers des noms plus lisibles
const COLUMN_LABELS: { [key: string]: string } = {
  _id: "ID",
  date: "Date",
  libelleSimplifier: "Libellé simplifié",
  categorie: "Catégorie",
  sousCategorie: "Sous-catégorie",
  solde: "Solde",
  montant: "Montant",
  debit: "Débit",
  credit: "Crédit",
  pointageOperation: "Pointage",
  dateValeur: "Date valeur",
  dateOperation: "Date opération",
  reference: "Référence",
  informationComplementaire: "Info. complémentaire",
  typeOperation: "Type opération",
  libellerOperation: "Libellé opération",
  sousCategoriePersonnel: "Sous-catégorie perso",
  precision: "Précision",
  categoriePersonnel: "Catégorie perso",
};

export const ColumnsToggler = ({
  visibleColumns,
  toggleColumnVisibility,
  buttonClassName = "btn btn-sm btn-outline", // Valeur par défaut
  buttonContent = "Colonnes", // Valeur par défaut
}: ColumnsTogglerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="dropdown dropdown-end">
      <button className={buttonClassName} onClick={() => setIsOpen(!isOpen)}>
        {buttonContent}
      </button>

      {isOpen && (
        <div className="dropdown-content z-10 menu p-2 shadow bg-base-100 rounded-box w-52 mt-2">
          <div className="max-h-96 overflow-y-auto">
            {Object.entries(visibleColumns).map(([key, isVisible]) => (
              <div key={key} className="form-control">
                <label className="label cursor-pointer justify-start gap-2">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-sm"
                    checked={isVisible}
                    onChange={() => toggleColumnVisibility(key)}
                  />
                  <span className="label-text">
                    {COLUMN_LABELS[key] || key}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
