import { MenuType } from "@/pages/types.ts";
import { nanoid } from "nanoid";
import {
  Backpack,
  Compass,
  Home,
  ListTodo,
  MessageCircle,
  PiggyBank,
  TrainFrontIcon,
  TramFrontIcon,
} from "lucide-react";

export const menuItems: MenuType[] = [
  {
    id: nanoid(),
    name: "Home",
    path: "/",
    icon: <Compass />,
  },
  {
    id: nanoid(),
    name: "SNCF",
    path: "/sncf",
    icon: <TramFrontIcon />,
  },
  {
    id: nanoid(),
    name: "Parcourt",
    icon: <TrainFrontIcon />,
    path: "/sncf/playSncf",
    disable: false,
  },
  {
    id: nanoid(),
    name: "House",
    path: "/house",
    icon: <Home />,
    subMenu: [
      {
        id: nanoid(),
        name: "Éclairage Jardin",
        path: "/lightsExt",
      },
      {
        id: nanoid(),
        name: "Led Reminder",
        path: "/reminder",
      },
      {
        id: nanoid(),
        name: "Puit",
        path: "/puit",
      },
    ],
  },
  {
    id: nanoid(),
    name: "Todos",
    path: "/todos",
    icon: <ListTodo />,
  },
  {
    id: nanoid(),
    name: "Feed",
    icon: <MessageCircle />,
    path: "/feed",
    disable: false,
  },
  {
    id: nanoid(),
    name: "Vacances",
    path: "/vacances",
    icon: <Backpack />,
    subMenu: [
      {
        id: nanoid(),
        name: "Molky",
        path: "/molky",
      },
    ],
  },
  {
    id: nanoid(),
    name: "Bank",
    icon: <PiggyBank />,
    path: "/bank/landing/compte",
  },
  // Ces menus sont masqués mais toujours accessibles par URL
  {
    id: nanoid(),
    name: "Login",
    path: "/login",
    hidden: true,
  },
  {
    id: nanoid(),
    name: "TodoFocus",
    path: "/todos/:id",
    hidden: true,
  },
  {
    id: nanoid(),
    name: "TodoForm",
    path: "/todoForm",
    hidden: true,
  },
  {
    id: nanoid(),
    name: "Profil",
    path: "/profile",
    hidden: true,
  },
];
