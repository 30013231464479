import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Compte, PrevisionRowSchema, RowData } from "@/pages/Bank/types.ts";
import { formatDate } from "@/utils/textUtils.ts";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createLignePrevisionDeCompte,
  deleteLignePrevisionDeCompte,
  getLigneDeCompte,
  getLignesPrevisionDeCompte,
  updateLignePrevisionDeCompte,
} from "@/utils/axios/bankUtils.ts";
import { AxiosResponse } from "axios";

// Définition des types pour notre hook
export type VisibleColumnsType = {
  [key: string]: boolean;
};

// Type pour les props du hook
interface UseDataCompteProps {
  compte: Compte;
}

type UseDataCompteReturn = {
  // Données
  lignesCompte: RowData[];
  isLignesCompteLoading: boolean;
  isLignesCompteError: boolean;
  lignesPrevision: PrevisionRowSchema[];
  isLignesPrevisionDeCompteLoading: boolean;
  isLignesPrevisionDeCompteError: boolean;

  // Handlers
  addPrevision: (prevision: PrevisionRowSchema) => Promise<AxiosResponse>;
  updatePrevision: (prevision: PrevisionRowSchema) => Promise<AxiosResponse>;
  removePrevision: (previsionId: string) => Promise<AxiosResponse>;
  setLignesCompte: Dispatch<SetStateAction<RowData[]>>;

  // Utilitaires
  formatDateValue: (date: string | Date | undefined) => string;
};

/**
 * Fonction pour trier les lignes par date et ajouter un index chronologique
 */
const sortRowsAndAddIndex = (rows: RowData[]): RowData[] => {
  if (!rows || rows.length === 0) return [];

  // Créer une copie pour ne pas modifier l'original
  const copiedRows = [...rows];

  // Trier par date (de la plus ancienne à la plus récente)
  copiedRows.sort((a, b) => {
    const dateA = new Date(a.dateOperation || a.date || 0);
    const dateB = new Date(b.dateOperation || b.date || 0);
    return dateA.getTime() - dateB.getTime();
  });

  // Ajouter un index chronologique
  return copiedRows.map((row, index) => ({
    ...row,
    chronoIndex: index, // Ajouter un index chronologique unique
  }));
};

/**
 * Fonction pour calculer le solde pour chaque ligne
 */
const getRowsWithSolde = (
  rowsInput: RowData[],
  correctionSoldeDepart: number = 0
): RowData[] => {
  if (!rowsInput || rowsInput.length === 0) return [];

  // Trier les lignes et ajouter un index chronologique
  const sortedIndexedRows = sortRowsAndAddIndex(rowsInput);

  let solde = correctionSoldeDepart;

  // Calculer les soldes
  const mapSolde = sortedIndexedRows.map((ligneCompte) => {
    // Déterminer le montant pour l'impact sur le solde
    const montantOperation =
      ligneCompte.credit !== 0 ? ligneCompte.credit : ligneCompte.debit; // Débit est négatif

    // Ajouter au solde
    solde += montantOperation;

    // Retourner la ligne avec le solde et le montant
    return {
      ...ligneCompte,
      precision: ligneCompte.precision || "",
      montant:
        ligneCompte.credit !== 0 ? ligneCompte.credit : ligneCompte.debit,
      solde: solde,
    };
  });

  // Inverser pour afficher de la plus récente à la plus ancienne
  return mapSolde.reverse();
};

export const useDataCompte = ({
  compte,
}: UseDataCompteProps): UseDataCompteReturn => {
  // const [selectedCell, setSelectedCell] = useState<CheckedRowList>({});
  const [lignesCompte, setLignesCompte] = useState<RowData[]>([]);
  const [lignesPrevision, setLignesPrevision] = useState<PrevisionRowSchema[]>(
    []
  );

  const queryClient = useQueryClient();
  // Requête API pour récupérer les données Lignes de compte
  const {
    data: lignesDeCompte,
    isLoading: isLignesCompteLoading,
    isError: isLignesCompteError,
  } = useQuery({
    queryKey: ["compte", compte._id],
    queryFn: () => getLigneDeCompte(compte._id),
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: lignesPrevisionDeCompte,
    isLoading: isLignesPrevisionDeCompteLoading,
    isError: isLignesPrevisionDeCompteError,
  } = useQuery({
    queryKey: ["previsions-compte", compte._id],
    queryFn: () => getLignesPrevisionDeCompte(compte._id),
    staleTime: 1000 * 60 * 5,
  });

  // Mise à jour des données lorsque les lignes de compte changent ou que le compte change
  useEffect(() => {
    if (lignesDeCompte) {
      // Récupérer la correction de solde depuis l'objet compte
      const correctionSoldeDepart = compte.correctionSoldeDepart || 0;

      // Calculer les soldes pour chaque ligne avec la correction de solde
      const rowsWithSolde = getRowsWithSolde(
        lignesDeCompte,
        correctionSoldeDepart
      );
      setLignesCompte(rowsWithSolde);
    }
  }, [lignesDeCompte, compte]);

  // Mise à jour des données lorsque les lignes de prévision changent ou que le compte change
  useEffect(() => {
    if (lignesPrevisionDeCompte) {
      setLignesPrevision(lignesPrevisionDeCompte);
    }
  }, [lignesPrevisionDeCompte, compte]);

  // Formatter une date
  const formatDateValue = useCallback((date: string | Date | undefined) => {
    if (!date) return "";
    if (date instanceof Date) {
      // Convertir la date en string si c'est un objet Date
      return formatDate(date.toISOString());
    }
    return formatDate(date);
  }, []);

  const addPrevision = async (prevision: PrevisionRowSchema) => {
    // Envoyer la demande de creation de prevision
    const createdPrevision = await createLignePrevisionDeCompte(prevision);
    // invalider la query pour forcer le rechargement des données
    await queryClient.invalidateQueries({
      queryKey: ["previsions-compte", compte._id],
    });
    return createdPrevision;
  };

  const updatePrevision = async (prevision: PrevisionRowSchema) => {
    const updatedPrevision = await updateLignePrevisionDeCompte(prevision);
    await queryClient.invalidateQueries({
      queryKey: ["previsions-compte", compte._id],
    });
    return updatedPrevision;
  };

  const removePrevision = async (previsionId: string) => {
    const response = await deleteLignePrevisionDeCompte(previsionId);
    await queryClient.invalidateQueries({
      queryKey: ["previsions-compte", compte._id],
    });
    return response;
  };

  return {
    // Données
    lignesCompte: lignesCompte || [],
    isLignesCompteLoading,
    isLignesCompteError,

    lignesPrevision: lignesPrevision || [],
    isLignesPrevisionDeCompteLoading,
    isLignesPrevisionDeCompteError,

    // Handlers
    addPrevision,
    updatePrevision,
    removePrevision,
    setLignesCompte,

    // Utilitaires
    formatDateValue,
  };
};
