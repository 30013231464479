import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useJoinFamily } from "@/hooks/familyHooks";

const JoinFamilyForm: React.FC = () => {
  const [inviteCode, setInviteCode] = useState("");
  const joinFamilyMutation = useJoinFamily();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!inviteCode.trim()) return;

    joinFamilyMutation.mutate(inviteCode, {
      onSuccess: () => {
        setInviteCode("");
      },
    });
  };

  return (
    <Card elevation={3}>
      <CardHeader title="Rejoindre une famille" />
      <CardContent>
        <Typography variant="body2" color="textSecondary" paragraph>
          Entrez le code d'invitation fourni par le propriétaire de la famille.
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            label="Code d'invitation"
            variant="outlined"
            fullWidth
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
            margin="normal"
            required
            disabled={joinFamilyMutation.isPending}
            placeholder="Par exemple: A1B2C3D4"
          />

          {joinFamilyMutation.isError && (
            <Alert severity="error" sx={{ my: 2 }}>
              Erreur:{" "}
              {joinFamilyMutation.error.message || "Code d'invitation invalide"}
            </Alert>
          )}

          {joinFamilyMutation.isSuccess && (
            <Alert severity="success" sx={{ my: 2 }}>
              Vous avez rejoint la famille avec succès !
            </Alert>
          )}

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!inviteCode.trim() || joinFamilyMutation.isPending}
              startIcon={
                joinFamilyMutation.isPending ? (
                  <CircularProgress size={20} />
                ) : null
              }
            >
              {joinFamilyMutation.isPending
                ? "En cours..."
                : "Rejoindre la famille"}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default JoinFamilyForm;
