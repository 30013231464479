import React, { useEffect, useRef, useState } from "react";
import { Compte, PrevisionRowSchema } from "@/pages/Bank/types.ts";
import { useDataCompte } from "@/pages/Bank/components/hooks/useDataCompte.tsx";
import { PrevisionTable } from "@/pages/Bank/components/Prevision/components/PrevisionTable.tsx";
import { PrevisionForm } from "@/pages/Bank/components/Prevision/components/PrevisionForm.tsx";

interface ExpandableDrawerProps {
  isVisible: boolean;
  minHeight?: number;
  maxHeight?: number;
  compte: Compte;
}

const PrevisionPane: React.FC<ExpandableDrawerProps> = ({
  isVisible,
  minHeight = 200,
  maxHeight = 600,
  compte,
}) => {
  const [height, setHeight] = useState(minHeight);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [startHeight, setStartHeight] = useState(minHeight);
  const [previsionToEdit, setPrevisionToEdit] = useState<
    PrevisionRowSchema | undefined
  >(undefined);
  const drawerRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLDivElement>(null);

  const { lignesPrevision, addPrevision, updatePrevision, removePrevision } =
    useDataCompte({ compte });

  // Handle resize logic
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true);
    setStartY(e.clientY);
    setStartHeight(height);
    e.preventDefault();
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging) return;
    const deltaY = startY - e.clientY;
    const newHeight = Math.min(
      Math.max(startHeight + deltaY, minHeight),
      maxHeight
    );
    setHeight(newHeight);
    e.preventDefault();
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Add and remove event listeners
  useEffect(() => {
    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  // Reset height when drawer becomes visible
  useEffect(() => {
    if (isVisible) {
      setHeight(minHeight);
    }
  }, [isVisible, minHeight]);

  const handleConfirmPrevisionForm = async (
    prevision: Partial<PrevisionRowSchema>
  ) => {
    if (prevision.isNew) addPrevision(prevision as PrevisionRowSchema);
    else updatePrevision(prevision as PrevisionRowSchema);
  };

  const handleResetPrevisionForm = () => {
    lignesPrevision.forEach((prevision) => {
      updatePrevision({ ...prevision, done: false });
    });
  };

  const handleToEditPrevision = (prevision: PrevisionRowSchema | undefined) => {
    setPrevisionToEdit(prevision);
  };

  const handleRemovePrevision = async (previsionId: string) => {
    // ask for confirmation
    await removePrevision(previsionId);
  };

  return (
    <div
      className={`fixed inset-x-0 bottom-0 z-50 transition-transform duration-300 ${
        isVisible ? "translate-y-0" : "translate-y-full"
      }`}
    >
      {/* Drawer */}
      <div
        ref={drawerRef}
        className="bg-base-100 border-t border-base-300 rounded-t-xl shadow-xl"
        style={{ height: `${height}px` }}
      >
        {/* Drag handle */}
        <div
          ref={handleRef}
          className="h-6 w-full cursor-ns-resize flex justify-center items-center border-b border-base-300"
          onMouseDown={handleMouseDown}
        >
          <div className="w-16 h-1 bg-base-300 rounded-full"></div>
        </div>

        {/* Content */}
        <div
          className="p-4 overflow-auto"
          style={{ height: `calc(100% - 24px)` }}
        >
          <PrevisionForm
            onAjouter={handleConfirmPrevisionForm}
            onClose={handleToEditPrevision}
            onReinitialiser={handleResetPrevisionForm}
            infoCompte={compte._id}
            prevision={previsionToEdit}
          />
          <PrevisionTable
            lignes={lignesPrevision}
            onSupprimer={handleRemovePrevision}
            handleEdit={handleToEditPrevision}
            onToggleDone={handleConfirmPrevisionForm}
          />
        </div>
      </div>
    </div>
  );
};

export default PrevisionPane;
