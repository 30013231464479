import React from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { RowData } from "@/pages/Bank/types.ts";
import { SortConfig } from "@/pages/Bank/components/Table/TableMain";

interface SortableColumnHeaderProps {
  column: keyof RowData;
  label: string;
  sortConfig: SortConfig;
  onSort: (column: keyof RowData) => void;
  isCompactMode?: boolean;
}

export const SortableColumnHeader: React.FC<SortableColumnHeaderProps> = ({
  column,
  label,
  sortConfig,
  onSort,
  isCompactMode = false,
}) => {
  // Déterminer si cette colonne est actuellement triée
  const isSorted = sortConfig.key === column;
  const direction = isSorted ? sortConfig.direction : null;

  // Classes pour le mode compact
  const headerClasses = isCompactMode
    ? "p-1 h-8 text-xs font-medium cursor-pointer select-none"
    : "p-2 cursor-pointer select-none";

  return (
    <div
      className={`flex items-center justify-between gap-1 ${headerClasses}`}
      onClick={() => onSort(column)}
    >
      <span>{label}</span>
      <span className="text-gray-400">
        {isSorted ? (
          direction === "asc" ? (
            <FaSortUp size={isCompactMode ? 12 : 14} className="text-primary" />
          ) : (
            <FaSortDown
              size={isCompactMode ? 12 : 14}
              className="text-primary"
            />
          )
        ) : (
          <FaSort size={isCompactMode ? 10 : 12} className="opacity-50" />
        )}
      </span>
    </div>
  );
};
