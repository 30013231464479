import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getUserById,
  getUserListExceptUserLogged,
} from "@/utils/axios/userUtils.ts";
import { UserType } from "@/utils/userTypes.ts";
import { Search, Users, X } from "lucide-react";

interface UserOwnersSelectorProps {
  currentOwners: string[] | any[];
  onChange: (newOwners: string[]) => void;
}

export const UserOwnersSelector = ({
  currentOwners,
  onChange,
}: UserOwnersSelectorProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // Fonction pour extraire les IDs des propriétaires
  const extractIds = (owners: any[]): string[] => {
    return owners
      .map((owner) =>
        typeof owner === "object" && owner?._id ? owner._id : owner
      )
      .filter((id) => typeof id === "string" && id.trim() !== "");
  };

  // Liste d'IDs valides (qu'ils soient des objets ou des chaînes)
  const validOwnerIds = Array.isArray(currentOwners)
    ? extractIds(currentOwners)
    : [];

  // Charger la liste des utilisateurs
  const { data: userList = [], isLoading } = useQuery({
    queryKey: ["users"],
    queryFn: getUserListExceptUserLogged,
    staleTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

  // Gérer l'ajout/suppression d'un utilisateur
  const handleUserToggle = (userId: string) => {
    const newOwners = validOwnerIds.includes(userId)
      ? validOwnerIds.filter((id) => id !== userId)
      : [...validOwnerIds, userId];

    onChange(newOwners);
  };

  // Filtrer les utilisateurs selon le terme de recherche
  const filteredUsers = userList.filter((user: UserType) => {
    return user.username.toLowerCase().includes(searchTerm.toLowerCase());
  });

  // Obtenir les informations des utilisateurs sélectionnés
  const { data: selectedUsers = [] } = useQuery({
    queryKey: ["selected-users", JSON.stringify(validOwnerIds)],
    queryFn: async () => {
      if (validOwnerIds.length === 0) return [];

      try {
        const userPromises = validOwnerIds.map((id) => getUserById(id));
        const userDetails = await Promise.all(
          userPromises.map((p) => p.catch(() => null))
        );
        return userDetails.filter(Boolean) as UserType[];
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des utilisateurs:",
          error
        );
        return [];
      }
    },
    enabled: validOwnerIds.length > 0,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Fermer le dropdown quand on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest(".owner-selector-dropdown") && isDropdownOpen) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  // Réinitialiser la recherche quand on ferme le dropdown
  useEffect(() => {
    if (!isDropdownOpen) {
      setSearchTerm("");
    }
  }, [isDropdownOpen]);

  return (
    <div className="form-control mb-2 owner-selector-dropdown">
      <label className="label py-1">
        <span className="label-text flex items-center gap-1">
          <Users size={16} />
          Partager avec
        </span>
        <span className="label-text-alt text-xs text-gray-500">
          {validOwnerIds.length} utilisateur(s)
        </span>
      </label>

      <div className="dropdown w-full">
        <div
          tabIndex={0}
          role="button"
          className="input input-bordered input-sm h-9 flex justify-between items-center"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span className="flex items-center gap-2">
            <Users size={16} className="text-gray-500" />
            {validOwnerIds.length === 0 ? (
              <span className="text-gray-500">
                Sélectionner des utilisateurs
              </span>
            ) : (
              <span>
                {selectedUsers.length > 0
                  ? selectedUsers.map((u) => u.username).join(", ")
                  : `${validOwnerIds.length} utilisateur(s) sélectionné(s)`}
              </span>
            )}
          </span>
          <span className="text-xs">{isDropdownOpen ? "▲" : "▼"}</span>
        </div>

        {isDropdownOpen && (
          <div
            tabIndex={0}
            className="dropdown-content z-10 bg-base-100 shadow-lg rounded-box w-full max-h-72 overflow-hidden mt-1 border border-base-300"
          >
            {/* Barre de recherche */}
            <div className="p-2 border-b border-base-300">
              <div className="input-group">
                <span className="btn btn-square btn-sm bg-base-300">
                  <Search size={14} />
                </span>
                <input
                  type="text"
                  placeholder="Rechercher..."
                  className="input input-bordered input-sm w-full"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </div>

            {/* Liste des utilisateurs */}
            {isLoading ? (
              <div className="p-4 text-center">
                <span className="loading loading-spinner loading-sm text-primary"></span>
                <span className="ml-2">Chargement des utilisateurs...</span>
              </div>
            ) : filteredUsers.length === 0 ? (
              <div className="p-4 text-center text-gray-500">
                {searchTerm
                  ? "Aucun résultat pour cette recherche"
                  : "Aucun utilisateur disponible"}
              </div>
            ) : (
              <div className="overflow-y-auto max-h-60">
                <ul className="menu p-0">
                  {filteredUsers.map((user: UserType) => (
                    <li
                      key={user._id}
                      className="border-b border-base-200 last:border-b-0"
                    >
                      <label className="flex justify-between items-center py-2 px-4 hover:bg-base-200">
                        <span className="font-medium">{user.username}</span>
                        <input
                          type="checkbox"
                          className="checkbox checkbox-sm checkbox-primary"
                          checked={validOwnerIds.includes(user._id)}
                          onChange={() => handleUserToggle(user._id)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Badges des utilisateurs sélectionnés */}
      {validOwnerIds.length > 0 && (
        <div className="flex flex-wrap gap-1 mt-1">
          {validOwnerIds.map((ownerId) => {
            const user =
              userList.find((u: UserType) => u._id === ownerId) ||
              selectedUsers.find((u: UserType) => u._id === ownerId);
            return user ? (
              <div
                key={`selected-${ownerId}`}
                className="badge badge-sm badge-primary gap-1"
              >
                {user.username}
                <button
                  onClick={() => handleUserToggle(ownerId)}
                  className="btn btn-xs btn-ghost"
                >
                  <X size={10} />
                </button>
              </div>
            ) : (
              <div
                key={`unknown-${ownerId}`}
                className="badge badge-sm badge-warning gap-1"
              >
                ID: {ownerId.substring(0, 6)}...
                <button
                  onClick={() => handleUserToggle(ownerId)}
                  className="btn btn-xs btn-ghost"
                >
                  <X size={10} />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
