import * as LucideIcons from "lucide-react";

// Fonction utilitaire pour sauvegarder une préférence
export const savePreferenceLocalStorage = (
  key: string,
  value: string | boolean | number | { [key: string]: boolean },
  prefix: string,
  discriminateKey: string
) => {
  localStorage.setItem(
    `${prefix}${key}_${discriminateKey}`,
    JSON.stringify(value)
  );
};

// Fonction utilitaire pour charger une préférence
export const loadPreferenceLocalStorage = (
  key: string,
  defaultValue: string | boolean | number | { [key: string]: boolean },
  prefix: string,
  discriminateKey: string
) => {
  const savedValue = localStorage.getItem(`${prefix}${key}_${discriminateKey}`);
  return savedValue !== null ? JSON.parse(savedValue) : defaultValue;
};

/**
 * Récupère un composant d'icône Lucide à partir de son nom
 * @param iconName Le nom de l'icône (ex: "shopping-cart", "coffee", etc.)
 * @returns Le composant React correspondant ou undefined si non trouvé
 */
export const getIconComponent = (iconName: string) => {
  // Normaliser le nom de l'icône (kebab-case vers PascalCase)
  const normalizedName = iconName
    .split("-")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join("");

  // Récupérer l'icône depuis Lucide
  return (LucideIcons as any)[normalizedName];
};

/**
 * Liste des icônes disponibles pour les catégories
 */
export const availableIcons = [
  // Catégories de base
  "home", // Maison
  "wallet", // Finances
  "shopping-cart", // Courses
  "utensils", // Alimentation
  "coffee", // Restaurant
  "car", // Transport
  "bus", // Transport en commun
  "plug", // Électricité
  "droplet", // Eau
  "flame", // Gaz
  "phone", // Téléphone
  "wifi", // Internet
  "tv", // TV
  "heart", // Santé
  "baby", // Enfants
  "gamepad-2", // Jeux
  "music", // Musique
  "film", // Cinéma
  "book", // Livres
  "shirt", // Vêtements
  "plane", // Voyages
  "dog", // Animaux
  "gift", // Cadeaux
  "briefcase", // Travail
  "building", // Immobilier

  // Autres icônes utiles
  "credit-card", // Carte de crédit
  "receipt", // Reçus
  "pill", // Médicaments
  "scissors", // Coiffeur
  "hammer", // Bricolage
  "flower", // Jardin
  "wine", // Boissons
  "shopping-bag", // Shopping
  "train", // Train
  "bike", // Vélo
  "dollar-sign", // Argent
];
