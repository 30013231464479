import { Player } from "@/pages/Molky/types.ts";

interface ScoreBoardProps {
  players: Player[];
  currentPlayerId?: string;
}

export function ScoreBoard({ players, currentPlayerId }: ScoreBoardProps) {
  // Trier les joueurs par score, du plus haut au plus bas
  const sortedPlayers = [...players].sort((a, b) => b.score - a.score);

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">Tableau des scores</h2>

        <div className="overflow-x-auto">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>Pos.</th>
                <th>Joueur</th>
                <th>Score</th>
                <th>Derniers lancers</th>
              </tr>
            </thead>
            <tbody>
              {sortedPlayers.map((player, index) => (
                <tr
                  key={player.id}
                  className={
                    player.id === currentPlayerId
                      ? "bg-accent bg-opacity-30"
                      : ""
                  }
                >
                  <td>{index + 1}</td>
                  <td>{player.name}</td>
                  <td className="font-bold">{player.score}</td>
                  <td className="text-sm">
                    {player.history.slice(-3).map((score, i) => (
                      <span key={i} className="badge mx-1">
                        {score}
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
