import { useEffect, useMemo, useRef, useState } from "react";
import { Compte } from "@/pages/Bank/types.ts";
import {
  FaCalendarAlt,
  FaChartBar,
  FaChartLine,
  FaChartPie,
  FaFilter,
  FaTable,
} from "react-icons/fa";
import { useDataCompte } from "@/pages/Bank/components/hooks/useDataCompte.tsx";
import * as d3 from "d3";

type AnalyticsViewProps = {
  compte: Compte;
};

type PeriodType = "month" | "quarter" | "year" | "all";
type ChartType = "pie" | "line" | "bar" | "table";
type CategoryData = {
  name: string;
  amount: number;
  percentage: number;
  transactions: number;
  color?: string;
};

// Palettes de couleurs pour les graphiques
const COLORS = [
  "#3b82f6",
  "#ef4444",
  "#10b981",
  "#f59e0b",
  "#8b5cf6",
  "#ec4899",
  "#06b6d4",
  "#84cc16",
  "#f97316",
  "#6366f1",
  "#14b8a6",
  "#a855f7",
  "#d946ef",
  "#facc15",
  "#0ea5e9",
];

export const AnalyticsView = ({ compte }: AnalyticsViewProps) => {
  // Refs pour les graphiques
  const pieChartRef = useRef<HTMLDivElement>(null);
  const barChartRef = useRef<HTMLDivElement>(null);
  const lineChartRef = useRef<HTMLDivElement>(null);

  // États
  const [chartType, setChartType] = useState<ChartType>("pie");
  const [period, setPeriod] = useState<PeriodType>("month");
  const [filterType, setFilterType] = useState<"all" | "expenses" | "income">(
    "all"
  );
  const [monthOffset, setMonthOffset] = useState(0); // 0 = mois courant, -1 = mois précédent, etc.

  // Récupérer les données via le hook
  const { lignesCompte, isLignesCompteLoading } = useDataCompte({
    compte,
  });

  // Organiser les données par période
  const periodData = useMemo(() => {
    if (!lignesCompte || lignesCompte.length === 0) return [];

    // Obtenir la date courante et les limites de la période sélectionnée
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    // Calculer les dates de début et de fin en fonction de la période et de l'offset
    let startDate: Date, endDate: Date;

    switch (period) {
      case "month": {
        startDate = new Date(currentYear, currentMonth + monthOffset, 1);
        endDate = new Date(currentYear, currentMonth + monthOffset + 1, 0);
        break;
      }
      case "quarter": {
        const quarterStartMonth =
          Math.floor(currentMonth / 3) * 3 + monthOffset * 3;
        startDate = new Date(currentYear, quarterStartMonth, 1);
        endDate = new Date(currentYear, quarterStartMonth + 3, 0);
        break;
      }
      case "year": {
        startDate = new Date(currentYear + monthOffset, 0, 1);
        endDate = new Date(currentYear + monthOffset + 1, 0, 0);
        break;
      }
      default: {
        // "all"
        startDate = new Date(0); // epoch
        endDate = new Date(9999, 11, 31); // très loin dans le futur
        break;
      }
    }

    // Filtrer les lignes dans la période sélectionnée
    return lignesCompte.filter((ligne) => {
      const ligneDate = new Date(ligne.date);
      return ligneDate >= startDate && ligneDate <= endDate;
    });
  }, [lignesCompte, period, monthOffset]);

  // Filtrer par type (dépenses/revenus/tous)
  const filteredData = useMemo(() => {
    if (!periodData) return [];

    switch (filterType) {
      case "expenses":
        return periodData.filter((ligne) => ligne.montant < 0);
      case "income":
        return periodData.filter((ligne) => ligne.montant > 0);
      default:
        return periodData;
    }
  }, [periodData, filterType]);

  // Données des dépenses et revenus
  const expensesData = useMemo(() => {
    return filteredData.filter((ligne) => ligne.montant < 0);
  }, [filteredData]);

  const incomeData = useMemo(() => {
    return filteredData.filter((ligne) => ligne.montant > 0);
  }, [filteredData]);

  // Calcul des statistiques principales
  const stats = useMemo(() => {
    if (!filteredData || filteredData.length === 0) {
      return {
        totalExpenses: 0,
        totalIncome: 0,
        balance: 0,
        averageTransaction: 0,
        transactionCount: 0,
        largestExpense: { amount: 0, description: "" },
        largestIncome: { amount: 0, description: "" },
      };
    }

    const totalExpenses = expensesData.reduce(
      (sum, ligne) => sum + Math.abs(ligne.montant),
      0
    );
    const totalIncome = incomeData.reduce(
      (sum, ligne) => sum + ligne.montant,
      0
    );
    const balance = totalIncome - totalExpenses;

    // Trouver la transaction la plus importante (dépense et revenu)
    let largestExpense = { amount: 0, description: "" };
    let largestIncome = { amount: 0, description: "" };

    if (expensesData.length > 0) {
      const largest = expensesData.reduce((prev, current) =>
        Math.abs(prev.montant) > Math.abs(current.montant) ? prev : current
      );
      largestExpense = {
        amount: Math.abs(largest.montant),
        description:
          largest.libelleSimplifier || (largest.libelle as string) || "",
      };
    }

    if (incomeData.length > 0) {
      const largest = incomeData.reduce((prev, current) =>
        prev.montant > current.montant ? prev : current
      );
      largestIncome = {
        amount: largest.montant,
        description:
          largest.libelleSimplifier || (largest.libelle as string) || "",
      };
    }

    return {
      totalExpenses,
      totalIncome,
      balance,
      averageTransaction:
        filteredData.length > 0
          ? Math.abs(
              filteredData.reduce((sum, ligne) => sum + ligne.montant, 0)
            ) / filteredData.length
          : 0,
      transactionCount: filteredData.length,
      largestExpense,
      largestIncome,
    };
  }, [filteredData, expensesData, incomeData]);

  // Analyse des catégories
  const categoryAnalysis = useMemo(() => {
    if (!filteredData || filteredData.length === 0) return [];

    // Regrouper par catégorie
    const categories: { [key: string]: CategoryData } = {};
    const totalAmount = filteredData.reduce(
      (sum, ligne) => sum + Math.abs(ligne.montant),
      0
    );

    filteredData.forEach((ligne) => {
      const category =
        ligne.categorie || ligne.categoriePersonnel || "Non catégorisé";
      if (!categories[category as string]) {
        categories[category as string] = {
          name: category as string,
          amount: 0,
          percentage: 0,
          transactions: 0,
        };
      }
      categories[category as string].amount += Math.abs(ligne.montant);
      categories[category as string].transactions += 1;
    });

    // Calculer les pourcentages et trier
    return Object.values(categories)
      .map((cat, index) => ({
        ...cat,
        percentage: (cat.amount / totalAmount) * 100,
        color: COLORS[index % COLORS.length],
      }))
      .sort((a, b) => b.amount - a.amount);
  }, [filteredData]);

  // Analyser les tendances au fil du temps
  const timeAnalysis = useMemo(() => {
    if (!periodData || periodData.length === 0) return [];

    // Regrouper par mois
    const monthlyData: {
      [key: string]: { date: Date; expenses: number; income: number };
    } = {};

    periodData.forEach((ligne) => {
      const date = new Date(ligne.date);
      const monthKey = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}`;

      if (!monthlyData[monthKey]) {
        monthlyData[monthKey] = {
          date: new Date(date.getFullYear(), date.getMonth(), 1),
          expenses: 0,
          income: 0,
        };
      }

      if (ligne.montant < 0) {
        monthlyData[monthKey].expenses += Math.abs(ligne.montant);
      } else {
        monthlyData[monthKey].income += ligne.montant;
      }
    });

    return Object.values(monthlyData).sort(
      (a, b) => a.date.getTime() - b.date.getTime()
    );
  }, [periodData]);

  // Titre de la période
  const periodTitle = useMemo(() => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    switch (period) {
      case "month": {
        const date = new Date(currentYear, currentMonth + monthOffset, 1);
        return new Intl.DateTimeFormat("fr-FR", {
          month: "long",
          year: "numeric",
        }).format(date);
      }
      case "quarter": {
        const quarterStartMonth =
          Math.floor(currentMonth / 3) * 3 + monthOffset * 3;
        const quarterNumber = Math.floor(quarterStartMonth / 3) + 1;
        const yearOfQuarter =
          currentYear + Math.floor((currentMonth + monthOffset * 3) / 12);
        return `${quarterNumber}e trimestre ${yearOfQuarter}`;
      }
      case "year": {
        return `${currentYear + monthOffset}`;
      }
      default:
        return "Toutes les données";
    }
  }, [period, monthOffset]);

  // Gestion du changement de période
  const handlePeriodNavigation = (direction: "prev" | "next") => {
    if (direction === "prev") {
      setMonthOffset(monthOffset - 1);
    } else {
      // Ne pas permettre de naviguer au-delà de la période courante
      if (
        (period === "month" && monthOffset < 0) ||
        (period === "quarter" && monthOffset < 0) ||
        (period === "year" && monthOffset < 0) ||
        period === "all"
      ) {
        setMonthOffset(monthOffset + 1);
      }
    }
  };

  // Formater une date pour l'affichage
  // const formatDateForDisplay = (date: Date): string => {
  //   return new Intl.DateTimeFormat("fr-FR", {
  //     day: "2-digit",
  //     month: "long",
  //     year: "numeric",
  //   }).format(date);
  // };

  // Créer un graphique circulaire avec D3.js
  useEffect(() => {
    if (
      chartType !== "pie" ||
      !pieChartRef.current ||
      categoryAnalysis.length === 0
    )
      return;

    const width = pieChartRef.current.clientWidth;
    const height = 300;
    const radius = Math.min(width, height) / 2;

    // Nettoyer le contenu précédent
    d3.select(pieChartRef.current).selectAll("*").remove();

    // Créer le SVG
    const svg = d3
      .select(pieChartRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Limiter à 5 catégories + "Autres"
    let dataToUse = [...categoryAnalysis];
    if (dataToUse.length > 5) {
      const otherCategories = dataToUse.slice(5);
      const otherAmount = otherCategories.reduce(
        (sum, cat) => sum + cat.amount,
        0
      );
      const otherPercentage = otherCategories.reduce(
        (sum, cat) => sum + cat.percentage,
        0
      );
      const otherTransactions = otherCategories.reduce(
        (sum, cat) => sum + cat.transactions,
        0
      );

      dataToUse = dataToUse.slice(0, 5);
      dataToUse.push({
        name: "Autres",
        amount: otherAmount,
        percentage: otherPercentage,
        transactions: otherTransactions,
        color: COLORS[5],
      });
    }

    // Créer la fonction arc
    const arc = d3.arc().innerRadius(0).outerRadius(radius);

    // Créer la fonction pie
    const pie = d3
      .pie<CategoryData>()
      .value((d) => d.amount)
      .sort(null);

    // Ajouter les arcs
    const arcs = svg.selectAll("arc").data(pie(dataToUse)).enter().append("g");

    // Dessiner les paths
    arcs
      .append("path")
      .attr("d", arc as any)
      .attr("fill", (d, i) => d.data.color || COLORS[i % COLORS.length])
      .attr("stroke", "white")
      .style("stroke-width", "2px");

    // Ajouter les labels
    arcs
      .append("text")
      .attr("transform", (d) => {
        const centroid = arc.centroid(d as any);
        const x = centroid[0] * 1.4;
        const y = centroid[1] * 1.4;
        return `translate(${x}, ${y})`;
      })
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .attr("fill", "white")
      .text((d) =>
        d.data.percentage >= 5 ? `${d.data.percentage.toFixed(0)}%` : ""
      );

    // Ajouter une légende
    const legend = svg
      .selectAll(".legend")
      .data(dataToUse)
      .enter()
      .append("g")
      .attr("class", "legend")
      .attr(
        "transform",
        (d, i) => `translate(-${width / 2 - 10}, ${-height / 2 + 20 + i * 20})`
      );

    legend
      .append("rect")
      .attr("width", 10)
      .attr("height", 10)
      .attr("fill", (d, i) => d.color || COLORS[i % COLORS.length]);

    legend
      .append("text")
      .attr("x", 20)
      .attr("y", 10)
      .attr("font-size", "12px")
      .text(
        (d) => `${d.name.substring(0, 15)}${d.name.length > 15 ? "..." : ""}`
      );
  }, [chartType, categoryAnalysis]);

  // Créer un graphique en barres avec D3.js
  useEffect(() => {
    if (
      chartType !== "bar" ||
      !barChartRef.current ||
      timeAnalysis.length === 0
    )
      return;

    const margin = { top: 20, right: 30, bottom: 40, left: 60 };
    const width = barChartRef.current.clientWidth - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Nettoyer le contenu précédent
    d3.select(barChartRef.current).selectAll("*").remove();

    // Créer le SVG
    const svg = d3
      .select(barChartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Échelles
    const x = d3
      .scaleBand()
      .domain(timeAnalysis.map((d) => d.date.toISOString().substr(0, 7)))
      .range([0, width])
      .padding(0.3);

    const maxValue =
      d3.max(timeAnalysis, (d) => Math.max(d.income, d.expenses)) || 0;

    const y = d3
      .scaleLinear()
      .domain([0, maxValue * 1.1]) // 10% de marge en haut
      .range([height, 0]);

    // Axe X
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(
        d3.axisBottom(x).tickFormat((d) => {
          const date = new Date(d.toString());
          return date.toLocaleDateString("fr-FR", {
            month: "short",
            year: "2-digit",
          });
        })
      )
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    // Axe Y
    svg.append("g").call(
      d3
        .axisLeft(y)
        .ticks(5)
        .tickFormat((d) => `${(+d).toLocaleString()}€`)
    );

    // Barres pour les revenus
    svg
      .selectAll(".bar-income")
      .data(timeAnalysis)
      .enter()
      .append("rect")
      .attr("class", "bar-income")
      .attr("x", (d) => x(d.date.toISOString().substr(0, 7)) as number)
      .attr("width", x.bandwidth() / 2)
      .attr("y", (d) => y(d.income))
      .attr("height", (d) => height - y(d.income))
      .attr("fill", "#10b981") // Vert
      .on("mouseover", function (event, d) {
        d3.select(this).attr("opacity", 0.8);
        // Tooltip
      })
      .on("mouseout", function () {
        d3.select(this).attr("opacity", 1);
        // Hide tooltip
      });

    // Barres pour les dépenses
    svg
      .selectAll(".bar-expense")
      .data(timeAnalysis)
      .enter()
      .append("rect")
      .attr("class", "bar-expense")
      .attr(
        "x",
        (d) =>
          (x(d.date.toISOString().substr(0, 7)) as number) + x.bandwidth() / 2
      )
      .attr("width", x.bandwidth() / 2)
      .attr("y", (d) => y(d.expenses))
      .attr("height", (d) => height - y(d.expenses))
      .attr("fill", "#ef4444") // Rouge
      .on("mouseover", function (event, d) {
        d3.select(this).attr("opacity", 0.8);
        // Tooltip
      })
      .on("mouseout", function () {
        d3.select(this).attr("opacity", 1);
        // Hide tooltip
      });

    // Légende
    const legend = svg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "end")
      .selectAll("g")
      .data(["Revenus", "Dépenses"])
      .enter()
      .append("g")
      .attr("transform", (d, i) => `translate(0, ${i * 20})`);

    legend
      .append("rect")
      .attr("x", width - 19)
      .attr("width", 19)
      .attr("height", 19)
      .attr("fill", (d, i) => (i === 0 ? "#10b981" : "#ef4444"));

    legend
      .append("text")
      .attr("x", width - 24)
      .attr("y", 9.5)
      .attr("dy", "0.32em")
      .text((d) => d);
  }, [chartType, timeAnalysis]);

  // Créer un graphique linéaire avec D3.js
  useEffect(() => {
    if (
      chartType !== "line" ||
      !lineChartRef.current ||
      timeAnalysis.length === 0
    )
      return;

    const margin = { top: 20, right: 30, bottom: 50, left: 60 };
    const width = lineChartRef.current.clientWidth - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    // Nettoyer le contenu précédent
    d3.select(lineChartRef.current).selectAll("*").remove();

    // Créer le SVG
    const svg = d3
      .select(lineChartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Préparer les données pour le graphique d'évolution du solde
    const lineData = timeAnalysis.map((d) => ({
      date: d.date,
      solde: d.income - d.expenses,
    }));

    // Échelles
    const x = d3
      .scaleTime()
      .domain(d3.extent(lineData, (d) => d.date) as [Date, Date])
      .range([0, width]);

    const maxValue = d3.max(lineData, (d) => Math.abs(d.solde)) || 0;

    const y = d3
      .scaleLinear()
      .domain([-maxValue * 1.1, maxValue * 1.1]) // Symétrique avec 10% de marge
      .range([height, 0]);

    // Axe X
    svg
      .append("g")
      .attr("transform", `translate(0, ${y(0)})`) // Placer sur la ligne zéro
      .call(
        d3.axisBottom(x).tickFormat((d) => {
          const date = new Date(d as Date);
          return date.toLocaleDateString("fr-FR", {
            month: "short",
            year: "2-digit",
          });
        })
      )
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)");

    // Axe Y
    svg.append("g").call(
      d3
        .axisLeft(y)
        .ticks(5)
        .tickFormat((d) => `${(+d).toLocaleString()}€`)
    );

    // Ligne zéro (horizontale)
    svg
      .append("line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", y(0))
      .attr("y2", y(0))
      .attr("stroke", "#888")
      .attr("stroke-width", 1)
      .attr("stroke-dasharray", "3,3");

    // Ligne pour le solde
    const line = d3
      .line<{ date: Date; solde: number }>()
      .x((d) => x(d.date))
      .y((d) => y(d.solde));

    svg
      .append("path")
      .datum(lineData)
      .attr("fill", "none")
      .attr("stroke", "#3b82f6")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Points sur la ligne
    svg
      .selectAll("circle")
      .data(lineData)
      .enter()
      .append("circle")
      .attr("cx", (d) => x(d.date))
      .attr("cy", (d) => y(d.solde))
      .attr("r", 4)
      .attr("fill", (d) => (d.solde >= 0 ? "#10b981" : "#ef4444"));
  }, [chartType, timeAnalysis]);

  // Construire les graphiques HTML/CSS (pour les navigateurs qui ne supportent pas bien D3)
  const renderDaisyUICharts = () => {
    if (chartType === "pie" && categoryAnalysis.length > 0) {
      // Graphique circulaire simplifié avec DaisyUI (fallback)
      return (
        <div>
          <h3 className="text-lg font-semibold mb-4 text-center">
            Répartition par catégorie
          </h3>

          {/* Graphique D3.js */}
          <div ref={pieChartRef} className="w-full h-80"></div>

          {/* Fallback si D3 ne fonctionne pas */}
          <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-2">
            {categoryAnalysis.slice(0, 6).map((cat, idx) => (
              <div key={idx} className="bg-base-200 p-2 rounded">
                <div className="flex items-center">
                  <div
                    className="w-3 h-3 rounded-full mr-2"
                    style={{
                      backgroundColor: cat.color || COLORS[idx % COLORS.length],
                    }}
                  ></div>
                  <div className="text-sm font-medium truncate">{cat.name}</div>
                </div>
                <div className="mt-1 flex justify-between">
                  <span className="text-xs">{cat.percentage.toFixed(1)}%</span>
                  <span className="text-xs">
                    {cat.amount.toLocaleString()} €
                  </span>
                </div>
                <div className="w-full bg-base-300 rounded-full h-1.5 mt-1">
                  <div
                    className="h-1.5 rounded-full"
                    style={{
                      width: `${cat.percentage}%`,
                      backgroundColor: cat.color || COLORS[idx % COLORS.length],
                    }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else if (chartType === "bar" && timeAnalysis.length > 0) {
      // Graphique à barres simplifié avec DaisyUI (fallback)
      return (
        <div>
          <h3 className="text-lg font-semibold mb-4 text-center">
            Comparaison revenus/dépenses par période
          </h3>

          {/* Graphique D3.js */}
          <div ref={barChartRef} className="w-full h-80"></div>

          {/* Fallback si D3 ne fonctionne pas */}
          <div className="mt-4 overflow-x-auto">
            <table className="table w-full">
              <thead>
                <tr>
                  <th>Période</th>
                  <th>Revenus</th>
                  <th>Dépenses</th>
                  <th>Solde</th>
                </tr>
              </thead>
              <tbody>
                {timeAnalysis.map((data, idx) => (
                  <tr key={idx}>
                    <td>
                      {new Intl.DateTimeFormat("fr-FR", {
                        month: "long",
                        year: "numeric",
                      }).format(data.date)}
                    </td>
                    <td className="text-success">
                      {data.income.toLocaleString()} €
                    </td>
                    <td className="text-error">
                      {data.expenses.toLocaleString()} €
                    </td>
                    <td
                      className={
                        data.income - data.expenses >= 0
                          ? "text-success"
                          : "text-error"
                      }
                    >
                      {(data.income - data.expenses).toLocaleString()} €
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else if (chartType === "line" && timeAnalysis.length > 0) {
      // Graphique linéaire simplifié avec DaisyUI (fallback)
      return (
        <div>
          <h3 className="text-lg font-semibold mb-4 text-center">
            Évolution du solde dans le temps
          </h3>

          {/* Graphique D3.js */}
          <div ref={lineChartRef} className="w-full h-80"></div>

          {/* Fallback si D3 ne fonctionne pas */}
          <div className="mt-4 grid grid-cols-1 gap-2">
            {timeAnalysis.map((data, idx) => {
              const solde = data.income - data.expenses;
              const isPositive = solde >= 0;
              const width = Math.min(
                (Math.abs(solde) / stats.totalIncome) * 100,
                100
              );

              return (
                <div key={idx} className="flex items-center">
                  <div className="w-20 text-xs">
                    {new Intl.DateTimeFormat("fr-FR", {
                      month: "short",
                      year: "2-digit",
                    }).format(data.date)}
                  </div>
                  <div className="flex-1 h-8 bg-base-200 relative">
                    {/* Ligne zéro */}
                    <div className="absolute top-0 bottom-0 left-1/2 w-0.5 bg-base-300"></div>

                    {/* Barre de solde */}
                    <div
                      className={`absolute top-0 bottom-0 ${
                        isPositive ? "left-1/2" : "right-1/2"
                      } flex items-center`}
                      style={{ width: `${width / 2}%` }}
                    >
                      <div
                        className={`h-4 w-full ${
                          isPositive ? "bg-success" : "bg-error"
                        } rounded-sm`}
                      ></div>
                    </div>

                    {/* Valeur */}
                    <div
                      className={`absolute top-0 bottom-0 ${
                        isPositive ? "left-1/2 ml-2" : "right-1/2 mr-2"
                      } flex items-center`}
                    >
                      <span
                        className={`text-xs ${
                          isPositive ? "text-success" : "text-error"
                        }`}
                      >
                        {solde.toLocaleString()} €
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    } else if (chartType === "table") {
      // Le mode tableau est déjà implémenté
      return null;
    }

    return (
      <div className="text-center py-10">
        <p>Aucune donnée disponible pour cette période</p>
      </div>
    );
  };

  if (isLignesCompteLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="w-full bg-base-100 rounded-b-lg shadow-sm">
      {/* Barre d'outils d'analyse */}
      <div className="p-4 border-b border-base-300 flex flex-wrap items-center justify-between gap-4">
        <div className="btn-group">
          <button
            className={`btn btn-sm ${chartType === "pie" ? "btn-active" : ""}`}
            onClick={() => setChartType("pie")}
            title="Graphique circulaire"
          >
            <FaChartPie className="text-xs" />
          </button>
          <button
            className={`btn btn-sm ${chartType === "line" ? "btn-active" : ""}`}
            onClick={() => setChartType("line")}
            title="Graphique linéaire"
          >
            <FaChartLine className="text-xs" />
          </button>
          <button
            className={`btn btn-sm ${chartType === "bar" ? "btn-active" : ""}`}
            onClick={() => setChartType("bar")}
            title="Graphique à barres"
          >
            <FaChartBar className="text-xs" />
          </button>
          <button
            className={`btn btn-sm ${
              chartType === "table" ? "btn-active" : ""
            }`}
            onClick={() => setChartType("table")}
            title="Tableau"
          >
            <FaTable className="text-xs" />
          </button>
        </div>

        <div className="flex items-center gap-2">
          {/* Navigation temporelle */}
          <div className="flex items-center">
            <button
              className="btn btn-sm btn-ghost"
              onClick={() => handlePeriodNavigation("prev")}
              title="Période précédente"
            >
              &lt;
            </button>
            <span className="mx-2 text-sm font-medium">{periodTitle}</span>
            <button
              className="btn btn-sm btn-ghost"
              onClick={() => handlePeriodNavigation("next")}
              title="Période suivante"
              disabled={monthOffset >= 0}
            >
              &gt;
            </button>
          </div>

          {/* Sélecteur de période */}
          <div className="dropdown dropdown-end">
            <label
              tabIndex={0}
              className="btn btn-sm btn-outline flex items-center gap-1"
            >
              <FaCalendarAlt className="text-xs" />{" "}
              {period === "month"
                ? "Mois"
                : period === "quarter"
                ? "Trimestre"
                : period === "year"
                ? "Année"
                : "Tout"}
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-40"
            >
              <li>
                <a
                  onClick={() => {
                    setPeriod("month");
                    setMonthOffset(0);
                  }}
                >
                  Mois
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    setPeriod("quarter");
                    setMonthOffset(0);
                  }}
                >
                  Trimestre
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    setPeriod("year");
                    setMonthOffset(0);
                  }}
                >
                  Année
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    setPeriod("all");
                    setMonthOffset(0);
                  }}
                >
                  Tout
                </a>
              </li>
            </ul>
          </div>

          {/* Filtre par type */}
          <div className="dropdown dropdown-end">
            <label
              tabIndex={0}
              className="btn btn-sm btn-outline flex items-center gap-1"
            >
              <FaFilter className="text-xs" />
              {filterType === "all"
                ? "Tout"
                : filterType === "expenses"
                ? "Dépenses"
                : "Revenus"}
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <a onClick={() => setFilterType("all")}>Tous</a>
              </li>
              <li>
                <a onClick={() => setFilterType("expenses")}>
                  Dépenses uniquement
                </a>
              </li>
              <li>
                <a onClick={() => setFilterType("income")}>
                  Revenus uniquement
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Contenu principal de la vue d'analyse */}
      <div className="p-4">
        {/* Résumé statistique */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
          <div className="stat bg-base-200 rounded-lg shadow-sm">
            <div className="stat-title">Revenus</div>
            <div className="stat-value text-success">
              {`${stats.totalIncome.toLocaleString()} €`}
            </div>
            <div className="stat-desc">
              Transactions: {incomeData.length} | Moy:{" "}
              {incomeData.length > 0
                ? `${(
                    stats.totalIncome / incomeData.length
                  ).toLocaleString()} €`
                : "0 €"}
            </div>
          </div>

          <div className="stat bg-base-200 rounded-lg shadow-sm">
            <div className="stat-title">Dépenses</div>
            <div className="stat-value text-error">
              {`${stats.totalExpenses.toLocaleString()} €`}
            </div>
            <div className="stat-desc">
              Transactions: {expensesData.length} | Moy:{" "}
              {expensesData.length > 0
                ? `${(
                    stats.totalExpenses / expensesData.length
                  ).toLocaleString()} €`
                : "0 €"}
            </div>
          </div>

          <div className="stat bg-base-200 rounded-lg shadow-sm">
            <div className="stat-title">Solde</div>
            <div
              className={`stat-value ${
                stats.balance >= 0 ? "text-success" : "text-error"
              }`}
            >
              {`${stats.balance.toLocaleString()} €`}
            </div>
            <div className="stat-desc">
              {stats.balance >= 0 ? "Excédent" : "Déficit"}
              {` | Ratio: ${Math.round(
                (stats.totalExpenses / stats.totalIncome) * 100
              )}%`}
            </div>
          </div>
        </div>

        {/* Transactions importantes */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
          {stats.largestExpense.amount > 0 && (
            <div className="bg-base-200 rounded-lg shadow-sm p-4">
              <h3 className="text-md font-semibold mb-2">
                Plus grande dépense
              </h3>
              <p className="text-lg font-bold text-error">
                {`${stats.largestExpense.amount.toLocaleString()} €`}
              </p>
              <p className="text-sm opacity-70">
                {stats.largestExpense.description}
              </p>
            </div>
          )}

          {stats.largestIncome.amount > 0 && (
            <div className="bg-base-200 rounded-lg shadow-sm p-4">
              <h3 className="text-md font-semibold mb-2">Plus grand revenu</h3>
              <p className="text-lg font-bold text-success">
                {`${stats.largestIncome.amount.toLocaleString()} €`}
              </p>
              <p className="text-sm opacity-70">
                {stats.largestIncome.description}
              </p>
            </div>
          )}
        </div>

        {/* Zone d'affichage selon le type de graphique */}
        <div className="bg-base-200 rounded-lg shadow-sm p-6 min-h-80">
          {/* Graphique en fonction du type sélectionné */}
          {filteredData.length === 0 ? (
            <div className="text-center py-10">
              <p>Aucune donnée disponible pour cette période</p>
            </div>
          ) : chartType === "table" ? (
            /* Tableau des catégories */
            <div className="overflow-x-auto">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th>Catégorie</th>
                    <th>Montant</th>
                    <th>%</th>
                    <th>Transactions</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryAnalysis.map((category, index) => (
                    <tr key={index}>
                      <td>{category.name}</td>
                      <td className="text-right">
                        {`${category.amount.toLocaleString()} €`}
                      </td>
                      <td className="text-right">
                        {category.percentage.toFixed(1)}%
                      </td>
                      <td className="text-center">{category.transactions}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            renderDaisyUICharts()
          )}
        </div>
      </div>
    </div>
  );
};
