import React, { useState } from "react";
import { RowData } from "@/pages/Bank/types.ts";
import {
  Filter,
  FilterOperator,
} from "@/pages/Bank/components/Table/TableMain";
import { FaFilter, FaPlus, FaTimes } from "react-icons/fa";

interface FilterPanelProps {
  filters: Filter[];
  onAddFilter: (filter: Filter) => void;
  onUpdateFilter?: (index: number, filter: Filter) => void;
  onRemoveFilter: (index: number) => void;
  onClearFilters: () => void;
  visibleColumns: { [key: string]: boolean };
  columnLabels: { [key: string]: string };
}

const OPERATOR_LABELS: Record<FilterOperator, string> = {
  contains: "Contient",
  equals: "Égal à",
  startsWith: "Commence par",
  endsWith: "Termine par",
  greaterThan: "Plus grand que",
  lessThan: "Plus petit que",
  between: "Entre",
};

// Aide pour déterminer quels opérateurs sont disponibles pour chaque type de colonne
const getAvailableOperators = (columnName: string): FilterOperator[] => {
  // Pour les colonnes monétaires et dates
  if (["montant", "debit", "credit", "solde"].includes(columnName)) {
    return ["equals", "greaterThan", "lessThan", "between"];
  }

  // Pour les colonnes textuelles
  if (
    [
      "libelleSimplifier",
      "categorie",
      "sousCategorie",
      "precision",
      "libellerOperation",
    ].includes(columnName)
  ) {
    return ["contains", "equals", "startsWith", "endsWith"];
  }

  // Pour les dates
  if (["date", "dateValeur", "dateOperation"].includes(columnName)) {
    return ["equals", "greaterThan", "lessThan", "between"];
  }

  // Par défaut
  return ["contains", "equals"];
};

export const FilterPanel: React.FC<FilterPanelProps> = ({
  filters,
  onAddFilter,
  // onUpdateFilter,
  onRemoveFilter,
  onClearFilters,
  visibleColumns,
  columnLabels,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newFilter, setNewFilter] = useState<Filter>({
    column: "libelleSimplifier" as keyof RowData,
    operator: "contains",
    value: "",
  });

  // Récupérer les colonnes visibles
  const visibleColumnKeys = Object.entries(visibleColumns)
    .filter(([, isVisible]) => isVisible)
    .map(([key]) => key as keyof RowData);

  const handleAddNewFilter = () => {
    if (newFilter.value) {
      onAddFilter(newFilter);
      setNewFilter({
        column: "libelleSimplifier" as keyof RowData,
        operator: "contains",
        value: "",
      });
    }
  };

  const handleOperatorChange = (operator: FilterOperator) => {
    setNewFilter((prev) => ({
      ...prev,
      operator,
      // Réinitialiser secondValue si on ne sélectionne pas "between"
      secondValue: operator === "between" ? prev.secondValue : undefined,
    }));
  };

  return (
    <div className="relative">
      <button
        className={`btn btn-sm ${
          filters.length > 0 ? "btn-primary" : "btn-outline"
        } flex items-center gap-1`}
        onClick={() => setIsOpen(!isOpen)}
        title={
          filters.length > 0
            ? `${filters.length} filtres actifs`
            : "Filtrer les données"
        }
      >
        <FaFilter size={12} />
        {filters.length > 0 ? `Filtres (${filters.length})` : "Filtrer"}
      </button>

      {isOpen && (
        <div className="absolute top-full mt-2 right-0 z-50 bg-base-100 shadow-lg rounded-lg p-4 min-w-96 border border-base-300">
          <div className="flex justify-between items-center mb-4">
            <h3 className="font-bold text-lg">Filtres</h3>
            <button
              className="btn btn-sm btn-ghost"
              onClick={() => setIsOpen(false)}
            >
              <FaTimes size={14} />
            </button>
          </div>

          {/* Liste des filtres actifs */}
          {filters.length > 0 ? (
            <div className="mb-4 space-y-2">
              {filters.map((filter, index) => (
                <div
                  key={index}
                  className="flex items-center gap-2 p-2 bg-base-200 rounded-md"
                >
                  <span className="font-medium">
                    {columnLabels[filter.column as string] || filter.column}
                  </span>
                  <span className="text-sm text-base-content/70">
                    {OPERATOR_LABELS[filter.operator]}
                  </span>
                  <span className="font-semibold">
                    {filter.value}
                    {filter.operator === "between" && filter.secondValue && (
                      <> et {filter.secondValue}</>
                    )}
                  </span>
                  <button
                    className="btn btn-xs btn-ghost ml-auto"
                    onClick={() => onRemoveFilter(index)}
                    title="Supprimer ce filtre"
                  >
                    <FaTimes size={10} />
                  </button>
                </div>
              ))}
              <button
                className="btn btn-xs btn-outline mt-2 w-full"
                onClick={onClearFilters}
              >
                Effacer tous les filtres
              </button>
            </div>
          ) : (
            <div className="mb-4 text-center text-base-content/70">
              Aucun filtre actif
            </div>
          )}

          {/* Création d'un nouveau filtre */}
          <div className="space-y-3 p-3 border border-base-300 rounded-md">
            <h4 className="font-medium">Ajouter un filtre</h4>

            <div className="grid grid-cols-2 gap-2">
              {/* Sélection de la colonne */}
              <div>
                <label className="text-xs text-base-content/70">Colonne</label>
                <select
                  className="select select-sm select-bordered w-full"
                  value={newFilter.column as string}
                  onChange={(e) =>
                    setNewFilter((prev) => ({
                      ...prev,
                      column: e.target.value as keyof RowData,
                      operator: getAvailableOperators(e.target.value)[0],
                    }))
                  }
                >
                  {visibleColumnKeys.map((column) => (
                    <option key={column as string} value={column as string}>
                      {columnLabels[column as string] || column}
                    </option>
                  ))}
                </select>
              </div>

              {/* Sélection de l'opérateur */}
              <div>
                <label className="text-xs text-base-content/70">
                  Opérateur
                </label>
                <select
                  className="select select-sm select-bordered w-full"
                  value={newFilter.operator}
                  onChange={(e) =>
                    handleOperatorChange(e.target.value as FilterOperator)
                  }
                >
                  {getAvailableOperators(newFilter.column as string).map(
                    (op) => (
                      <option key={op} value={op}>
                        {OPERATOR_LABELS[op]}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>

            {/* Champ(s) de valeur */}
            <div
              className={
                newFilter.operator === "between" ? "grid grid-cols-2 gap-2" : ""
              }
            >
              <div>
                <label className="text-xs text-base-content/70">
                  {newFilter.operator === "between" ? "Valeur min" : "Valeur"}
                </label>
                <input
                  type={
                    ["montant", "debit", "credit", "solde"].includes(
                      newFilter.column as string
                    )
                      ? "number"
                      : "text"
                  }
                  className="input input-sm input-bordered w-full"
                  value={newFilter.value as string}
                  onChange={(e) =>
                    setNewFilter((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                  placeholder="Saisir une valeur..."
                />
              </div>

              {newFilter.operator === "between" && (
                <div>
                  <label className="text-xs text-base-content/70">
                    Valeur max
                  </label>
                  <input
                    type={
                      ["montant", "debit", "credit", "solde"].includes(
                        newFilter.column as string
                      )
                        ? "number"
                        : "text"
                    }
                    className="input input-sm input-bordered w-full"
                    value={(newFilter.secondValue as string) || ""}
                    onChange={(e) =>
                      setNewFilter((prev) => ({
                        ...prev,
                        secondValue: e.target.value,
                      }))
                    }
                    placeholder="Valeur maximale..."
                  />
                </div>
              )}
            </div>

            {/* Bouton d'ajout */}
            <button
              className="btn btn-sm btn-primary w-full mt-2"
              onClick={handleAddNewFilter}
              disabled={!newFilter.value}
            >
              <FaPlus size={12} className="mr-1" /> Ajouter ce filtre
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
