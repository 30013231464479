import { Link } from "@tanstack/react-router";
import { ArrowLeft, Home, Search } from "lucide-react";

const NotFoundPage = () => {
  return (
    <div className="flex justify-center">
      <div className="card w-full max-w-lg bg-base-100 shadow-xl">
        <div className="card-body items-center text-center">
          {/* Numéro d'erreur stylisé */}
          <div className="text-9xl font-bold opacity-10 absolute top-6">
            404
          </div>

          {/* Icône et messages */}
          <div className="mt-4 relative z-10">
            <div className="avatar404 placeholder mb-6">
              <div className="bg-neutral text-neutral-content rounded-full w-48">
                <span className="text-2xl">404</span>
              </div>
            </div>

            <h1 className="text-3xl font-bold mb-2">Page introuvable</h1>
            <p className="mb-6 text-base-content/70">
              La page que tu cherches n'existe pas ou a été déplacée.
            </p>

            {/* Suggestions */}
            <div className="card bg-base-200 p-4 mb-6 w-full">
              <p className="text-sm mb-3">Essaie l'une de ces options :</p>
              <ul className="text-sm text-left space-y-2">
                <li>• Vérifie l'URL pour t'assurer qu'elle est correcte</li>
                <li>• Retourne à la page d'accueil</li>
                <li>
                  • Contacte l'administrateur si tu penses que c'est une erreur
                </li>
              </ul>
            </div>

            {/* Actions */}
            <div className="flex flex-col md:flex-row gap-3 justify-center">
              <Link to="/" className="btn btn-primary gap-2">
                <Home size={18} />
                Retour à l'accueil
              </Link>

              <button
                onClick={() => window.history.back()}
                className="btn btn-outline gap-2"
              >
                <ArrowLeft size={18} />
                Page précédente
              </button>
            </div>
          </div>

          {/* Footer */}
          <div className="divider mt-8"></div>
          <div className="flex justify-between w-full text-sm opacity-70">
            <span>YaCadMander</span>
            <a href="#" className="flex items-center gap-1 hover:underline">
              <Search size={14} />
              Chercher sur le site
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
