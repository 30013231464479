// providers/AuthProvider.tsx - MODIFIÉ
import React, { createContext, ReactNode, useContext } from "react";
import { useAuth } from "@/hooks/useAuth";
import { UserType } from "@/utils/userTypes";
import { Family } from "@/store/auth/authSlice";

interface AuthContextType {
  user: UserType | null;
  family: Family | null;
  isLoading: boolean;
  isAuthenticated: boolean;
  error: string | null;
  login: (credentials: { login: string; password: string }) => Promise<any>;
  register: (userData: any) => Promise<any>;
  logout: () => void;
  loginWithGoogle: (credential: string) => Promise<any>;
  updateProfile: (userId: string, data: FormData) => Promise<any>;
  getFamilyDetails: () => Promise<any>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const {
    user,
    isLoading,
    error,
    family,
    isAuthenticated,
    loginUser,
    registerUser,
    logoutUser,
    loginWithGoogle,
    updateUserProfile,
    getFamilyDetails,
  } = useAuth();

  const value = {
    user,
    family,
    isLoading,
    isAuthenticated,
    error,
    login: loginUser,
    register: registerUser,
    logout: logoutUser,
    loginWithGoogle,
    updateProfile: updateUserProfile,
    getFamilyDetails,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};
