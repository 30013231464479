import { useState } from "react";
import { Category, Modificateur } from "@/pages/Bank/types.ts";
import { useCategories } from "../hooks/useCategories";
import { CategoryForm } from "./CategoryForm";
import { CategoryList } from "./CategoryList";
import { getUserLogged } from "@/utils/axios/userUtils.ts";
import {
  getDefaultCategories,
  getDefaultCategoryRelations,
} from "./data/defaultCategories";
import { FolderPlus } from "lucide-react";

export const CategoryMain = () => {
  // État du formulaire
  const [categoryFormValue, setCategoryFormValue] = useState<Partial<Category>>(
    {
      label: "",
      archived: false,
      disabled: false,
      owner: getUserLogged()?.family,
    }
  );

  // État pour contrôler si le formulaire est ouvert ou fermé
  const [isFormOpen, setIsFormOpen] = useState(false);

  // Utiliser le hook personnalisé pour gérer les catégories
  const {
    categories,
    mainCategories,
    isLoading,
    isError,
    addCategory,
    updateCategory,
    removeCategory,
    getSubCategories,
  } = useCategories();

  // Création des catégories par défaut
  const [isCreatingDefaults, setIsCreatingDefaults] = useState(false);

  // Gestion du formulaire
  const handleFormValueChange = (category: Category) => {
    setCategoryFormValue(category);
    setIsFormOpen(true);
  };

  // Création d'une nouvelle catégorie
  const handleAddNewCategory = () => {
    setCategoryFormValue({
      label: "",
      archived: false,
      disabled: false,
      owner: getUserLogged()?._id,
    });
    setIsFormOpen(true);
  };

  // Soumission du formulaire
  const handleSubmitCategory = async (categoryData: Partial<Category>) => {
    if (categoryData._id) {
      // Mise à jour d'une catégorie existante
      await updateCategory(categoryData);
    } else {
      // Création d'une nouvelle catégorie
      await addCategory(categoryData);
    }
    setIsFormOpen(false);
  };

  // Modification de l'état d'une catégorie (archivée/désactivée)
  const handleModifyCategory = async (
    categoryId: string,
    modificateur: Modificateur
  ) => {
    const categoryToModify = categories.find((c) => c._id === categoryId);
    if (!categoryToModify) return;

    const updatedCategory = {
      ...categoryToModify,
      [modificateur.status]: modificateur.state,
    };

    await updateCategory(updatedCategory);
  };

  // Suppression d'une catégorie
  const handleDeleteCategory = async (categoryId: string) => {
    await removeCategory(categoryId);
  };

  // Création des catégories par défaut
  const handleCreateDefaultCategories = async () => {
    if (isCreatingDefaults) return;

    try {
      setIsCreatingDefaults(true);

      // Obtenir les catégories par défaut
      const defaultCategories = getDefaultCategories();

      // Créer toutes les catégories
      const createdCategories: Category[] = [];

      for (const category of defaultCategories) {
        try {
          const response = await addCategory(category);
          if (response.data) {
            createdCategories.push(response.data);
          }
        } catch (error) {
          console.error("Erreur lors de la création de la catégorie:", error);
        }
      }

      // Établir les relations parent-enfant
      const relations = getDefaultCategoryRelations(createdCategories);

      for (const relation of relations) {
        try {
          const childCategory = createdCategories.find(
            (c) => c._id === relation.childId
          );
          if (childCategory) {
            const parentCategory = createdCategories.find(
              (c) => c._id === relation.parentId
            );
            await updateCategory({
              ...childCategory,
              parent: parentCategory,
            });
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour de la relation:", error);
        }
      }
    } catch (error) {
      console.error(
        "Erreur lors de la création des catégories par défaut:",
        error
      );
    } finally {
      setIsCreatingDefaults(false);
    }
  };

  // Affichage d'une erreur si nécessaire
  if (isError) {
    return (
      <div className="alert alert-error">
        <span>Erreur lors du chargement des catégories</span>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Section formulaire */}
      <div className="collapse collapse-arrow bg-base-100 border-base-300 border">
        <input
          type="checkbox"
          checked={isFormOpen}
          onChange={() => setIsFormOpen(!isFormOpen)}
          className="min-h-[3rem]"
        />
        <div className="collapse-title font-semibold text-2xl">
          {categoryFormValue._id
            ? "Modifier une catégorie"
            : "Créer une catégorie"}
        </div>
        <div className="collapse-content">
          {isFormOpen && (
            <CategoryForm
              formData={categoryFormValue}
              setFormData={setCategoryFormValue}
              categories={categories}
              onSuccess={() => setIsFormOpen(false)}
              onCancel={() => setIsFormOpen(false)}
              onSubmit={handleSubmitCategory}
            />
          )}
        </div>
      </div>

      {/* Actions */}
      {mainCategories.length === 0 && (
        <div className="alert">
          <div>
            <span className="font-medium">Aucune catégorie disponible</span>
            <p className="text-sm mt-1">
              Vous pouvez créer des catégories personnalisées ou utiliser notre
              configuration par défaut.
            </p>
          </div>
          <div className="flex-none">
            <button
              className="btn btn-sm btn-primary"
              onClick={handleCreateDefaultCategories}
              disabled={isCreatingDefaults}
            >
              {isCreatingDefaults ? (
                <>
                  <span className="loading loading-spinner loading-xs"></span>
                  Création en cours...
                </>
              ) : (
                <>
                  <FolderPlus size={16} />
                  Créer les catégories par défaut
                </>
              )}
            </button>
          </div>
        </div>
      )}

      {/* Liste des catégories */}
      <CategoryList
        mainCategories={mainCategories}
        loading={isLoading}
        onEdit={handleFormValueChange}
        onDelete={handleDeleteCategory}
        onModify={handleModifyCategory}
        onAddNew={handleAddNewCategory}
        getSubCategories={getSubCategories}
      />
    </div>
  );
};
