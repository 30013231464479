import { useCallback, useEffect, useState } from "react";
import { Category } from "@/pages/Bank/types.ts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createCategoryForUser,
  deleteCategoryForUser,
  getCategoriesForUser,
  updateCategoryForUser,
} from "@/utils/axios/bankUtils.ts";
import { AxiosResponse } from "axios";
import { showToast } from "@/components/tools/toast.ts";

interface UseCategoriesReturn {
  // Données
  categories: Category[];
  mainCategories: Category[];
  isLoading: boolean;
  isError: boolean;

  // Actions CRUD
  addCategory: (category: Partial<Category>) => Promise<AxiosResponse>;
  updateCategory: (category: Partial<Category>) => Promise<AxiosResponse>;
  removeCategory: (categoryId: string) => Promise<AxiosResponse>;

  // Utilitaires
  getSubCategories: (categoryId: string) => Category[];
  getCategoryById: (categoryId: string) => Category | undefined;
}

export const useCategories = (): UseCategoriesReturn => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [mainCategories, setMainCategories] = useState<Category[]>([]);

  const queryClient = useQueryClient();

  // Requête pour récupérer les catégories
  const {
    data: categoriesData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategoriesForUser,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Mise à jour des catégories quand les données changent
  useEffect(() => {
    if (categoriesData) {
      setCategories(categoriesData);

      // Filtrer les catégories principales (sans parent)
      const mainCats = categoriesData.filter((cat) => !cat.parent);
      setMainCategories(mainCats);
    }
  }, [categoriesData]);

  // Créer une catégorie
  const addCategoryMutation = useMutation({
    mutationFn: createCategoryForUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      showToast("success", "Catégorie créée avec succès");
    },
    onError: (error: Error) => {
      showToast("error", error.message);
    },
  });

  // Mettre à jour une catégorie
  const updateCategoryMutation = useMutation({
    mutationFn: updateCategoryForUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      showToast("success", "Catégorie mise à jour avec succès");
    },
    onError: (error: Error) => {
      showToast("error", error.message);
    },
  });

  // Supprimer une catégorie
  const deleteCategoryMutation = useMutation({
    mutationFn: deleteCategoryForUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      showToast("success", "Catégorie supprimée avec succès");
    },
    onError: (error: Error) => {
      showToast("error", error.message);
    },
  });

  // Actions CRUD
  const addCategory = async (category: Partial<Category>) => {
    return await addCategoryMutation.mutateAsync(category);
  };

  const updateCategory = async (category: Partial<Category>) => {
    return await updateCategoryMutation.mutateAsync(category);
  };

  const removeCategory = async (categoryId: string) => {
    return await deleteCategoryMutation.mutateAsync(categoryId);
  };

  // Utilitaires
  const getSubCategories = useCallback(
    (categoryId: string) => {
      if (!categories || categories.length === 0) return [];
      return categories.filter(
        (cat) => cat.parent && cat.parent._id === categoryId
      );
    },
    [categories]
  );

  const getCategoryById = useCallback(
    (categoryId: string) => {
      if (!categories || categories.length === 0) return undefined;
      return categories.find((cat) => cat._id === categoryId);
    },
    [categories]
  );

  return {
    categories,
    mainCategories,
    isLoading,
    isError,

    addCategory,
    updateCategory,
    removeCategory,

    getSubCategories,
    getCategoryById,
  };
};
