import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Category } from "@/pages/Bank/types.ts";
import { getUserLogged } from "@/utils/axios/userUtils.ts";
import { Check, ChevronDown, Folder, FolderPlus, Tag, X } from "lucide-react";
import { IconSelector } from "@/pages/Bank/components/Category/components/IconSelector.tsx";
import { availableIcons } from "@/pages/Bank/utils.ts";

interface CategoryFormProps {
  formData: Partial<Category>;
  setFormData: Dispatch<SetStateAction<Partial<Category>>>;
  categories: Category[];
  onSuccess?: () => void;
  onCancel?: () => void;
  onSubmit: (category: Partial<Category>) => Promise<any>;
}

export const CategoryForm = ({
  formData,
  setFormData,
  categories,
  onSuccess = () => {},
  onCancel,
  onSubmit,
}: CategoryFormProps) => {
  const isEditMode = !!formData._id;

  // S'assurer que le owner est toujours initialisé
  useEffect(() => {
    if (!formData.owner) {
      setFormData((prev) => ({
        ...prev,
        owner: getUserLogged()?.family,
        archived: prev.archived || false,
        disabled: prev.disabled || false,
      }));
    }
  }, [formData.owner, setFormData]);

  // Gestion des changements de champs
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Gestion des changements de champs booléens
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  // Soumission du formulaire
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await onSubmit(formData);
      onSuccess();
      // Reset du formulaire
      setFormData({
        label: "",
        archived: false,
        disabled: false,
        owner: getUserLogged()?.family,
      });
    } catch (error) {
      console.error("Erreur lors de la soumission:", error);
    }
  };

  // Réinitialisation du formulaire
  const handleReset = (e: React.FormEvent) => {
    e.preventDefault();
    setFormData({
      label: "",
      archived: false,
      disabled: false,
      owner: getUserLogged()?.family,
    });
    if (onCancel) onCancel();
  };

  // Filtrer les catégories principales (celles qui n'ont pas de parent)
  const mainCategories = categories.filter((cat) => !cat.parent);

  return (
    <div className="p-3 bg-base-100 rounded-lg shadow-sm border border-base-300">
      <h3 className="text-lg font-bold mb-3 flex items-center gap-2">
        <FolderPlus className="text-primary" />
        {isEditMode ? "Modifier la catégorie" : "Créer une nouvelle catégorie"}
      </h3>

      <form onSubmit={handleSubmit} onReset={handleReset} className="space-y-2">
        {/* Nom de la catégorie */}
        <div className="form-control">
          <label className="label py-1">
            <span className="label-text flex items-center gap-1">
              <Tag size={16} />
              Nom de la catégorie *
            </span>
          </label>
          <input
            type="text"
            name="label"
            value={formData.label || ""}
            onChange={handleInputChange}
            className="input input-bordered input-sm h-9 w-full"
            placeholder="Ex: Alimentation, Transport, Loisirs..."
            required
          />
        </div>

        {/* Icône */}
        <div className="form-control">
          <label className="label py-1">
            <span className="label-text flex items-center gap-1">
              <Tag size={16} />
              Icône
            </span>
            <span className="label-text-alt text-xs text-gray-500">
              Optionnel
            </span>
          </label>
          <IconSelector
            selectedIcon={formData.icon}
            onChange={(iconName) =>
              setFormData((prev) => ({ ...prev, icon: iconName }))
            }
            availableIcons={availableIcons}
          />
        </div>

        {/* Catégorie parent */}
        <div className="form-control">
          <label className="label py-1">
            <span className="label-text flex items-center gap-1">
              <Folder size={16} />
              Catégorie parent
            </span>
            <span className="label-text-alt text-xs text-gray-500">
              Optionnel
            </span>
          </label>
          <div className="relative">
            <select
              name="parent"
              value={formData.parent?._id || ""}
              onChange={(e) => {
                const parentId = e.target.value;
                if (!parentId) {
                  setFormData((prev) => ({ ...prev, parent: undefined }));
                } else {
                  const selectedParent = categories.find(
                    (c) => c._id === parentId
                  );
                  setFormData((prev) => ({ ...prev, parent: selectedParent }));
                }
              }}
              className="select select-bordered select-sm w-full h-9 pr-10"
            >
              <option value="">Aucun (catégorie principale)</option>
              {mainCategories.map((cat) => (
                <option
                  key={cat._id}
                  value={cat._id}
                  disabled={cat._id === formData._id} // Empêcher de sélectionner soi-même comme parent
                >
                  {cat.label}
                </option>
              ))}
            </select>
            <ChevronDown
              size={16}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-gray-500"
            />
          </div>
        </div>

        {/* Options */}
        <div className="flex gap-4 mt-2">
          <div className="form-control">
            <label className="cursor-pointer label py-1 justify-start gap-2">
              <input
                type="checkbox"
                name="archived"
                checked={formData.archived || false}
                onChange={handleCheckboxChange}
                className="checkbox checkbox-xs checkbox-primary"
              />
              <span className="label-text">Archivée</span>
            </label>
          </div>

          <div className="form-control">
            <label className="cursor-pointer label py-1 justify-start gap-2">
              <input
                type="checkbox"
                name="disabled"
                checked={formData.disabled || false}
                onChange={handleCheckboxChange}
                className="checkbox checkbox-xs checkbox-primary"
              />
              <span className="label-text">Désactivée</span>
            </label>
          </div>
        </div>

        {/* Boutons d'action */}
        <div className="flex justify-end gap-2 pt-2">
          <button type="reset" className="btn btn-sm btn-outline btn-error">
            <X size={16} />
            Annuler
          </button>
          <button type="submit" className="btn btn-sm btn-primary">
            <Check size={16} />
            {isEditMode ? "Modifier" : "Créer"}
          </button>
        </div>
      </form>
    </div>
  );
};
