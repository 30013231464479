import { ReactNode } from "react";
import { CheckedCellList, RowData } from "@/pages/Bank/types.ts";

interface ExportButtonProps {
  data: RowData[];
  selectedCell: CheckedCellList;
  exportMode: boolean;
  toggleExportMode: () => void;
  buttonClassName?: string;
  buttonContent?: ReactNode;
}

export const ExportButton = ({
  data,
  selectedCell,
  exportMode,
  toggleExportMode,
  buttonClassName,
  buttonContent,
}: ExportButtonProps) => {
  // Fonction pour exporter les données au format CSV
  const exportToCSV = () => {
    // Filtre les lignes sélectionnées si en mode export
    const rowsToExport = exportMode
      ? data.filter((row) => row._id && selectedCell[row._id])
      : data;

    if (rowsToExport.length === 0) {
      alert("Aucune ligne sélectionnée pour l'export");
      return;
    }

    // Récupère les en-têtes des colonnes depuis la première ligne
    const headers = Object.keys(rowsToExport[0]).filter(
      // Exclus certaines propriétés si nécessaire
      (header) => header !== "_id" && header !== "__v"
    );

    // Crée le contenu CSV
    const csvContent =
      headers.join(";") +
      "\n" +
      rowsToExport
        .map((row) => {
          return headers
            .map((header) => {
              // Formatage selon le type de données
              const value = row[header];
              if (value === null || value === undefined) return "";
              if (typeof value === "string")
                return `"${value.replace(/"/g, '""')}"`;
              return value;
            })
            .join(";");
        })
        .join("\n");

    // Crée un blob et un lien de téléchargement
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "export_compte.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex items-center space-x-2">
      <button
        className={
          buttonClassName ||
          `btn btn-sm ${exportMode ? "btn-primary" : "btn-outline"}`
        }
        onClick={toggleExportMode}
      >
        {buttonContent || (exportMode ? "Mode export actif" : "Mode export")}
      </button>

      <button className="btn btn-sm btn-outline" onClick={exportToCSV}>
        Exporter CSV
      </button>
    </div>
  );
};
