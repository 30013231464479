import { createFileRoute } from "@tanstack/react-router";
import { TodosPage } from "@/pages/TodosPage.tsx";

export const Route = createFileRoute("/todos")({
  component: RouteComponent,
  loader: () => <div className="text-6xl text-cyan-700">LOADING</div>,
});

function RouteComponent() {
  return <TodosPage />;
}
