import { useEffect, useRef, useState } from "react";
import { Search, X } from "lucide-react";
import { getIconComponent } from "@/pages/Bank/utils.ts";

interface IconSelectorProps {
  selectedIcon: string | undefined;
  onChange: (iconName: string | undefined) => void;
  availableIcons: string[];
}

export const IconSelector = ({
  selectedIcon,
  onChange,
  availableIcons,
}: IconSelectorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Fermer le dropdown quand on clique en dehors
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filtrer les icônes selon la recherche
  const filteredIcons = availableIcons.filter((icon) =>
    icon.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Rendre l'icône sélectionnée
  const renderSelectedIcon = () => {
    if (!selectedIcon) return null;

    const IconComponent = getIconComponent(selectedIcon);
    return IconComponent ? (
      <div className="flex items-center gap-2 px-2">
        <IconComponent size={20} className="text-primary" />
        <span>{selectedIcon}</span>
        <button
          className="ml-auto text-gray-500 hover:text-gray-700"
          onClick={(e) => {
            e.stopPropagation();
            onChange(undefined);
          }}
        >
          <X size={14} />
        </button>
      </div>
    ) : (
      <span className="text-gray-500">Icône non trouvée</span>
    );
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {/* Bouton de sélection */}
      <div
        className="input input-bordered input-sm h-9 flex items-center cursor-pointer w-full"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedIcon ? (
          renderSelectedIcon()
        ) : (
          <span className="text-gray-400 px-2">Choisir une icône</span>
        )}
      </div>

      {/* Dropdown de sélection */}
      {isOpen && (
        <div className="absolute mt-1 z-50 bg-base-100 border border-base-300 rounded-md shadow-lg w-full max-w-md">
          {/* Barre de recherche */}
          <div className="p-2 border-b border-base-200 relative">
            <input
              type="text"
              className="input input-sm input-bordered w-full pl-8"
              placeholder="Rechercher une icône..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
            <Search
              size={16}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"
            />
          </div>

          {/* Liste des icônes */}
          <div className="max-h-60 overflow-y-auto p-2 grid grid-cols-3 sm:grid-cols-4 gap-2">
            {filteredIcons.length === 0 ? (
              <div className="col-span-full text-center py-4 text-gray-500">
                Aucune icône trouvée
              </div>
            ) : (
              filteredIcons.map((icon) => {
                const IconComponent = getIconComponent(icon);

                if (!IconComponent) return null;

                return (
                  <div
                    key={icon}
                    className={`flex flex-col items-center justify-center p-2 rounded-md cursor-pointer hover:bg-base-200 transition-colors ${
                      selectedIcon === icon
                        ? "bg-primary/10 border border-primary/30"
                        : ""
                    } ${hoveredIcon === icon ? "bg-base-200" : ""}`}
                    onClick={() => {
                      onChange(icon);
                      setIsOpen(false);
                    }}
                    onMouseEnter={() => setHoveredIcon(icon)}
                    onMouseLeave={() => setHoveredIcon(null)}
                  >
                    <IconComponent
                      size={24}
                      className={selectedIcon === icon ? "text-primary" : ""}
                    />
                    <span className="text-xs mt-1 truncate w-full text-center">
                      {icon}
                    </span>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
};
