import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useCreateFamily } from "@/hooks/familyHooks";
import { getUserLogged } from "@/utils/axios/userUtils";

const CreateFamilyForm: React.FC = () => {
  const [familyName, setFamilyName] = useState("");
  const createFamilyMutation = useCreateFamily();
  const currentUser = getUserLogged();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!familyName.trim() || !currentUser?._id) return;

    createFamilyMutation.mutate(
      { name: familyName, owner: currentUser._id },
      {
        onSuccess: () => {
          setFamilyName("");
        },
      }
    );
  };

  return (
    <Card elevation={3}>
      <CardHeader title="Créer une nouvelle famille" />
      <CardContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nom de la famille"
            variant="outlined"
            fullWidth
            value={familyName}
            onChange={(e) => setFamilyName(e.target.value)}
            margin="normal"
            required
            disabled={createFamilyMutation.isPending}
          />

          {createFamilyMutation.isError && (
            <Alert severity="error" sx={{ my: 2 }}>
              Erreur:{" "}
              {createFamilyMutation.error.message ||
                "Impossible de créer la famille"}
            </Alert>
          )}

          {createFamilyMutation.isSuccess && (
            <Alert severity="success" sx={{ my: 2 }}>
              Famille créée avec succès !
            </Alert>
          )}

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!familyName.trim() || createFamilyMutation.isPending}
              startIcon={
                createFamilyMutation.isPending ? (
                  <CircularProgress size={20} />
                ) : null
              }
            >
              {createFamilyMutation.isPending
                ? "Création..."
                : "Créer ma famille"}
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreateFamilyForm;
