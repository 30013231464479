// hooks/useSncfApi.ts
import { useQuery } from "@tanstack/react-query";
import { JourneySearchParams } from "@/pages/API/sncf/types.ts";
import { sncfApi } from "@/utils/axios/sncfUtils.ts";

// Hook pour la recherche de stations
export const useStationSearch = (query: string, enabled = true) => {
  return useQuery({
    queryKey: ["stations", query],
    queryFn: () => sncfApi.searchStations(query),
    enabled: enabled && query.length >= 2, // Désactivé si la requête est trop courte
    staleTime: 5 * 60 * 1000, // 5 minutes de cache
  });
};

// Hook pour récupérer les détails d'une station
export const useStation = (stationId: string | null) => {
  return useQuery({
    queryKey: ["station", stationId],
    queryFn: () => sncfApi.getStation(stationId!),
    enabled: !!stationId,
    staleTime: 24 * 10 * 60 * 1000, // day de cache
  });
};

// Hook pour la recherche de trajets
export const useJourneySearch = (params: JourneySearchParams | null) => {
  return useQuery({
    queryKey: ["journeys", params],
    queryFn: () => sncfApi.searchJourneys(params!),
    enabled: !!params && !!params.fromStationId && !!params.toStationId,
    staleTime: 60 * 10000, // 10 minute de cache
  });
};

// Hook pour le suivi en temps réel
export const useRealTimeInfo = (
  journeyId: string | null,
  refetchInterval = 600000
) => {
  return useQuery({
    queryKey: ["realtime", journeyId],
    queryFn: () => sncfApi.getJourneyRealTimeInfo(journeyId!),
    enabled: !!journeyId,
    refetchInterval, // Rafraîchissement automatique (par défaut toutes les 10 minutes)
  });
};
