import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addHouse,
  createFamily,
  getFamilyDetails,
  joinFamily,
  leaveFamily,
  regenerateInviteCode,
  removeHouse,
} from "@/utils/axios/userUtils";
import {
  AddHouseParams,
  CreateFamilyParams,
  Family,
} from "@/features/Login/Family/types.ts";
// import { Family, CreateFamilyParams, AddHouseParams } from '@/features/Family';

// Clés de requête
export const FAMILY_KEYS = {
  all: ["family"] as const,
  details: () => [...FAMILY_KEYS.all, "details"] as const,
  houses: () => [...FAMILY_KEYS.all, "houses"] as const,
  house: (id: string) => [...FAMILY_KEYS.houses(), id] as const,
};

// Hook pour récupérer les détails de la famille
export const useFamilyDetails = () => {
  return useQuery<Family>({
    queryKey: FAMILY_KEYS.details(),
    queryFn: getFamilyDetails,
    // Ne pas retenter la requête en cas d'erreur (400 si pas de famille)
    retry: false,
    // Cache la famille pendant 5 minutes
    staleTime: 1000 * 60 * 5,
  });
};

// Hook pour créer une famille
export const useCreateFamily = () => {
  const queryClient = useQueryClient();

  return useMutation<Family, Error, CreateFamilyParams>({
    mutationFn: (familyData) => createFamily(familyData),
    onSuccess: (data) => {
      // Met à jour le cache avec la nouvelle famille
      queryClient.setQueryData(FAMILY_KEYS.details(), data);
      // Force l'actualisation de toutes les requêtes liées aux familles
      queryClient.invalidateQueries({ queryKey: FAMILY_KEYS.all });
    },
  });
};

// Hook pour rejoindre une famille
export const useJoinFamily = () => {
  const queryClient = useQueryClient();

  return useMutation<Family, Error, string>({
    mutationFn: (inviteCode) => joinFamily(inviteCode),
    onSuccess: (data) => {
      // Met à jour le cache avec la famille rejointe
      queryClient.setQueryData(FAMILY_KEYS.details(), data);
      // Force l'actualisation de toutes les requêtes liées aux familles
      queryClient.invalidateQueries({ queryKey: FAMILY_KEYS.all });
    },
  });
};

// Hook pour quitter une famille
export const useLeaveFamily = () => {
  const queryClient = useQueryClient();

  return useMutation<{ message: string }, Error, void>({
    mutationFn: () => leaveFamily(),
    onSuccess: () => {
      // Supprime les données de famille du cache
      queryClient.removeQueries({ queryKey: FAMILY_KEYS.details() });
      // Force l'actualisation de toutes les requêtes liées aux familles
      queryClient.invalidateQueries({ queryKey: FAMILY_KEYS.all });
    },
  });
};

// Hook pour régénérer le code d'invitation
export const useRegenerateInviteCode = () => {
  const queryClient = useQueryClient();

  return useMutation<{ inviteCode: string }, Error, void>({
    mutationFn: () => regenerateInviteCode(),
    onSuccess: (data) => {
      // Mise à jour optimiste du code d'invitation dans le cache
      queryClient.setQueryData<Family | undefined>(
        FAMILY_KEYS.details(),
        (oldData) =>
          oldData ? { ...oldData, inviteCode: data.inviteCode } : undefined
      );
    },
  });
};

// Hook pour ajouter une maison
export const useAddHouse = () => {
  const queryClient = useQueryClient();

  return useMutation<House, Error, AddHouseParams>({
    mutationFn: (houseData) => addHouse(houseData),
    onSuccess: (newHouse) => {
      // Mise à jour optimiste de la liste des maisons dans le cache
      queryClient.setQueryData<Family | undefined>(
        FAMILY_KEYS.details(),
        (oldData) => {
          if (!oldData) return undefined;
          return {
            ...oldData,
            houses: [...oldData.houses, newHouse],
          };
        }
      );
    },
  });
};

// Hook pour supprimer une maison
export const useRemoveHouse = () => {
  const queryClient = useQueryClient();

  return useMutation<{ message: string }, Error, string>({
    mutationFn: (houseId) => removeHouse(houseId),
    onSuccess: (_, houseId) => {
      // Mise à jour optimiste de la liste des maisons dans le cache en retirant la maison supprimée
      queryClient.setQueryData<Family | undefined>(
        FAMILY_KEYS.details(),
        (oldData) => {
          if (!oldData) return undefined;
          return {
            ...oldData,
            houses: oldData.houses.filter((house) => house._id !== houseId),
          };
        }
      );
    },
  });
};

// Ajouter une déclaration d'interface pour le type House
interface House {
  _id: string;
  name: string;
  address: string;
  family: string;
}
