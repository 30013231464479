import React from "react";
import { Category, Modificateur } from "@/pages/Bank/types.ts";
import { CategoryListItem } from "./CategoryListItem";
import { Folder, FolderPlus, Search } from "lucide-react";

interface CategoryListProps {
  mainCategories: Category[];
  loading: boolean;
  onEdit: (category: Category) => void;
  onDelete: (categoryId: string) => void;
  onModify: (categoryId: string, modificateur: Modificateur) => void;
  onAddNew: () => void;
  getSubCategories: (categoryId: string) => Category[];
}

export const CategoryList = ({
  mainCategories,
  loading,
  onEdit,
  onDelete,
  onModify,
  onAddNew,
  getSubCategories,
}: CategoryListProps) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [showArchived, setShowArchived] = React.useState(false);

  // Filtrer les catégories selon la recherche
  const filteredCategories = mainCategories.filter((category) => {
    // Filtrer par terme de recherche
    const matchesSearch = category.label
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    // Filtrer les catégories archivées si nécessaire
    const matchesArchived = showArchived || !category.archived;

    return matchesSearch && matchesArchived;
  });

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="loading loading-spinner loading-lg text-primary"></div>
      </div>
    );
  }

  return (
    <div className="p-3 bg-base-100 rounded-lg shadow-sm border border-base-300">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-bold flex items-center gap-2">
          <Folder className="text-primary" />
          Catégories
        </h3>

        <button className="btn btn-sm btn-primary" onClick={onAddNew}>
          <FolderPlus size={16} />
          Nouvelle catégorie
        </button>
      </div>

      {/* Barre de recherche et filtres */}
      <div className="flex flex-col sm:flex-row gap-2 mb-4">
        <div className="form-control flex-grow relative">
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">
            <Search size={16} />
          </div>
          <input
            type="text"
            placeholder="Rechercher une catégorie..."
            className="input input-bordered input-sm w-full pl-9"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="form-control">
          <label className="cursor-pointer label py-0 justify-start gap-2">
            <input
              type="checkbox"
              checked={showArchived}
              onChange={(e) => setShowArchived(e.target.checked)}
              className="checkbox checkbox-xs checkbox-primary"
            />
            <span className="label-text">Afficher les archives</span>
          </label>
        </div>
      </div>

      {/* Liste des catégories */}
      <div className="mt-2 max-h-[60vh] overflow-y-auto pr-2">
        {filteredCategories.length === 0 ? (
          <div className="text-center py-6 text-gray-500">
            {searchTerm
              ? "Aucune catégorie ne correspond à votre recherche"
              : "Aucune catégorie n'a été créée"}
          </div>
        ) : (
          filteredCategories.map((category) => (
            <CategoryListItem
              key={category._id}
              category={category}
              subCategories={getSubCategories(category._id)}
              onEdit={onEdit}
              onDelete={onDelete}
              onModify={onModify}
            />
          ))
        )}
      </div>
    </div>
  );
};
