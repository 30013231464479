import React, { useState } from "react";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ContentCopy as CopyIcon,
  ExitToApp as ExitIcon,
  Refresh as RefreshIcon,
  Share as ShareIcon,
} from "@mui/icons-material";
import { Family, getAvatarUrl, getUserLogged } from "@/utils/axios/userUtils";
import { useLeaveFamily, useRegenerateInviteCode } from "@/hooks/familyHooks";

interface FamilyDetailsProps {
  family: Family;
}

const FamilyDetails: React.FC<FamilyDetailsProps> = ({ family }) => {
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [codeCopied, setCodeCopied] = useState(false);

  const regenerateCodeMutation = useRegenerateInviteCode();
  const leaveFamilyMutation = useLeaveFamily();

  const currentUser = getUserLogged();
  const isOwner = currentUser?._id === family?.owner._id;

  const copyInviteCode = () => {
    try {
      if (!navigator.clipboard) {
        // Fallback pour les navigateurs qui ne supportent pas l'API Clipboard
        const textArea = document.createElement("textarea");
        textArea.value = family.inviteCode;
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand("copy");
          setCodeCopied(true);
        } catch (err) {
          console.error("Fallback: Impossible de copier le texte", err);
          alert(
            "Impossible de copier automatiquement. Code: " + family.inviteCode
          );
        }

        document.body.removeChild(textArea);
      } else {
        // Méthode moderne
        navigator.clipboard
          .writeText(family.inviteCode)
          .then(() => {
            console.log("Code copié avec succès!");
            setCodeCopied(true);
          })
          .catch((err) => {
            console.error("Erreur lors de la copie:", err);
            alert(
              "Impossible de copier automatiquement. Code: " + family.inviteCode
            );
          });
      }

      // Masquer la notification après 2 secondes
      setTimeout(() => setCodeCopied(false), 2000);
    } catch (error) {
      console.error("Erreur générale lors de la copie:", error);
      alert("Impossible de copier. Voici le code: " + family.inviteCode);
    }
  };

  const handleLeaveFamily = () => {
    leaveFamilyMutation.mutate(undefined, {
      onSuccess: () => {
        setOpenLeaveDialog(false);
      },
    });
  };

  return (
    <>
      <Card elevation={3} sx={{ mb: 4 }}>
        <CardHeader
          title={`Famille: ${family.name}`}
          subheader={
            family.createdAt
              ? `Créée le ${new Date(family.createdAt).toLocaleDateString()}`
              : "Date de création non disponible"
          }
          action={
            !isOwner && (
              <Button
                color="error"
                startIcon={<ExitIcon />}
                onClick={() => setOpenLeaveDialog(true)}
              >
                Quitter
              </Button>
            )
          }
        />

        <CardContent>
          {/* Section Propriétaire */}
          <Typography variant="h6" gutterBottom>
            Propriétaire
          </Typography>
          <Box display="flex" alignItems="center" gap={1} mb={3}>
            <Avatar
              src={
                family.owner.avatar
                  ? getAvatarUrl(family.owner.avatar)
                  : undefined
              }
              alt={family.owner.username}
            />
            <Typography>{family.owner.username}</Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Section Code d'invitation - Mise en valeur pour les propriétaires */}
          <Typography variant="h6" gutterBottom>
            Code d'invitation
          </Typography>

          {isOwner ? (
            <Paper elevation={2} sx={{ p: 2, mb: 3, bgcolor: "action.hover" }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="body2">
                  En tant que propriétaire de la famille, vous pouvez inviter
                  d'autres utilisateurs à rejoindre votre famille en partageant
                  ce code :
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  gap={2}
                  justifyContent="center"
                >
                  <Chip
                    label={family.inviteCode}
                    color="primary"
                    sx={{ fontSize: "1.2rem", py: 3, px: 1 }}
                  />

                  <Tooltip title="Copier le code">
                    <IconButton onClick={copyInviteCode} color="primary">
                      <CopyIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Générer un nouveau code">
                    <IconButton
                      onClick={() => regenerateCodeMutation.mutate()}
                      disabled={regenerateCodeMutation.isPending}
                      color="primary"
                    >
                      {regenerateCodeMutation.isPending ? (
                        <CircularProgress size={24} />
                      ) : (
                        <RefreshIcon />
                      )}
                    </IconButton>
                  </Tooltip>

                  <Button
                    variant="contained"
                    startIcon={<ShareIcon />}
                    onClick={() => setOpenShareDialog(true)}
                  >
                    Partager
                  </Button>
                </Box>

                {regenerateCodeMutation.isSuccess && (
                  <Alert severity="success" sx={{ mt: 1 }}>
                    Nouveau code généré :{" "}
                    {regenerateCodeMutation.data.inviteCode}
                  </Alert>
                )}
              </Box>
            </Paper>
          ) : (
            // Version simple pour les membres non-propriétaires
            <Box display="flex" alignItems="center" gap={2} mb={3}>
              <Chip
                label={family.inviteCode}
                color="primary"
                sx={{ fontSize: "1rem", py: 2 }}
              />

              <Tooltip title={codeCopied ? "Copié !" : "Copier le code"}>
                <IconButton onClick={copyInviteCode}>
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}

          <Divider sx={{ my: 2 }} />

          {/* Section Membres */}
          <Typography variant="h6" gutterBottom>
            Membres ({family.members.length})
          </Typography>

          <List>
            {family.members.map((member) => (
              <ListItem key={member._id}>
                <ListItemAvatar>
                  <Avatar
                    src={
                      member.avatar ? getAvatarUrl(member.avatar) : undefined
                    }
                    alt={member.username}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={member.username}
                  secondary={
                    member._id === family.owner._id ? "Propriétaire" : "Membre"
                  }
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>

      {/* Dialog de confirmation pour quitter la famille */}
      <Dialog open={openLeaveDialog} onClose={() => setOpenLeaveDialog(false)}>
        <DialogTitle>Quitter la famille</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Êtes-vous sûr de vouloir quitter cette famille ? Cette action est
            irréversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLeaveDialog(false)}>Annuler</Button>
          <Button
            onClick={handleLeaveFamily}
            color="error"
            disabled={leaveFamilyMutation.isPending}
          >
            {leaveFamilyMutation.isPending ? "En cours..." : "Quitter"}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog pour partager le code d'invitation */}
      <Dialog open={openShareDialog} onClose={() => setOpenShareDialog(false)}>
        <DialogTitle>Partager le code d'invitation</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Partagez ce code avec les personnes que vous souhaitez inviter à
            rejoindre votre famille.
          </DialogContentText>

          <TextField
            fullWidth
            variant="outlined"
            value={family.inviteCode}
            InputProps={{
              readOnly: true,
            }}
            sx={{ mb: 2 }}
          />

          <Box display="flex" flexDirection="column" gap={2}>
            <Button
              variant="contained"
              startIcon={<CopyIcon />}
              onClick={copyInviteCode}
            >
              Copier le code
            </Button>

            {navigator.share && (
              <Button
                variant="outlined"
                startIcon={<ShareIcon />}
                onClick={() => {
                  navigator
                    .share({
                      title: "Rejoignez ma famille",
                      text: `Rejoignez ma famille "${family.name}" avec ce code d'invitation: ${family.inviteCode}`,
                    })
                    .catch((err) =>
                      console.error("Erreur lors du partage:", err)
                    );
                  setOpenShareDialog(false);
                }}
              >
                Partager via mon appareil
              </Button>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenShareDialog(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={codeCopied}
        autoHideDuration={2000}
        onClose={() => setCodeCopied(false)}
        message="Code d'invitation copié dans le presse-papiers !"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        // Ne pas inclure de style qui pourrait passer automatiquement ownerState
        sx={{ "& .MuiSnackbarContent-root": { bgcolor: "success.main" } }} // Utiliser sx au lieu de style direct
      />
    </>
  );
};

export default FamilyDetails;
