import { Player } from "@/pages/Molky/types.ts";

interface StatsProps {
  players: Player[];
}

export function Stats({ players }: StatsProps) {
  // Calcul de statistiques simples
  const stats = players.map((player) => {
    const totalThrows = player.history.length;
    const totalPoints = player.score;
    const avgPointsPerThrow = totalThrows > 0 ? totalPoints / totalThrows : 0;
    const maxScore = Math.max(0, ...player.history);
    const zeroThrows = player.history.filter((score) => score === 0).length;
    const zeroPercentage =
      totalThrows > 0 ? (zeroThrows / totalThrows) * 100 : 0;

    return {
      name: player.name,
      totalThrows,
      avgPointsPerThrow,
      maxScore,
      zeroPercentage,
    };
  });

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">Statistiques</h2>

        <div className="overflow-x-auto">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>Joueur</th>
                <th>Lancers</th>
                <th>Moy. points</th>
                <th>Meilleur</th>
                <th>% Manqués</th>
              </tr>
            </thead>
            <tbody>
              {stats.map((stat, index) => (
                <tr key={index}>
                  <td>{stat.name}</td>
                  <td>{stat.totalThrows}</td>
                  <td>{stat.avgPointsPerThrow.toFixed(1)}</td>
                  <td>{stat.maxScore}</td>
                  <td>{stat.zeroPercentage.toFixed(1)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
