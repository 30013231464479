import { CheckedRowList, Compte, RowData } from "@/pages/Bank/types.ts";
import { TableView } from "@/pages/Bank/components/Table/views/TableView.tsx";
import { useCallback, useMemo, useState } from "react";
import { AnalyticsView } from "@/pages/Bank/components/Table/views/AnalyticsView.tsx";
import { SelectedAnalyticsView } from "@/pages/Bank/components/Table/views/SelectedAnalyticsView.tsx";
import {
  useDataCompte,
  VisibleColumnsType,
} from "@/pages/Bank/components/hooks/useDataCompte.tsx";

// Colonnes par défaut
const DEFAULT_VISIBLE_COLUMNS: VisibleColumnsType = {
  date: true,
  libelleSimplifier: true,
  categorie: true,
  sousCategorie: true,
  solde: true,
  montant: true,
  // Par défaut, ces colonnes sont masquées
  _id: false,
  debit: false,
  credit: false,
  pointageOperation: false,
  dateValeur: false,
  dateOperation: false,
  reference: false,
  informationComplementaire: false,
  typeOperation: false,
  libellerOperation: false,
  sousCategoriePersonnel: false,
  precision: false,
  categoriePersonnel: false,
  chronoIndex: false, // Nouvel index chronologique
};

// Types pour la gestion du tri
export type SortDirection = "asc" | "desc";

export interface SortConfig {
  key: keyof RowData | null;
  direction: SortDirection;
}

// Types pour la gestion des filtres
export type FilterOperator =
  | "contains"
  | "equals"
  | "greaterThan"
  | "lessThan"
  | "between"
  | "startsWith"
  | "endsWith";

export interface Filter {
  column: keyof RowData;
  operator: FilterOperator;
  value: string | number | boolean | null;
  secondValue?: string | number | null; // Pour les opérateurs comme 'between'
}

export const TableMain = ({ compte }: { compte: Compte }) => {
  // Définition des vues disponibles dans un tableau pour faciliter la navigation cyclique
  const views = ["table", "analytics", "selectedAnalytics"];
  const [activeViewIndex, setActiveViewIndex] = useState<number>(0);
  const [checkedRow, setCheckedRow] = useState<CheckedRowList>({});
  const [isValueHidden, setIsValueHidden] = useState<boolean>(false);
  // États de la table
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [visibleColumns, setVisibleColumns] = useState<VisibleColumnsType>(
    DEFAULT_VISIBLE_COLUMNS
  );

  // État pour la gestion du tri visuel (uniquement pour l'affichage)
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: "date",
    direction: "desc",
  });

  // État pour les filtres
  const [filters, setFilters] = useState<Filter[]>([]);

  // Récupérer les données du compte (déjà triées pour le calcul du solde)
  const { lignesCompte, isLignesCompteLoading } = useDataCompte({ compte });
  // Appliquer les filtres aux données
  const filteredData = useMemo(() => {
    if (!filters.length || !lignesCompte || lignesCompte.length === 0) {
      return lignesCompte;
    }

    return lignesCompte.filter((row) => {
      // Une ligne doit satisfaire tous les filtres pour être incluse
      return filters.every((filter) => {
        const columnValue = row[filter.column];

        // Vérifier si la valeur existe
        if (columnValue === undefined || columnValue === null) {
          return false;
        }

        // Convertir en string pour les opérations de texte
        const stringValue = String(columnValue).toLowerCase();
        const filterValue =
          filter.value !== null ? String(filter.value).toLowerCase() : "";

        switch (filter.operator) {
          case "contains":
            return stringValue.includes(filterValue);
          case "equals":
            return stringValue === filterValue;
          case "startsWith":
            return stringValue.startsWith(filterValue);
          case "endsWith":
            return stringValue.endsWith(filterValue);
          case "greaterThan":
            return (
              typeof columnValue === "number" &&
              columnValue > Number(filter.value)
            );
          case "lessThan":
            return (
              typeof columnValue === "number" &&
              columnValue < Number(filter.value)
            );
          case "between":
            if (
              typeof columnValue === "number" &&
              filter.secondValue !== undefined
            ) {
              return (
                columnValue >= Number(filter.value) &&
                columnValue <= Number(filter.secondValue)
              );
            }
            return false;
          default:
            return true;
        }
      });
    });
  }, [lignesCompte, filters]);

  // Trier les données filtrées
  const sortedAndFilteredData = useMemo(() => {
    if (!sortConfig.key || !filteredData || filteredData.length === 0) {
      return filteredData;
    }

    // Si on trie par l'index chronologique, on utilise directement cet index
    if (sortConfig.key === "chronoIndex") {
      return [...filteredData].sort((a, b) => {
        const aIndex = a.chronoIndex || 0;
        const bIndex = b.chronoIndex || 0;
        return sortConfig.direction === "asc"
          ? aIndex - bIndex
          : bIndex - aIndex;
      });
    }

    // Créer une copie pour ne pas modifier les données d'origine
    const sortableItems = [...filteredData];

    // Tri visuel uniquement (n'affecte pas les soldes qui sont déjà calculés)
    sortableItems.sort((a, b) => {
      // Gérer les valeurs nulles ou undefined
      const valueA = a[sortConfig.key as keyof RowData];
      const valueB = b[sortConfig.key as keyof RowData];

      if (valueA === undefined || valueA === null)
        return sortConfig.direction === "asc" ? -1 : 1;
      if (valueB === undefined || valueB === null)
        return sortConfig.direction === "asc" ? 1 : -1;

      // Gérer les dates
      if (
        sortConfig.key === "date" ||
        sortConfig.key === "dateOperation" ||
        sortConfig.key === "dateValeur"
      ) {
        const dateA = new Date(String(valueA)).getTime();
        const dateB = new Date(String(valueB)).getTime();
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      }

      // Gérer les nombres
      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortConfig.direction === "asc"
          ? valueA - valueB
          : valueB - valueA;
      }

      // Gérer les chaînes
      const strA = String(valueA).toLowerCase();
      const strB = String(valueB).toLowerCase();

      if (strA < strB) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (strA > strB) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }

      // En cas d'égalité, utiliser l'index chronologique pour maintenir l'ordre
      const aIndex = a.chronoIndex !== undefined ? a.chronoIndex : 0;
      const bIndex = b.chronoIndex !== undefined ? b.chronoIndex : 0;
      return aIndex - bIndex;
    });

    return sortableItems;
  }, [filteredData, sortConfig]);

  // Composant pour afficher les indicateurs de vues disponibles
  const ViewIndicator = ({
    index,
    isActive,
  }: {
    index: number;
    isActive: boolean;
  }) => (
    <div
      onClick={() => setActiveViewIndex(index)}
      className={`h-3 w-3 rounded-full mx-1 cursor-pointer transition-all duration-200 ${
        isActive ? "bg-blue-500 scale-110" : "bg-gray-300 hover:bg-gray-400"
      }`}
    />
  );

  // Calculer les données de la page courante
  const startIndex = useMemo(
    () => (currentPage - 1) * pageSize,
    [currentPage, pageSize]
  );
  const endIndex = useMemo(() => startIndex + pageSize, [startIndex, pageSize]);

  // Utiliser les données triées/filtrées pour la pagination
  const currentPageData = useMemo(
    () => sortedAndFilteredData?.slice(startIndex, endIndex) || [],
    [sortedAndFilteredData, startIndex, endIndex]
  );

  // Nombre total de pages (basé sur les données filtrées)
  const totalPages = useMemo(
    () => Math.ceil((sortedAndFilteredData?.length || 0) / pageSize),
    [sortedAndFilteredData, pageSize]
  );

  // Handler pour changer la taille de page
  const handlePageSizeChange = useCallback((newSize: number) => {
    setPageSize(newSize);
    setCurrentPage(1); // Revenir à la première page lors du changement de taille
  }, []);

  // Handler pour changer le tri
  const handleSort = useCallback((key: keyof RowData) => {
    setSortConfig((prevConfig) => {
      // Si même colonne et déjà en mode descendant (2ème clic), annuler le tri (3ème clic)
      if (prevConfig.key === key && prevConfig.direction === "desc") {
        return { key: null, direction: "desc" };
      }

      // Si même colonne et en mode ascendant (1er clic), passer en descendant (2ème clic)
      if (prevConfig.key === key && prevConfig.direction === "asc") {
        return { key, direction: "desc" };
      }

      // Nouvelle colonne ou tri annulé, commencer par ascendant (1er clic)
      return { key, direction: "asc" };
    });

    setCurrentPage(1); // Revenir à la première page lors du changement de tri
  }, []);

  // Handler pour ajouter un filtre
  const handleAddFilter = useCallback((filter: Filter) => {
    setFilters((prev) => [...prev, filter]);
    setCurrentPage(1); // Revenir à la première page lors de l'ajout d'un filtre
  }, []);

  // Handler pour modifier un filtre existant
  const handleUpdateFilter = useCallback((index: number, filter: Filter) => {
    setFilters((prev) => {
      const newFilters = [...prev];
      newFilters[index] = filter;
      return newFilters;
    });
    setCurrentPage(1);
  }, []);

  // Handler pour supprimer un filtre
  const handleRemoveFilter = useCallback((index: number) => {
    setFilters((prev) => prev.filter((_, i) => i !== index));
    setCurrentPage(1);
  }, []);

  // Handler pour réinitialiser tous les filtres
  const handleClearFilters = useCallback(() => {
    setFilters([]);
    setCurrentPage(1);
  }, []);
  // Gérer la sélection d'une ligne individuelle
  const handleSelectRow = useCallback((id: string, isChecked: boolean) => {
    setCheckedRow((prev) => ({
      ...prev,
      [id]: isChecked,
    }));
  }, []);

  // Gérer la sélection de toutes les lignes de la page actuelle
  const handleSelectAllOnPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      const newCheckedLines = { ...checkedRow };

      // Appliquer la sélection uniquement aux éléments de la page courante
      currentPageData.forEach((row: RowData) => {
        if (row._id) {
          newCheckedLines[row._id] = isChecked;
        }
      });

      setCheckedRow(newCheckedLines);
    },
    [currentPageData, checkedRow]
  );

  // Calculer si toutes les lignes de la page actuelle sont sélectionnées
  const areAllCurrentPageRowsSelected = useCallback(() => {
    if (!currentPageData || currentPageData.length === 0) return false;
    return currentPageData.every((row) => row._id && checkedRow[row._id]);
  }, [currentPageData, checkedRow]);

  // Basculer la visibilité d'une colonne
  const toggleColumnVisibility = useCallback((columnKey: string) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  }, []);

  // Navigation vers la vue précédente
  const goToPrevView = () => {
    setActiveViewIndex((prev) => (prev === 0 ? views.length - 1 : prev - 1));
  };

  // Navigation vers la vue suivante
  const goToNextView = () => {
    setActiveViewIndex((prev) => (prev === views.length - 1 ? 0 : prev + 1));
  };

  // Permet toujours de changer de vue directement par nom si nécessaire
  const handleViewChange = (view: string) => {
    if (view === "next") {
      goToNextView();
      return;
    }
    if (view === "prev") {
      goToPrevView();
      return;
    }

    const viewIndex = views.indexOf(view);
    if (viewIndex !== -1) {
      setActiveViewIndex(viewIndex);
    }
  };

  // Générer les numéros de page pour la pagination
  const generatePagination = useCallback(() => {
    const pages: (number | string)[] = [];

    if (totalPages <= 0) return pages;

    const maxPagesToShow = 5;

    // Toujours montrer la première page
    pages.push(1);

    // Calculer la plage de pages à afficher autour de la page courante
    let startPage = Math.max(2, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages - 1, startPage + maxPagesToShow - 2);

    // Ajuster si on atteint les limites
    if (endPage - startPage < maxPagesToShow - 3 && startPage > 2) {
      startPage = Math.max(2, endPage - maxPagesToShow + 3);
    }

    // Ajouter des ellipses si nécessaire
    if (startPage > 2) {
      pages.push("...");
    }

    // Ajouter les pages de la plage calculée
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Ajouter des ellipses si nécessaire
    if (endPage < totalPages - 1) {
      pages.push("...");
    }

    // Toujours montrer la dernière page si elle existe
    if (totalPages > 1) {
      pages.push(totalPages);
    }

    return pages;
  }, [currentPage, totalPages]);
  // Obtenir la vue active actuelle
  const activeView = views[activeViewIndex];

  return (
    <div className="w-full">
      <div className="flex flex-col">
        {/* Indicateurs de vues (points) */}
        <div className="flex justify-center mt-2 mb-4 gap-4">
          {views.map((_, index) => (
            <ViewIndicator
              key={index}
              index={index}
              isActive={activeViewIndex === index}
            />
          ))}
        </div>
        <div className="flex-1 w-full">
          {activeView === "table" && (
            <TableView
              compte={compte}
              changeView={handleViewChange}
              isValueHidden={isValueHidden}
              setIsValueHidden={setIsValueHidden}
              generatePagination={generatePagination}
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              selectedCell={checkedRow}
              visibleColumns={visibleColumns}
              toggleColumnVisibility={toggleColumnVisibility}
              currentPageData={currentPageData}
              handleSelectRow={handleSelectRow}
              areAllCurrentPageRowsSelected={areAllCurrentPageRowsSelected}
              handleSelectAllOnPage={handleSelectAllOnPage}
              pageSize={pageSize}
              setPageSize={handlePageSizeChange}
              // Nouvelles props pour le tri et le filtrage
              sortConfig={sortConfig}
              handleSort={handleSort}
              filters={filters}
              handleAddFilter={handleAddFilter}
              handleUpdateFilter={handleUpdateFilter}
              handleRemoveFilter={handleRemoveFilter}
              handleClearFilters={handleClearFilters}
              isLoading={isLignesCompteLoading}
              totalRowCount={sortedAndFilteredData?.length || 0}
            />
          )}
          {activeView === "analytics" && <AnalyticsView compte={compte} />}
          {activeView === "selectedAnalytics" && (
            <SelectedAnalyticsView compte={compte} selectedCell={checkedRow} />
          )}
        </div>

        {/* Indicateurs de vues (points) */}
        <div className="flex justify-center mt-2">
          {views.map((_, index) => (
            <ViewIndicator
              key={index}
              index={index}
              isActive={activeViewIndex === index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
