import { useState } from "react";
import { Player } from "@/pages/Molky/types.ts";

interface GameControlsProps {
  currentPlayer: Player;
  onAddScore: (points: number) => void;
  onNewGame: () => void;
  onUndoLastScore?: () => void;
  isGameFinished: boolean;
  winner?: Player;
  onSaveGame?: (generateSummary: boolean) => void;
  gameSummary?: string;
}

export function GameControls({
  currentPlayer,
  onAddScore,
  onNewGame,
  onUndoLastScore,
  isGameFinished,
  winner,
  onSaveGame,
  gameSummary,
}: GameControlsProps) {
  const [customScore, setCustomScore] = useState<number>(0);
  const [generateSummary, setGenerateSummary] = useState<boolean>(true);
  const [showSummary, setShowSummary] = useState<boolean>(false);

  // Quilles du Mölkky (1-12)
  const pins = Array.from({ length: 12 }, (_, i) => i + 1);

  if (isGameFinished) {
    return (
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body text-center">
          <div className="alert alert-success">
            <h2 className="text-2xl font-bold">🎉 Partie terminée ! 🎉</h2>
            <p className="text-xl my-4">
              {winner?.name} a gagné avec {winner?.score} points !
            </p>

            {!showSummary && !gameSummary && (
              <div className="form-control mt-4">
                <label className="label cursor-pointer justify-center">
                  <span className="label-text mr-2">
                    Générer un résumé de la partie
                  </span>
                  <input
                    type="checkbox"
                    className="toggle toggle-primary"
                    checked={generateSummary}
                    onChange={() => setGenerateSummary(!generateSummary)}
                  />
                </label>

                <button
                  onClick={() => {
                    if (onSaveGame) {
                      onSaveGame(generateSummary);
                      setShowSummary(true);
                    }
                  }}
                  className="btn btn-primary mt-2"
                >
                  Sauvegarder la partie
                </button>
              </div>
            )}

            {showSummary && gameSummary && (
              <div className="alert alert-info mt-4">
                <h3 className="font-bold">Résumé de la partie</h3>
                <p>{gameSummary}</p>
              </div>
            )}

            <div className="flex justify-center gap-2 mt-4">
              <button onClick={onNewGame} className="btn btn-primary">
                Nouvelle partie
              </button>
              {winner && (
                <button
                  onClick={() => {
                    if (onUndoLastScore) onUndoLastScore();
                  }}
                  className="btn btn-outline"
                >
                  Annuler la victoire
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">
          Tour de{" "}
          <span className="font-bold text-primary">{currentPlayer.name}</span>
          <span className="badge badge-lg">{currentPlayer.score} pts</span>
        </h2>

        <div className="divider">Ajouter des points</div>

        {/* Manqué (0 points) */}
        <button onClick={() => onAddScore(0)} className="btn btn-error mb-4">
          Manqué (0)
        </button>

        {/* Quilles individuelles (1-12 points) */}
        <h3 className="font-bold mb-2">Quille individuelle (1-12)</h3>
        <div className="grid grid-cols-4 gap-2 mb-4">
          {pins.map((pin) => (
            <button
              key={pin}
              onClick={() => onAddScore(pin)}
              className="btn btn-outline"
            >
              {pin}
            </button>
          ))}
        </div>

        {/* Score personnalisé (pour plusieurs quilles) */}
        <h3 className="font-bold mb-2">
          Plusieurs quilles (nombre de quilles)
        </h3>
        <div className="flex gap-2">
          <input
            type="number"
            min="0"
            max="12"
            value={customScore || ""}
            onChange={(e) => setCustomScore(Number(e.target.value))}
            className="input input-bordered w-full"
            placeholder="Nombre de quilles"
          />
          <button
            onClick={() => {
              if (customScore > 0 && customScore <= 12) {
                onAddScore(customScore);
                setCustomScore(0);
              }
            }}
            disabled={customScore <= 0 || customScore > 12}
            className="btn btn-primary"
          >
            Valider
          </button>
        </div>

        {/* Bouton retour en arrière */}
        {onUndoLastScore && (
          <div className="mt-6">
            <button
              onClick={onUndoLastScore}
              className="btn btn-outline btn-info w-full"
            >
              ↩️ Revenir en arrière (dernier score)
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
