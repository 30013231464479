import axios from "axios";
import { getBackendMainUrl } from "./global.ts";
import {
  Banque,
  Category,
  Compte,
  PrevisionRowSchema,
  RowData,
} from "@/pages/Bank/types.ts";
import { getUserLogged } from "@/utils/axios/userUtils.ts";

const BASE_URL_API = getBackendMainUrl + "/bank";
const HEADERS = {
  headers: {
    "content-type": "application/json",
    authorization: localStorage.getItem("authorization"),
  },
};

// BANQUE REST PART
export const getBanqueDetail = async (banqueId: string) => {
  const response = await axios.get(
    BASE_URL_API + "/banque" + "/" + banqueId + "/",
    HEADERS
  );
  return response;
};

export const getBanquesForUser = async () => {
  const response = await axios.get(BASE_URL_API + "/banque/", HEADERS);
  return response.data;
};

export const getBanqueFields = async () => {
  const response = await axios.get(
    BASE_URL_API + "/banque" + "/fields",
    HEADERS
  );
  return response;
};

export const createBanqueForUser = async (banque: Banque) => {
  const response = axios.post(BASE_URL_API + "/banque", banque, HEADERS);
  return response;
};

export const updateBanqueForUser = async (banque: Banque, banqueId: string) => {
  const response = await axios.patch(
    BASE_URL_API + "/banque" + "/" + banqueId,
    JSON.stringify(banque),
    HEADERS
  );
  return response;
};

export const deleteBanqueForUser = async (banqueId: string) => {
  const response = await axios.delete(
    BASE_URL_API + "/banque" + "/" + banqueId,
    HEADERS
  );
  return response;
};

// COMPTE REST PART
export const createCompteForBanqueForUser = async (compte: Compte) => {
  const response = await axios.post(BASE_URL_API + "/compte", compte, HEADERS);
  return response;
};

export const getAllComptes = async () => {
  const response = await axios.get(BASE_URL_API + "/compte", HEADERS);
  return response.data;
};

export const getComptesForUser = async (userId: string) => {
  const response = await axios.get(
    BASE_URL_API + "/compte/user/" + userId,
    HEADERS
  );
  return response.data;
};

export const getCompteById = async (compteId: string) => {
  const response = await axios.get(
    BASE_URL_API + "/compte/" + compteId,
    HEADERS
  );
  return response.data;
};

export const getComptesForUserConnected = async () => {
  return await getComptesForUser(getUserLogged()!._id);
};

export const updateCompteForUser = async (compte: Compte) => {
  const response = await axios.patch(
    BASE_URL_API + "/compte/" + compte._id,
    compte,
    HEADERS
  );
  return response;
};

export const deleteCompteForUser = async (compteId: string) => {
  const response = await axios.delete(
    BASE_URL_API + "/compte/" + compteId,
    HEADERS
  );
  return response;
};

// SEND CSV PART
export const sendCsvFile = async (formData: FormData) => {
  const response = await axios.post(
    BASE_URL_API + "/ligneDeCompte/",
    formData,
    {
      headers: {
        "content-type": "multipart/formData",
        authorization: localStorage.getItem("authorization"),
      },
    }
  );
  return response;
};

// LIGNE_DE_COMPTE PART
export const getLigneDeCompte = async (
  compteId: string
): Promise<RowData[]> => {
  const response = await axios.get(
    BASE_URL_API + "/ligneDeCompte/" + compteId,
    HEADERS
  );
  return response.data;
};

// LIGNE PREVISION PART
export const getLignesPrevisionDeCompte = async (
  compteId: string
): Promise<PrevisionRowSchema[]> => {
  const response = await axios.get(
    BASE_URL_API + "/lignePrevisionDeCompte/" + compteId,
    HEADERS
  );
  return response.data;
};

export const createLignePrevisionDeCompte = async (
  prevision: PrevisionRowSchema
) => {
  const response = await axios.post(
    BASE_URL_API + "/lignePrevisionDeCompte/",
    JSON.stringify(prevision),
    HEADERS
  );
  return response;
};

export const updateLignePrevisionDeCompte = async (
  prevision: Partial<PrevisionRowSchema>
) => {
  const response = await axios.patch(
    BASE_URL_API + "/lignePrevisionDeCompte/" + prevision._id,
    JSON.stringify(prevision),
    HEADERS
  );
  return response;
};

export const deleteLignePrevisionDeCompte = async (previsionId: string) => {
  const response = await axios.delete(
    BASE_URL_API + "/lignePrevisionDeCompte/" + previsionId,
    HEADERS
  );
  console.log(response);
  return response;
};

// Ajouter ces fonctions à ton fichier bankUtils.ts existant

// CATEGORY REST PART
export const getCategoriesForUser = async (): Promise<Category[]> => {
  const response = await axios.get(BASE_URL_API + "/categories", HEADERS);
  return response.data;
};

export const getCategoryById = async (
  categoryId: string
): Promise<Category> => {
  const response = await axios.get(
    BASE_URL_API + "/categories/" + categoryId,
    HEADERS
  );
  return response.data;
};

export const createCategoryForUser = async (category: Partial<Category>) => {
  const response = await axios.post(
    BASE_URL_API + "/categories",
    JSON.stringify(category),
    HEADERS
  );
  return response;
};

export const updateCategoryForUser = async (category: Partial<Category>) => {
  const response = await axios.patch(
    BASE_URL_API + "/categories/" + category._id,
    JSON.stringify(category),
    HEADERS
  );
  return response;
};

export const deleteCategoryForUser = async (categoryId: string) => {
  const response = await axios.delete(
    BASE_URL_API + "/categories/" + categoryId,
    HEADERS
  );
  return response;
};

// Types pour les requêtes de gestion des catégories
export interface AssignCategorieRequest {
  ligneId: string;
  categorieId: string; // Toujours string, jamais null
  sousCategorieId?: string; // Optionnel
}

export interface ResetCategorieRequest {
  ligneId: string;
}

// Fonction pour attribuer une catégorie à une ligne
export const assignCategorieToLigne = async (
  request: AssignCategorieRequest
) => {
  try {
    // Créer un nouvel objet propre pour la requête
    const requestData: Record<string, string> = {
      ligneId: request.ligneId,
      categorieId: request.categorieId,
    };

    // Ajouter sousCategorieId seulement s'il existe et n'est pas vide
    if (request.sousCategorieId && request.sousCategorieId.trim() !== "") {
      requestData.sousCategorieId = request.sousCategorieId;
    }

    const response = await axios.post(
      BASE_URL_API + "/ligneDeCompte/assignCategorie",
      JSON.stringify(requestData),
      HEADERS
    );

    return response;
  } catch (error: any) {
    console.error("Erreur détaillée:", error);
    // Tenter d'afficher le corps de la réponse d'erreur s'il existe
    if (error.response && error.response.data) {
      console.error("Message d'erreur du serveur:", error.response.data);
    }
    throw error;
  }
};

// Fonction pour réinitialiser une catégorie à sa valeur d'origine
export const resetCategorieForLigne = async (
  request: ResetCategorieRequest
) => {
  try {
    const response = await axios.post(
      BASE_URL_API + "/ligneDeCompte/resetCategorie",
      JSON.stringify(request),
      HEADERS
    );
    return response;
  } catch (error: any) {
    console.error("Erreur détaillée:", error);
    if (error.response && error.response.data) {
      console.error("Message d'erreur du serveur:", error.response.data);
    }
    throw error;
  }
};
