export const displayLocalDate = (date: string) => {
  const dateToParse = new Date(date);
  return dateToParse.toLocaleDateString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const sortDate = (a: Date, b: Date) => {
  const aDate = new Date(a);
  const bDate = new Date(b);
  return aDate.getTime() - bDate.getTime();
};

export const formatDate = (date: string) => {
  return new Date(date).toLocaleDateString("fr-FR");
};
