import React, { useEffect, useRef, useState } from "react";
import { Category, RowData } from "@/pages/Bank/types.ts";
import {
  assignCategorieToLigne,
  resetCategorieForLigne,
} from "@/utils/axios/bankUtils.ts";
import { FaCheckCircle, FaEdit, FaTimes } from "react-icons/fa";
import { useQueryClient } from "@tanstack/react-query";
import { useCategories } from "@/pages/Bank/components/hooks/useCategories";

interface CategoryCellProps {
  row: RowData;
  column: "categorie" | "sousCategorie";
  isCompactMode: boolean;
  compteId: string;
}

export const CategoryCell: React.FC<CategoryCellProps> = ({
  row,
  column,
  isCompactMode,
  compteId,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(
    null
  );
  const [subCategories, setSubCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  // Utiliser le hook useCategories à la place de la logique personnalisée
  const {
    mainCategories,
    isLoading: categoriesLoading,
    getSubCategories,
  } = useCategories();

  // Si on est dans une cellule de sous-catégorie, on ne permet pas de l'éditer directement
  // sauf si une catégorie personnalisée est déjà définie
  const isSubCategoryCell = column === "sousCategorie";
  const hasPersonalizedCategory = !!row.categoriePersonnel;

  useEffect(() => {
    // Fermer le dropdown si on clique ailleurs
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsEditing(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Initialiser les valeurs actuelles quand l'édition commence
  useEffect(() => {
    if (isEditing && !categoriesLoading) {
      // Initialiser avec les valeurs actuelles si c'est déjà personnalisé
      if (row.categoriePersonnel) {
        const parentId = row.categoriePersonnel._id;
        setSelectedCategory(parentId);

        // Charger les sous-catégories associées
        const subCats = getSubCategories(parentId);
        setSubCategories(subCats);

        if (row.sousCategoriePersonnel) {
          setSelectedSubCategory(row.sousCategoriePersonnel._id);
        }
      }
    }
  }, [
    isEditing,
    categoriesLoading,
    row.categoriePersonnel,
    row.sousCategoriePersonnel,
    getSubCategories,
  ]);

  // Mettre à jour les sous-catégories quand une catégorie principale est sélectionnée
  useEffect(() => {
    if (selectedCategory) {
      const subCats = getSubCategories(selectedCategory);
      setSubCategories(subCats);

      // Réinitialiser la sous-catégorie si on change de catégorie principale
      if (row.categoriePersonnel?._id !== selectedCategory) {
        setSelectedSubCategory(null);
      }
    } else {
      setSubCategories([]);
      setSelectedSubCategory(null);
    }
  }, [selectedCategory, getSubCategories, row.categoriePersonnel]);

  const handleEdit = () => {
    // Si c'est une cellule de sous-catégorie et qu'il n'y a pas de catégorie personnalisée,
    // n'ouvrez pas l'éditeur
    if (isSubCategoryCell && !hasPersonalizedCategory) {
      return;
    }

    setIsEditing(true);
  };

  const handleCategorySelect = (categoryId: string) => {
    setSelectedCategory(categoryId);
  };

  const handleSubCategorySelect = (subCategoryId: string) => {
    setSelectedSubCategory(subCategoryId);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);

      // Si c'est une cellule de sous-catégorie, nous utilisons la catégorie parente actuelle
      const catId = isSubCategoryCell
        ? row.categoriePersonnel?._id
        : selectedCategory;

      // Vérifier que nous avons au moins une catégorie
      if (!catId) {
        console.error("Aucune catégorie sélectionnée");
        return;
      }

      // Créer un objet de requête propre
      const requestData: {
        ligneId: string;
        categorieId: string;
        sousCategorieId?: string;
      } = {
        ligneId: row._id,
        categorieId: catId,
      };

      // N'ajouter sousCategorieId que si une valeur est sélectionnée
      if (selectedSubCategory) {
        requestData.sousCategorieId = selectedSubCategory;
      }

      await assignCategorieToLigne(requestData);

      // Invalider la query pour forcer le rechargement des données
      queryClient.invalidateQueries({
        queryKey: ["compte", compteId],
      });

      setIsEditing(false);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de la catégorie:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = async () => {
    try {
      setIsLoading(true);

      await resetCategorieForLigne({
        ligneId: row._id,
      });

      // Invalider la query pour forcer le rechargement des données
      queryClient.invalidateQueries({
        queryKey: ["compte", compteId],
      });

      setIsEditing(false);
    } catch (error) {
      console.error(
        "Erreur lors de la réinitialisation de la catégorie:",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const cellClasses = `cursor-pointer relative ${
    isCompactMode ? "p-1 py-0.5" : ""
  } hover:bg-base-300 ${
    isSubCategoryCell && !hasPersonalizedCategory ? "opacity-50" : ""
  }`;

  if (isEditing) {
    return (
      <div ref={dropdownRef} className={`${cellClasses} min-h-12`}>
        {isLoading || categoriesLoading ? (
          <div className="flex justify-center items-center">
            <span className="loading loading-spinner loading-xs"></span>
          </div>
        ) : (
          <div className="absolute z-10 bg-base-100 shadow-lg rounded-md p-2 min-w-48 right-0 mt-1">
            {!isSubCategoryCell && (
              <div className="mb-2">
                <div className="font-semibold text-sm mb-1">Catégorie</div>
                <select
                  className="select select-sm select-bordered w-full"
                  value={selectedCategory || ""}
                  onChange={(e) => handleCategorySelect(e.target.value)}
                >
                  <option value="">-- Sélectionner --</option>
                  {mainCategories.map((cat) => (
                    <option key={cat._id} value={cat._id}>
                      {cat.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {selectedCategory && subCategories.length > 0 && (
              <div className="mb-2">
                <div className="font-semibold text-sm mb-1">Sous-catégorie</div>
                <select
                  className="select select-sm select-bordered w-full"
                  value={selectedSubCategory || ""}
                  onChange={(e) => handleSubCategorySelect(e.target.value)}
                >
                  <option value="">-- Aucune --</option>
                  {subCategories.map((subCat) => (
                    <option key={subCat._id} value={subCat._id}>
                      {subCat.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="flex justify-between mt-2">
              <button
                className="btn btn-xs btn-error"
                onClick={handleReset}
                title="Réinitialiser aux catégories d'origine"
              >
                <FaTimes size={12} />
              </button>
              <button
                className="btn btn-xs btn-primary"
                onClick={handleSave}
                disabled={isSubCategoryCell ? false : !selectedCategory}
              >
                <FaCheckCircle size={12} />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={cellClasses}
      onClick={handleEdit}
      title={
        isSubCategoryCell && !hasPersonalizedCategory
          ? "Vous devez d'abord définir une catégorie personnalisée"
          : "Cliquer pour modifier la catégorie"
      }
    >
      <div className="flex justify-between items-center">
        <span
          className={column === "sousCategorie" ? "text-xs text-gray-600" : ""}
        >
          {row[column]}
        </span>
        {(!isSubCategoryCell || hasPersonalizedCategory) && (
          <FaEdit
            size={isCompactMode ? 10 : 12}
            className="text-gray-400 opacity-50"
          />
        )}
      </div>
    </div>
  );
};
