import {Category} from "@/pages/Bank/types";
import {getUserLogged} from "@/utils/axios/userUtils";

/**
 * Crée la structure des catégories par défaut pour un nouvel utilisateur
 */
export const getDefaultCategories = (): Partial<Category>[] => {
  const currentUserFamily = getUserLogged()?.family;

  // Catégorie charges et ses sous-catégories
  const chargesCategory: Partial<Category> = {
    label: "Charges",
    icon: "wallet",
    archived: false,
    disabled: false,
    owner: currentUserFamily,
  };

  const electriciteCategory: Partial<Category> = {
    label: "Électricité",
    icon: "plug",
    archived: false,
    disabled: false,
    owner: currentUserFamily,
  };

  const eauCategory: Partial<Category> = {
    label: "Eau",
    icon: "droplet",
    archived: false,
    disabled: false,
    owner: currentUserFamily,
  };

  const gazCategory: Partial<Category> = {
    label: "Gaz",
    icon: "flame",
    archived: false,
    disabled: false,
    owner: currentUserFamily,
  };

  // Catégorie alimentation et ses sous-catégories
  const alimentationCategory: Partial<Category> = {
    label: "Alimentation",
    icon: "utensils",
    archived: false,
    disabled: false,
    owner: currentUserFamily,
  };

  const coursesCategory: Partial<Category> = {
    label: "Courses",
    icon: "shopping-cart",
    archived: false,
    disabled: false,
    owner: currentUserFamily,
  };

  const restaurantCategory: Partial<Category> = {
    label: "Restaurant",
    icon: "coffee",
    archived: false,
    disabled: false,
    owner: currentUserFamily,
  };

  const sortieCategory: Partial<Category> = {
    label: "Sortie",
    icon: "wine",
    archived: false,
    disabled: false,
    owner: currentUserFamily,
  };

  // Catégorie loisir
  const loisirCategory: Partial<Category> = {
    label: "Loisir",
    icon: "music",
    archived: false,
    disabled: false,
    owner: currentUserFamily,
  };

  return [
    chargesCategory,
    electriciteCategory,
    eauCategory,
    gazCategory,
    alimentationCategory,
    coursesCategory,
    restaurantCategory,
    sortieCategory,
    loisirCategory,
  ];
};

/**
 * Structure des relations parent-enfant pour les catégories par défaut
 * À utiliser après avoir créé les catégories et obtenu leurs IDs
 */
export const getDefaultCategoryRelations = (
  categories: Category[]
): { parentId: string; childId: string }[] => {
  const relations: { parentId: string; childId: string }[] = [];

  // Trouver les IDs des catégories principales
  const chargesCategory = categories.find((c) => c.label === "Charges");
  const alimentationCategory = categories.find(
    (c) => c.label === "Alimentation"
  );

  if (chargesCategory) {
    // Ajouter les relations pour les sous-catégories de Charges
    const electriciteCategory = categories.find(
      (c) => c.label === "Électricité"
    );
    const eauCategory = categories.find((c) => c.label === "Eau");
    const gazCategory = categories.find((c) => c.label === "Gaz");

    if (electriciteCategory) {
      relations.push({
        parentId: chargesCategory._id,
        childId: electriciteCategory._id,
      });
    }

    if (eauCategory) {
      relations.push({
        parentId: chargesCategory._id,
        childId: eauCategory._id,
      });
    }

    if (gazCategory) {
      relations.push({
        parentId: chargesCategory._id,
        childId: gazCategory._id,
      });
    }
  }

  if (alimentationCategory) {
    // Ajouter les relations pour les sous-catégories d'Alimentation
    const coursesCategory = categories.find((c) => c.label === "Courses");
    const restaurantCategory = categories.find((c) => c.label === "Restaurant");
    const sortieCategory = categories.find((c) => c.label === "Sortie");

    if (coursesCategory) {
      relations.push({
        parentId: alimentationCategory._id,
        childId: coursesCategory._id,
      });
    }

    if (restaurantCategory) {
      relations.push({
        parentId: alimentationCategory._id,
        childId: restaurantCategory._id,
      });
    }

    if (sortieCategory) {
      relations.push({
        parentId: alimentationCategory._id,
        childId: sortieCategory._id,
      });
    }
  }

  return relations;
};
