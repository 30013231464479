import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number) => void;
  generatePagination: () => (number | string)[];
}

export const Pagination = ({
  currentPage,
  totalPages,
  setCurrentPage,
  generatePagination,
}: PaginationProps) => {
  if (totalPages <= 1) return null;

  const pages = generatePagination();

  return (
    <div className="join">
      {/* Bouton précédent */}
      <button
        className="join-item btn btn-sm"
        onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
      >
        «
      </button>

      {/* Pages */}
      {pages.map((page, index) => (
        <React.Fragment key={index}>
          {typeof page === "number" ? (
            <button
              className={`join-item btn btn-sm ${
                currentPage === page ? "btn-active" : ""
              }`}
              onClick={() => setCurrentPage(page)}
            >
              {page}
            </button>
          ) : (
            <button className="join-item btn btn-sm btn-disabled">...</button>
          )}
        </React.Fragment>
      ))}

      {/* Bouton suivant */}
      <button
        className="join-item btn btn-sm"
        onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
      >
        »
      </button>
    </div>
  );
};
