import { useEffect, useState } from "react";
import { GameHistory } from "@/pages/Molky/types.ts";
import { useGameHistory } from "@/pages/Molky/hooks/useGameHistory.ts";

interface GameHistoryProps {
  onSelectPlayers?: (playerNames: string[]) => void;
}

export function GameHistoryComponent({ onSelectPlayers }: GameHistoryProps) {
  const { gameHistory, isLoading, isError, refetch } = useGameHistory();
  const [stats, setStats] = useState({
    totalGames: 0,
    avgDuration: 0,
    topPlayers: [] as { name: string; wins: number }[],
  });

  useEffect(() => {
    if (gameHistory.length > 0) {
      // Calcul des statistiques générales
      const totalGames = gameHistory.length;

      // Durée moyenne des parties
      const totalDuration = gameHistory.reduce(
        (sum, game) => sum + (game.duration || 0),
        0
      );
      const avgDuration = totalGames > 0 ? totalDuration / totalGames : 0;

      // Top joueurs par victoires
      const playerWins = {} as Record<string, number>;
      gameHistory.forEach((game) => {
        if (game.winner) {
          const winnerName = game.winner.name;
          playerWins[winnerName] = (playerWins[winnerName] || 0) + 1;
        }
      });

      const topPlayers = Object.entries(playerWins)
        .map(([name, wins]) => ({ name, wins }))
        .sort((a, b) => b.wins - a.wins)
        .slice(0, 3);

      setStats({
        totalGames,
        avgDuration,
        topPlayers,
      });
    }
  }, [gameHistory]);

  const handleReplayWithSamePlayers = (game: GameHistory) => {
    if (onSelectPlayers) {
      const playerNames = game.players.map((player) => player.name);
      onSelectPlayers(playerNames);
    }
  };

  if (isLoading) {
    return (
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Historique des parties</h2>
          <div className="flex justify-center">
            <span className="loading loading-spinner loading-lg"></span>
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Historique des parties</h2>
          <div className="alert alert-error">
            Erreur lors du chargement de l'historique des parties.
            <button className="btn btn-sm" onClick={() => refetch()}>
              Réessayer
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (gameHistory.length === 0) {
    return (
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">Historique des parties</h2>
          <p>Aucune partie n'a encore été jouée.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="card bg-base-100 shadow-xl">
      <div className="card-body">
        <h2 className="card-title">Historique des parties</h2>

        {/* Statistiques globales */}
        <div className="stats shadow mb-4">
          <div className="stat">
            <div className="stat-title">Parties jouées</div>
            <div className="stat-value">{stats.totalGames}</div>
          </div>

          <div className="stat">
            <div className="stat-title">Durée moyenne</div>
            <div className="stat-value">{stats.avgDuration.toFixed(0)} min</div>
          </div>

          <div className="stat">
            <div className="stat-title">Top joueur</div>
            <div className="stat-value text-primary">
              {stats.topPlayers[0]?.name || "-"}
            </div>
            <div className="stat-desc">
              {stats.topPlayers[0]
                ? `${stats.topPlayers[0].wins} victoires`
                : ""}
            </div>
          </div>
        </div>

        {/* Liste des parties récentes */}
        <h3 className="font-bold text-lg mt-4">Parties récentes</h3>
        <div className="overflow-x-auto">
          <table className="table table-zebra w-full">
            <thead>
              <tr>
                <th>Date</th>
                <th>Joueurs</th>
                <th>Gagnant</th>
                <th>Durée</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {gameHistory.map((game: GameHistory) => (
                <tr key={game.id}>
                  <td>{new Date(game.date).toLocaleDateString()}</td>
                  <td>
                    {game.players.map((player) => player.name).join(", ")}
                  </td>
                  <td className="font-bold text-success">
                    {game.winner?.name || "-"}
                  </td>
                  <td>{game.duration || "-"} min</td>
                  <td>
                    <button
                      className="btn btn-sm btn-outline"
                      onClick={() => handleReplayWithSamePlayers(game)}
                    >
                      Rejouer
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
