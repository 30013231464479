import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AppDispatch, RootState } from "@/store/store";
import {
  fetchFamilyDetails,
  googleLogin,
  initializeAuth,
  login,
  logout,
  register,
  updateProfile,
} from "@/store/auth/authSlice.ts";
import { UserForm } from "@/utils/userTypes.ts";

export const useAuth = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user, token, isLoading, error, family } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    // Initialiser l'authentification au chargement
    dispatch(initializeAuth());
  }, [dispatch]);

  const loginUser = (credentials: { login: string; password: string }) => {
    return dispatch(login(credentials));
  };

  const registerUser = (userData: UserForm) => {
    return dispatch(register(userData));
  };

  const logoutUser = () => {
    dispatch(logout());
  };

  const loginWithGoogle = (credential: string) => {
    return dispatch(googleLogin(credential));
  };

  const updateUserProfile = (userId: string, data: FormData) => {
    return dispatch(updateProfile({ userId, data }));
  };

  const getFamilyDetails = () => {
    return dispatch(fetchFamilyDetails());
  };

  return {
    user,
    token,
    isLoading,
    error,
    family,
    isAuthenticated: !!user,
    loginUser,
    registerUser,
    logoutUser,
    loginWithGoogle,
    updateUserProfile,
    getFamilyDetails,
  };
};
