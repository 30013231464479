// components/TrainSearch.tsx
import React, { useState } from "react";
import {
  Journey,
  JourneySearchParams,
  Station,
} from "@/pages/API/sncf/types.ts";
import { useJourneySearch } from "../hooks/useSncfApi";
import StationAutocomplete from "./StationAutocomplete";
import JourneyCard from "./JourneyCard";

export const TrainSearch: React.FC = () => {
  // État pour les stations de départ et d'arrivée
  const [departureStation, setDepartureStation] = useState<Station | null>(
    null
  );
  const [arrivalStation, setArrivalStation] = useState<Station | null>(null);

  // État pour la date et l'heure
  const [date, setDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [time, setTime] = useState<string>(
    new Date().toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    })
  );

  // État pour le trajet sélectionné
  const [selectedJourney, setSelectedJourney] = useState<Journey | null>(null);

  // État pour les paramètres de recherche
  const [searchParams, setSearchParams] = useState<JourneySearchParams | null>(
    null
  );

  // Utilisation du hook de recherche de trajets
  const {
    data: journeysData,
    isLoading,
    isError,
    error,
  } = useJourneySearch(searchParams);

  console.log("Journeys Data:", journeysData);

  // Fonction pour inverser les gares de départ et d'arrivée
  const handleSwapStations = () => {
    const temp = departureStation;
    setDepartureStation(arrivalStation);
    setArrivalStation(temp);
  };

  // Fonction pour lancer la recherche
  const handleSearch = () => {
    if (departureStation && arrivalStation) {
      // Formatage de la date et de l'heure au format ISO8601
      const isoDateTime = `${date}T${time}:00`;
      console.log(
        "Recherche de trajets de",
        departureStation.id,
        "à",
        arrivalStation.id,
        "à",
        isoDateTime
      );
      setSearchParams({
        fromStationId: departureStation.id,
        toStationId: arrivalStation.id,
        datetime: isoDateTime,
        datetime_represents: "departure",
        count: 10,
      });
      setSelectedJourney(null);
    }
  };

  // Vérifier si nous avons des trajets à afficher
  const hasJourneys = Array.isArray(journeysData) && journeysData.length > 0;

  return (
    <div className="container mx-auto p-4">
      <div className="card bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title text-2xl mb-4">
            Recherche de trajets SNCF
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center space-x-2">
              <StationAutocomplete
                label="Départ"
                placeholder="Gare de départ"
                value={departureStation}
                onChange={setDepartureStation}
              />

              <button
                className="btn btn-circle mt-8"
                onClick={handleSwapStations}
                aria-label="Inverser les gares"
              >
                ⇄
              </button>

              <StationAutocomplete
                label="Arrivée"
                placeholder="Gare d'arrivée"
                value={arrivalStation}
                onChange={setArrivalStation}
              />
            </div>

            <div className="flex space-x-2">
              <div className="form-control flex-1">
                <label className="label">
                  <span className="label-text">Date</span>
                </label>
                <input
                  type="date"
                  className="input input-bordered"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  min={new Date().toISOString().split("T")[0]}
                />
              </div>

              <div className="form-control flex-1">
                <label className="label">
                  <span className="label-text">Heure</span>
                </label>
                <input
                  type="time"
                  className="input input-bordered"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              </div>

              <div className="form-control flex-1">
                <label className="label">
                  <span className="label-text">&nbsp;</span>
                </label>
                <button
                  className="btn btn-primary"
                  onClick={handleSearch}
                  disabled={!departureStation || !arrivalStation}
                >
                  Rechercher
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {searchParams && (
        <div className="mt-6">
          <h3 className="text-xl font-bold mb-4">
            Trajets de {departureStation?.name} à {arrivalStation?.name}
          </h3>

          {isLoading ? (
            <div className="flex justify-center p-8">
              <span className="loading loading-dots loading-lg"></span>
            </div>
          ) : isError ? (
            <div className="alert alert-error">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current shrink-0 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span>
                Erreur:{" "}
                {(error as Error)?.message ||
                  "Impossible de récupérer les trajets"}
              </span>
            </div>
          ) : hasJourneys ? (
            <div className="grid grid-cols-1 gap-4">
              {journeysData.map((journey: Journey) => (
                <JourneyCard
                  key={journey.id}
                  journey={journey}
                  selected={selectedJourney?.id === journey.id}
                  showRealTimeInfo={selectedJourney?.id === journey.id}
                  onSelect={() => setSelectedJourney(journey)}
                />
              ))}
            </div>
          ) : (
            <div className="alert alert-info">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="stroke-current shrink-0 w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <span>
                Aucun trajet trouvé pour cette recherche. Essayez une autre date
                ou d'autres gares.
              </span>
            </div>
          )}
        </div>
      )}

      {selectedJourney && (
        <div className="mt-6">
          <div className="card bg-base-100 shadow-xl">
            <div className="card-body">
              <h3 className="card-title">Détails du trajet</h3>
              <JourneyCard journey={selectedJourney} showRealTimeInfo={true} />

              <div className="flex justify-end mt-4 space-x-2">
                <button className="btn btn-outline">Ajouter aux favoris</button>
                <button className="btn btn-primary">Réserver ce trajet</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
