import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getUserById, updateUserProfile } from "@/utils/axios/userUtils";
import { FAMILY_KEYS } from "./familyHooks";
import { UserType } from "@/utils/userTypes.ts";

// Clés de requête pour le profil utilisateur
export const PROFILE_KEYS = {
  all: ["profile"] as const,
  details: (id: string) => [...PROFILE_KEYS.all, "details", id] as const,
};

// Type pour les paramètres de mise à jour du profil
interface UpdateProfileParams {
  id: string;
  data: FormData;
}

// Hook pour mettre à jour le profil
export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  return useMutation<UserType, Error, UpdateProfileParams>({
    mutationFn: ({ id, data }) => updateUserProfile(id, data),

    onSuccess: async (updatedUser) => {
      // Mettre à jour le cache pour l'utilisateur
      queryClient.setQueryData(
        PROFILE_KEYS.details(updatedUser._id),
        updatedUser
      );

      // Mettre à jour le localStorage pour les changements d'utilisateur
      // Note: la fonction getUserLogged() lira le nouveau token du localStorage si disponible

      // Refetch les détails de la famille si l'utilisateur est membre d'une famille
      if (updatedUser.family) {
        queryClient.invalidateQueries({ queryKey: FAMILY_KEYS.details() });
      }

      // Forcer le rechargement de la page pour appliquer les changements de token/utilisateur
      window.location.reload();
    },
  });
};

// Hook pour charger les détails du profil d'un utilisateur spécifique
export const useUserProfile = (userId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => getUserById(userId),
    onSuccess: (user) => {
      if (user) {
        // Stocker dans le cache les détails de l'utilisateur
        queryClient.setQueryData(PROFILE_KEYS.details(userId), user);
      }
    },
  });
};
