// hooks/useGameHistory.ts
import { useEffect, useState } from "react";
import { api } from "../services/api";
import {
  Game,
  GameHistory,
  UseGameHistoryReturn,
} from "@/pages/Molky/types.ts";

export function useGameHistory(): UseGameHistoryReturn {
  const [gameHistory, setGameHistory] = useState<GameHistory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchGameHistory = async () => {
    try {
      setIsLoading(true);
      setIsError(false);
      setError(null);

      const games = await api.getGames();
      setGameHistory(games as Game[]);
    } catch (err) {
      setIsError(true);
      setError(
        err instanceof Error ? err : new Error("Une erreur est survenue")
      );
      console.error(
        "Erreur lors de la récupération de l'historique des parties",
        err
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGameHistory();
  }, []);

  return {
    gameHistory,
    isLoading,
    isError,
    error,
    refetch: fetchGameHistory,
  };
}
