import { createFileRoute } from "@tanstack/react-router";
import SncfNextCard from "@/pages/API/sncf/components/SncfNextCard.tsx";

export const Route = createFileRoute("/sncf/")({
  component: RouteComponent,
});

function RouteComponent() {
  return <SncfNextCard />;
}
