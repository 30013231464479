import { useState } from "react";
import { useAuthContext } from "@/providers/AuthProvider.tsx";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { registerSchema } from "@/features/Login/validationSchemas.ts";
import { UserForm } from "@/utils/userTypes.ts";

interface RegisterFormProps {
  onToggleForm: () => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ onToggleForm }) => {
  const { register: registerUser, isLoading, error } = useAuthContext();
  const [avatarPreview, setAvatarPreview] = useState<string | null>(null);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<UserForm>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      username: "",
      email: "",
      firstname: "",
      lastname: "",
      password: "",
      confirmPassword: "",
      familyCode: "",
    },
  });

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Validation manuelle du fichier
      if (
        !["image/jpeg", "image/png", "image/gif", "image/webp"].includes(
          file.type
        )
      ) {
        alert("L'avatar doit être une image (JPEG, PNG, GIF, WebP)");
        e.target.value = "";
        setAvatarFile(null);
        setAvatarPreview(null);
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        alert("L'avatar ne doit pas dépasser 5 Mo");
        e.target.value = "";
        setAvatarFile(null);
        setAvatarPreview(null);
        return;
      }

      setAvatarFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setAvatarFile(null);
      setAvatarPreview(null);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      // Création explicite du FormData
      const formData = new FormData();

      // Ajout des champs texte
      formData.append("username", data.username);
      formData.append("email", data.email);
      formData.append("password", data.password);

      // Ajout des champs optionnels s'ils existent
      if (data.firstname) formData.append("firstname", data.firstname);
      if (data.lastname) formData.append("lastname", data.lastname);
      if (data.familyCode) formData.append("familyCode", data.familyCode);

      // Ajout de l'avatar s'il existe
      if (avatarFile) {
        formData.append("avatar", avatarFile);
      }

      // Affichage pour debug
      console.log(
        "FormData créé, champs inclus:",
        [...formData.entries()].map(
          (entry) =>
            `${entry[0]}: ${
              entry[1] instanceof File ? "File: " + entry[1].name : entry[1]
            }`
        )
      );

      // Envoi au backend
      await registerUser(formData);
      reset();
      setAvatarFile(null);
      setAvatarPreview(null);
    } catch (err) {
      console.error("Erreur lors de l'inscription:", err);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-4"
      encType="multipart/form-data"
    >
      <div className="text-center mb-6">
        <h2 className="text-2xl font-bold">Créer un compte</h2>
      </div>

      {/* Avatar upload */}
      <div className="form-control flex items-center">
        <div className="avatar">
          <div className="w-24 rounded-full">
            <img
              src={avatarPreview || "/default-avatar.png"}
              alt="Avatar preview"
            />
          </div>
        </div>
        <input
          type="file"
          accept="image/*"
          className="file-input file-input-bordered mt-2"
          onChange={handleAvatarChange}
        />
      </div>

      {/* Informations de base */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Nom d'utilisateur</span>
          </label>
          <input
            type="text"
            className={`input input-bordered w-full ${
              errors.username ? "input-error" : ""
            }`}
            {...register("username")}
          />
          {errors.username && (
            <label className="label">
              <span className="label-text-alt text-error">
                {errors.username.message}
              </span>
            </label>
          )}
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Email</span>
          </label>
          <input
            type="email"
            className={`input input-bordered w-full ${
              errors.email ? "input-error" : ""
            }`}
            {...register("email")}
          />
          {errors.email && (
            <label className="label">
              <span className="label-text-alt text-error">
                {errors.email.message}
              </span>
            </label>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Prénom</span>
          </label>
          <input
            type="text"
            className={`input input-bordered w-full ${
              errors.firstname ? "input-error" : ""
            }`}
            {...register("firstname")}
          />
          {errors.firstname && (
            <label className="label">
              <span className="label-text-alt text-error">
                {errors.firstname.message}
              </span>
            </label>
          )}
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Nom (optionnel)</span>
          </label>
          <input
            type="text"
            className="input input-bordered w-full"
            {...register("lastname")}
          />
        </div>
      </div>

      {/* Mot de passe */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="form-control">
          <label className="label">
            <span className="label-text">Mot de passe</span>
          </label>
          <input
            type="password"
            className={`input input-bordered w-full ${
              errors.password ? "input-error" : ""
            }`}
            {...register("password")}
          />
          {errors.password && (
            <label className="label">
              <span className="label-text-alt text-error">
                {errors.password.message}
              </span>
            </label>
          )}
        </div>

        <div className="form-control">
          <label className="label">
            <span className="label-text">Confirmer le mot de passe</span>
          </label>
          <input
            type="password"
            className={`input input-bordered w-full ${
              errors.confirmPassword ? "input-error" : ""
            }`}
            {...register("confirmPassword")}
          />
          {errors.confirmPassword && (
            <label className="label">
              <span className="label-text-alt text-error">
                {errors.confirmPassword.message}
              </span>
            </label>
          )}
        </div>
      </div>

      {/* Code famille (optionnel) */}
      <div className="form-control">
        <label className="label">
          <span className="label-text">Code famille (optionnel)</span>
        </label>
        <input
          type="text"
          className="input input-bordered w-full"
          {...register("familyCode")}
        />
        <label className="label">
          <span className="label-text-alt">
            Si vous avez un code pour rejoindre une famille existante
          </span>
        </label>
      </div>

      {error && (
        <div className="alert alert-error">
          <span>{error}</span>
        </div>
      )}

      <div className="form-control mt-6">
        <button
          type="submit"
          className="btn btn-primary w-full"
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="loading loading-spinner loading-sm"></span>
          ) : (
            "S'inscrire"
          )}
        </button>
      </div>

      <div className="divider"></div>

      <p className="text-center">
        Déjà un compte ?{" "}
        <button
          type="button"
          className="text-primary font-semibold"
          onClick={onToggleForm}
        >
          Se connecter
        </button>
      </p>
    </form>
  );
};

export default RegisterForm;
