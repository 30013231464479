import { createFileRoute } from "@tanstack/react-router";
import { MolkyMainPage } from "@/pages/Molky/MolkyMainPage.tsx";

export const Route = createFileRoute("/vacances/molky")({
  component: RouteComponent,
});

function RouteComponent() {
  return <MolkyMainPage />;
  // return <div>Hello "/vacances/molky"!</div>
}
