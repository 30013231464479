import { createFileRoute } from "@tanstack/react-router";
import { BankLandingPage } from "@/pages/Bank/BankLandingPage.tsx";

export const Route = createFileRoute("/bank/landing/$tab")({
  component: RouteComponent,
});

function RouteComponent() {
  const { tab } = Route.useParams();
  return <BankLandingPage tab={tab} />;
}
