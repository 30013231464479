import {useQuery} from "@tanstack/react-query";

import BanqueMain from "@/pages/Bank/components/Banque/BanqueMain.tsx";
import {CompteMain} from "@/pages/Bank/components/Compte/CompteMain.tsx";
import {getAllComptes, getBanquesForUser} from "@/utils/axios/bankUtils.ts";
import {ImportCsvForm} from "@/pages/Bank/components/Tools/ImportCsvForm.tsx";
import {useRouter} from "@tanstack/react-router";
import {CategoryMain} from "./components/Category/CategoryMain";
import {getUserLogged} from "@/utils/axios/userUtils.ts";
import {Tooltip} from "@mui/material";

type TabId = "banque" | "compte" | "csv" | "table" | "categories";

export const BankLandingPage = ({tab}: { tab: string }) => {
  const router = useRouter();

  const {
    data: banques,
    isLoading: isBanquesLoading,
    isError: isBanquesError,
  } = useQuery({
    queryKey: ["banque"],
    queryFn: getBanquesForUser,
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: comptes,
    isLoading: isComptesLoading,
    isError: isComptesError,
  } = useQuery({
    queryKey: ["compte"],
    queryFn: getAllComptes,
    staleTime: 1000 * 60 * 5,
  });

  const userLogged = getUserLogged();

  // Vérifier si certains onglets doivent être désactivés
  const isCompteDisabled = !banques || banques.length === 0;
  const isCsvDisabled = !comptes || comptes.length === 0;
  const isCategoryDisabled = !userLogged?.family;

  // Fonction pour changer d'onglet
  const handleTabChange = (tabId: TabId) => {
    router.navigate({
      params: {tab: tabId},
      to: "/bank/landing/$tab",
      replace: true,
    });
  };

  return (
    <div className="tabs tabs-box w-full">
      <input
        type="radio"
        name="menu-haut"
        className="tab"
        aria-label="Banque"
        checked={tab === "banque"}
        onChange={() => handleTabChange("banque")}
      />
      <div className="tab-content bg-base-100 border-base-300 p-6">
        <BanqueMain
          banques={banques}
          isBanquesLoading={isBanquesLoading}
          isBanquesError={isBanquesError}
        />
      </div>

      <input
        type="radio"
        name="menu-haut"
        disabled={isCompteDisabled}
        className="tab"
        aria-label="Compte"
        checked={tab === "compte"}
        onChange={() => handleTabChange("compte")}
      />
      <div className="tab-content bg-base-100 border-base-300 p-6">
        <CompteMain
          banques={banques}
          comptes={comptes}
          isComptesLoading={isComptesLoading}
          isComptesError={isComptesError}
        />
      </div>

      <input
        type="radio"
        name="menu-haut"
        className="tab"
        aria-label="CSV"
        disabled={isCsvDisabled}
        checked={tab === "csv"}
        onChange={() => handleTabChange("csv")}
      />
      <div className="tab-content bg-base-100 border-base-300 p-6">
        <ImportCsvForm listeCompte={comptes || []}/>
      </div>

      <Tooltip title={isCategoryDisabled ? "Vous devez faire partie d'une famille" : 'Gestion des catégories'}><input
        type="radio"
        name="menu-haut"
        className="tab"
        aria-label="Catégorie"
        disabled={isCategoryDisabled}
        checked={tab === "categories"}
        onChange={() => handleTabChange("categories")}
      /></Tooltip>
      <div className="tab-content bg-base-100 border-base-300 p-6">
        <CategoryMain/>
      </div>
    </div>
  );
};
