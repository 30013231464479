/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TodosRouteImport } from './routes/todos.route'
import { Route as Profile2RouteImport } from './routes/profile2.route'
import { Route as FeedRouteImport } from './routes/feed.route'
import { Route as FamilyRouteImport } from './routes/family.route'
import { Route as BankIndexImport } from './routes/bank/index'
import { Route as SncfIndexRouteImport } from './routes/sncf/index.route'
import { Route as VacancesMolkyRouteImport } from './routes/vacances/molky.route'
import { Route as SncfPlaySncfRouteImport } from './routes/sncf/playSncf.route'
import { Route as UtilsRoutesLogin2RouteImport } from './routes/_utilsRoutes/login2.route'
import { Route as BankLandingTabRouteImport } from './routes/bank/landing/$tab.route'
import { Route as BankLandingTableCompteIdRouteImport } from './routes/bank/landing/table/$compteId.route'

// Create Virtual Routes

const ProfileLazyImport = createFileRoute('/profile')()
const LoginLazyImport = createFileRoute('/login')()
const IndexLazyImport = createFileRoute('/')()

// Create/Update Routes

const ProfileLazyRoute = ProfileLazyImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/profile.lazy').then((d) => d.Route))

const LoginLazyRoute = LoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const TodosRouteRoute = TodosRouteImport.update({
  id: '/todos',
  path: '/todos',
  getParentRoute: () => rootRoute,
} as any)

const Profile2RouteRoute = Profile2RouteImport.update({
  id: '/profile2',
  path: '/profile2',
  getParentRoute: () => rootRoute,
} as any)

const FeedRouteRoute = FeedRouteImport.update({
  id: '/feed',
  path: '/feed',
  getParentRoute: () => rootRoute,
} as any)

const FamilyRouteRoute = FamilyRouteImport.update({
  id: '/family',
  path: '/family',
  getParentRoute: () => rootRoute,
} as any)

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const BankIndexRoute = BankIndexImport.update({
  id: '/bank/',
  path: '/bank/',
  getParentRoute: () => rootRoute,
} as any)

const SncfIndexRouteRoute = SncfIndexRouteImport.update({
  id: '/sncf/',
  path: '/sncf/',
  getParentRoute: () => rootRoute,
} as any)

const VacancesMolkyRouteRoute = VacancesMolkyRouteImport.update({
  id: '/vacances/molky',
  path: '/vacances/molky',
  getParentRoute: () => rootRoute,
} as any)

const SncfPlaySncfRouteRoute = SncfPlaySncfRouteImport.update({
  id: '/sncf/playSncf',
  path: '/sncf/playSncf',
  getParentRoute: () => rootRoute,
} as any)

const UtilsRoutesLogin2RouteRoute = UtilsRoutesLogin2RouteImport.update({
  id: '/_utilsRoutes/login2',
  path: '/login2',
  getParentRoute: () => rootRoute,
} as any)

const BankLandingTabRouteRoute = BankLandingTabRouteImport.update({
  id: '/bank/landing/$tab',
  path: '/bank/landing/$tab',
  getParentRoute: () => rootRoute,
} as any)

const BankLandingTableCompteIdRouteRoute =
  BankLandingTableCompteIdRouteImport.update({
    id: '/bank/landing/table/$compteId',
    path: '/bank/landing/table/$compteId',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/family': {
      id: '/family'
      path: '/family'
      fullPath: '/family'
      preLoaderRoute: typeof FamilyRouteImport
      parentRoute: typeof rootRoute
    }
    '/feed': {
      id: '/feed'
      path: '/feed'
      fullPath: '/feed'
      preLoaderRoute: typeof FeedRouteImport
      parentRoute: typeof rootRoute
    }
    '/profile2': {
      id: '/profile2'
      path: '/profile2'
      fullPath: '/profile2'
      preLoaderRoute: typeof Profile2RouteImport
      parentRoute: typeof rootRoute
    }
    '/todos': {
      id: '/todos'
      path: '/todos'
      fullPath: '/todos'
      preLoaderRoute: typeof TodosRouteImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginLazyImport
      parentRoute: typeof rootRoute
    }
    '/profile': {
      id: '/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof ProfileLazyImport
      parentRoute: typeof rootRoute
    }
    '/_utilsRoutes/login2': {
      id: '/_utilsRoutes/login2'
      path: '/login2'
      fullPath: '/login2'
      preLoaderRoute: typeof UtilsRoutesLogin2RouteImport
      parentRoute: typeof rootRoute
    }
    '/sncf/playSncf': {
      id: '/sncf/playSncf'
      path: '/sncf/playSncf'
      fullPath: '/sncf/playSncf'
      preLoaderRoute: typeof SncfPlaySncfRouteImport
      parentRoute: typeof rootRoute
    }
    '/vacances/molky': {
      id: '/vacances/molky'
      path: '/vacances/molky'
      fullPath: '/vacances/molky'
      preLoaderRoute: typeof VacancesMolkyRouteImport
      parentRoute: typeof rootRoute
    }
    '/sncf/': {
      id: '/sncf/'
      path: '/sncf'
      fullPath: '/sncf'
      preLoaderRoute: typeof SncfIndexRouteImport
      parentRoute: typeof rootRoute
    }
    '/bank/': {
      id: '/bank/'
      path: '/bank'
      fullPath: '/bank'
      preLoaderRoute: typeof BankIndexImport
      parentRoute: typeof rootRoute
    }
    '/bank/landing/$tab': {
      id: '/bank/landing/$tab'
      path: '/bank/landing/$tab'
      fullPath: '/bank/landing/$tab'
      preLoaderRoute: typeof BankLandingTabRouteImport
      parentRoute: typeof rootRoute
    }
    '/bank/landing/table/$compteId': {
      id: '/bank/landing/table/$compteId'
      path: '/bank/landing/table/$compteId'
      fullPath: '/bank/landing/table/$compteId'
      preLoaderRoute: typeof BankLandingTableCompteIdRouteImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/family': typeof FamilyRouteRoute
  '/feed': typeof FeedRouteRoute
  '/profile2': typeof Profile2RouteRoute
  '/todos': typeof TodosRouteRoute
  '/login': typeof LoginLazyRoute
  '/profile': typeof ProfileLazyRoute
  '/login2': typeof UtilsRoutesLogin2RouteRoute
  '/sncf/playSncf': typeof SncfPlaySncfRouteRoute
  '/vacances/molky': typeof VacancesMolkyRouteRoute
  '/sncf': typeof SncfIndexRouteRoute
  '/bank': typeof BankIndexRoute
  '/bank/landing/$tab': typeof BankLandingTabRouteRoute
  '/bank/landing/table/$compteId': typeof BankLandingTableCompteIdRouteRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/family': typeof FamilyRouteRoute
  '/feed': typeof FeedRouteRoute
  '/profile2': typeof Profile2RouteRoute
  '/todos': typeof TodosRouteRoute
  '/login': typeof LoginLazyRoute
  '/profile': typeof ProfileLazyRoute
  '/login2': typeof UtilsRoutesLogin2RouteRoute
  '/sncf/playSncf': typeof SncfPlaySncfRouteRoute
  '/vacances/molky': typeof VacancesMolkyRouteRoute
  '/sncf': typeof SncfIndexRouteRoute
  '/bank': typeof BankIndexRoute
  '/bank/landing/$tab': typeof BankLandingTabRouteRoute
  '/bank/landing/table/$compteId': typeof BankLandingTableCompteIdRouteRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/family': typeof FamilyRouteRoute
  '/feed': typeof FeedRouteRoute
  '/profile2': typeof Profile2RouteRoute
  '/todos': typeof TodosRouteRoute
  '/login': typeof LoginLazyRoute
  '/profile': typeof ProfileLazyRoute
  '/_utilsRoutes/login2': typeof UtilsRoutesLogin2RouteRoute
  '/sncf/playSncf': typeof SncfPlaySncfRouteRoute
  '/vacances/molky': typeof VacancesMolkyRouteRoute
  '/sncf/': typeof SncfIndexRouteRoute
  '/bank/': typeof BankIndexRoute
  '/bank/landing/$tab': typeof BankLandingTabRouteRoute
  '/bank/landing/table/$compteId': typeof BankLandingTableCompteIdRouteRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/family'
    | '/feed'
    | '/profile2'
    | '/todos'
    | '/login'
    | '/profile'
    | '/login2'
    | '/sncf/playSncf'
    | '/vacances/molky'
    | '/sncf'
    | '/bank'
    | '/bank/landing/$tab'
    | '/bank/landing/table/$compteId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/family'
    | '/feed'
    | '/profile2'
    | '/todos'
    | '/login'
    | '/profile'
    | '/login2'
    | '/sncf/playSncf'
    | '/vacances/molky'
    | '/sncf'
    | '/bank'
    | '/bank/landing/$tab'
    | '/bank/landing/table/$compteId'
  id:
    | '__root__'
    | '/'
    | '/family'
    | '/feed'
    | '/profile2'
    | '/todos'
    | '/login'
    | '/profile'
    | '/_utilsRoutes/login2'
    | '/sncf/playSncf'
    | '/vacances/molky'
    | '/sncf/'
    | '/bank/'
    | '/bank/landing/$tab'
    | '/bank/landing/table/$compteId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  FamilyRouteRoute: typeof FamilyRouteRoute
  FeedRouteRoute: typeof FeedRouteRoute
  Profile2RouteRoute: typeof Profile2RouteRoute
  TodosRouteRoute: typeof TodosRouteRoute
  LoginLazyRoute: typeof LoginLazyRoute
  ProfileLazyRoute: typeof ProfileLazyRoute
  UtilsRoutesLogin2RouteRoute: typeof UtilsRoutesLogin2RouteRoute
  SncfPlaySncfRouteRoute: typeof SncfPlaySncfRouteRoute
  VacancesMolkyRouteRoute: typeof VacancesMolkyRouteRoute
  SncfIndexRouteRoute: typeof SncfIndexRouteRoute
  BankIndexRoute: typeof BankIndexRoute
  BankLandingTabRouteRoute: typeof BankLandingTabRouteRoute
  BankLandingTableCompteIdRouteRoute: typeof BankLandingTableCompteIdRouteRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  FamilyRouteRoute: FamilyRouteRoute,
  FeedRouteRoute: FeedRouteRoute,
  Profile2RouteRoute: Profile2RouteRoute,
  TodosRouteRoute: TodosRouteRoute,
  LoginLazyRoute: LoginLazyRoute,
  ProfileLazyRoute: ProfileLazyRoute,
  UtilsRoutesLogin2RouteRoute: UtilsRoutesLogin2RouteRoute,
  SncfPlaySncfRouteRoute: SncfPlaySncfRouteRoute,
  VacancesMolkyRouteRoute: VacancesMolkyRouteRoute,
  SncfIndexRouteRoute: SncfIndexRouteRoute,
  BankIndexRoute: BankIndexRoute,
  BankLandingTabRouteRoute: BankLandingTabRouteRoute,
  BankLandingTableCompteIdRouteRoute: BankLandingTableCompteIdRouteRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/family",
        "/feed",
        "/profile2",
        "/todos",
        "/login",
        "/profile",
        "/_utilsRoutes/login2",
        "/sncf/playSncf",
        "/vacances/molky",
        "/sncf/",
        "/bank/",
        "/bank/landing/$tab",
        "/bank/landing/table/$compteId"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/family": {
      "filePath": "family.route.tsx"
    },
    "/feed": {
      "filePath": "feed.route.tsx"
    },
    "/profile2": {
      "filePath": "profile2.route.tsx"
    },
    "/todos": {
      "filePath": "todos.route.tsx"
    },
    "/login": {
      "filePath": "login.lazy.tsx"
    },
    "/profile": {
      "filePath": "profile.lazy.tsx"
    },
    "/_utilsRoutes/login2": {
      "filePath": "_utilsRoutes/login2.route.tsx"
    },
    "/sncf/playSncf": {
      "filePath": "sncf/playSncf.route.ts"
    },
    "/vacances/molky": {
      "filePath": "vacances/molky.route.tsx"
    },
    "/sncf/": {
      "filePath": "sncf/index.route.tsx"
    },
    "/bank/": {
      "filePath": "bank/index.tsx"
    },
    "/bank/landing/$tab": {
      "filePath": "bank/landing/$tab.route.tsx"
    },
    "/bank/landing/table/$compteId": {
      "filePath": "bank/landing/table/$compteId.route.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
