// components/StationAutocomplete.tsx
import React, { useEffect, useRef, useState } from "react";
import { useStationSearch } from "../hooks/useSncfApi";
import { Station } from "@/pages/API/sncf/types.ts";

interface StationAutocompleteProps {
  label: string;
  placeholder?: string;
  onChange: (station: Station | null) => void;
  value: Station | null;
  className?: string;
}

const StationAutocomplete: React.FC<StationAutocompleteProps> = ({
  label,
  placeholder = "Rechercher une gare...",
  onChange,
  value,
  className = "",
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Récupération des résultats via TanStack Query
  const { data: stationsData, isLoading } = useStationSearch(
    searchQuery,
    searchQuery.length >= 2
  );

  // Gestion du clic en dehors du dropdown pour le fermer
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Mise à jour du champ de recherche quand la valeur change
  useEffect(() => {
    if (!value && searchQuery) {
      setSearchQuery("");
    } else if (value && value.name !== searchQuery) {
      setSearchQuery(value.name);
    }
  }, [value]);

  // Gestion du changement de texte dans l'input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setSearchQuery(newQuery);

    // Si l'utilisateur modifie le texte, réinitialiser la station sélectionnée
    if (value && value.name !== newQuery) {
      onChange(null);
    }

    if (newQuery.length >= 2) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }

    if (!newQuery) {
      onChange(null);
    }
  };

  // Sélection d'une station
  const handleSelectStation = (station: Station) => {
    // console.log("handleSelectStation appelé avec:", station);
    onChange(station);
    // setSearchQuery(station.name);
    setIsOpen(false);
  };

  // Determine if we have stations to display
  const hasStations = Array.isArray(stationsData) && stationsData.length > 0;

  return (
    <div className={`form-control w-full ${className}`} ref={dropdownRef}>
      <label className="label">
        <span className="label-text">{label}</span>
      </label>

      <div className="dropdown w-full">
        <input
          type="text"
          placeholder={placeholder}
          className="input input-bordered w-full"
          value={searchQuery}
          onChange={handleInputChange}
          onFocus={() => searchQuery.length >= 2 && setIsOpen(true)}
          autoComplete="off"
        />

        {isOpen && (
          <div className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full mt-1 max-h-60 overflow-auto z-10">
            {isLoading ? (
              <div className="p-2 text-center">
                <span className="loading loading-dots loading-md"></span>
              </div>
            ) : hasStations ? (
              <div className="w-full">
                {stationsData.map((station: Station) => (
                  <button
                    key={station.id}
                    type="button"
                    className="w-full text-left p-3 hover:bg-base-200 rounded-lg mb-1"
                    onClick={() => handleSelectStation(station)}
                  >
                    <div className="flex flex-col items-start">
                      <span className="font-medium">{station.name}</span>
                      {station.administrativeRegions &&
                        station.administrativeRegions.length > 0 && (
                          <span className="text-xs opacity-70">
                            {station.administrativeRegions[0].name} (
                            {station.administrativeRegions[0].zipCode})
                          </span>
                        )}
                    </div>
                  </button>
                ))}
              </div>
            ) : (
              <div className="p-2 text-center opacity-70">
                Aucune gare trouvée
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StationAutocomplete;
